import Tooltip from "rc-tooltip";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { EDeviceTypes } from "../../../enums/device-types";
import { IPowerConsumerWithConsumptionView } from "../../../interfaces/power-consumer-with-consumption-view";
import MaterialIcon from "../../../ui-elements/material-icon/material-icon";
import SpinnerModal from "../../../ui-elements/spinner/spinner";
import findIcon from "../../../utils/findIcon";
import Helper from "../../../utils/helpers";
import shortenedPath from "../../../utils/shortenedPath";
import styles from "./high-power-devices.module.scss";

interface IProps {
  consumers: IPowerConsumerWithConsumptionView[] | null;
  title: string;
  isFetching?: boolean;
}

const HighPowerDevices = (props: IProps) => {
  const [data, setData] = useState<
    {
      name: string;
      id: string;
      units: number;
      type: EDeviceTypes;
      percentage: number;
      path: string;
    }[]
  >([]);

  useEffect(() => {
    if (props.consumers && props.consumers.length > 0) {
      const sorted = props.consumers.slice().sort((a, b) => b.units - a.units);
      const max = sorted[0].units;
      const _data = sorted
        .filter((c) => Number(Helper.roundTo2(c.units)) > 0)
        .map((c) => {
          return {
            name: c.name,
            id: c.id,
            units: Number(Helper.roundTo2(c.units)),
            type: c.type,
            percentage: (c.units / max) * 100,
            path: c.path,
          };
        });

      setData(_data);
    } else {
      setData([]);
    }
  }, [props.consumers]);

  return (
    <div className={`${styles.container} container-white h-100`}>
      {props.isFetching ? (
        <SpinnerModal show={props.isFetching} withOverlay={false} size="md" />
      ) : (
        <>
          <Row className="align-items-center">
            <Col>
              <label className={styles.title}>{props.title}</label>
            </Col>
          </Row>
          <Row className="mx-0">
            {props.consumers === null || data.length < 1 ? (
              <Col className={`${styles.subTitle} text-center`}>
                No power consumption has yet been recorded
              </Col>
            ) : (
              <>
                {data.map((c, index) => (
                  <Col
                    key={index}
                    xs={12}
                    lg={6}
                    className={styles.contentRowLeft}
                  >
                    {c.units > 0 && <Device {...c} />}
                  </Col>
                ))}
              </>
            )}
          </Row>
        </>
      )}
    </div>
  );
};

interface IDeviceProps {
  name: string;
  id: string;
  units: number;
  type: EDeviceTypes;
  percentage: number;
  path: string;
}

const Device = (props: IDeviceProps) => {
  return (
    <Row className={`${styles.deviceContainer} align-items-center`}>
      <Col className={`${styles.left} ps-0`}>
        <Row className="align-items-center">
          <Col className="col-auto pe-0">
            <div className={`${props.type} ${styles.icon}`}>
              <MaterialIcon size={16} icon={findIcon(props.type)} />
            </div>
          </Col>
          <Col className="ps-2 pe-0">
            <Tooltip
              placement="top"
              trigger={["hover"]}
              overlay={
                <div>
                  <Row>
                    <Col className="text-dark font-weight-500 font-size-14">
                      {props.name}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-light font-weight-400 font-size-12">
                      {props.path.length > 80
                        ? shortenedPath(props.path)
                        : props.path}
                    </Col>
                  </Row>
                </div>
              }
            >
              <label className="text-truncate" style={{ maxWidth: "70px" }}>
                {props.name}
              </label>
            </Tooltip>
          </Col>
        </Row>
      </Col>
      <Col className={`${styles.right} text-end p-0 pe-0`}>
        <Row className="align-items-center">
          <Col className="pe-0">
            <div className={styles.progress}>
              <div
                className={props.type}
                style={{ width: `${props.percentage}%` }}
              ></div>
            </div>
          </Col>
          <Col className="ps-2 col-4">
            <label>{`${props.units}`}&nbsp;Units</label>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default HighPowerDevices;
