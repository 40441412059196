import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { EDeviceTypes } from "../../oversight-core/enums/device-types";
import { IDemandControlActionPowerConsumerView } from "../../oversight-core/interfaces/demand-control-action-power-consumer-view";
import AppSwitch from "../../oversight-core/ui-elements/app-switch/app-switch";
import MaterialIcon from "../../oversight-core/ui-elements/material-icon/material-icon";
import findIcon from "../../oversight-core/utils/findIcon";
import styles from "./device-card-with-toggle-button.module.scss";

interface IProps {
  powerConsumer: IDemandControlActionPowerConsumerView;
  onSwitch: () => void;
  disabled: boolean;
}

const DeviceCardWithToggleButton = (props: IProps) => {
  const { powerConsumer, onSwitch, disabled } = props;
  const [isOn, setIsOn] = useState(powerConsumer.isSelected);

  useEffect(() => {
    setIsOn(powerConsumer.isSelected);
  }, [powerConsumer.isSelected]);
  return (
    <Row
      className={`${styles.deviceCard} p-2 mx-0 align-items-center justify-content-between h-100`}
    >
      <Col>
        <Row className="align-items-center">
          <Col className={`${powerConsumer.deviceType} p-3 rounded col-auto`}>
            <MaterialIcon
              icon={findIcon(powerConsumer.deviceType as EDeviceTypes)}
            />
          </Col>
          <Col>
            <Row>
              <Col className="font-size-16 font-weight-500 text-dark">
                {powerConsumer.name}
              </Col>
            </Row>
            <Row>
              <Col className="font-size-14 font-weight-400 text-light">
                Saving {powerConsumer.saving}W
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col className="col-auto pe-0">
        <AppSwitch
          isOn={isOn}
          size="sm"
          onSwitch={(value: boolean) => {
            onSwitch();
          }}
          disabled={disabled}
        />
      </Col>
    </Row>
  );
};

export default DeviceCardWithToggleButton;
