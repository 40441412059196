import AddDemandResponseProgramRequestDTO from "../../../shared/oversight-core/dtos/request-dtos/add-demand-response-program-request-dto";
import { IAddUsageGuideRequestDTO } from "../../../shared/oversight-core/dtos/request-dtos/add-usage-guide-request-dto";
import UpdateDemandResponseProgramRequestDTO from "../../../shared/oversight-core/dtos/request-dtos/update-demand-response-program-request-dto";
import { IViewDeviceTypeAvailabilityRequestDTO } from "../../../shared/oversight-core/dtos/request-dtos/view-device-type-availability-request-dto";
import AddDemandControlRequestResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/add-demand-control-request-response-dto";
import { IAddUsageGuideResponseDTO } from "../../../shared/oversight-core/dtos/response-dtos/add-usage-guide-response-dto";
import CheckPendingDemandControlResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/check-pending-demand-control-response-dto";
import PublishDemandControlActionResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/publish-demand-control-action-response-dto";
import UpdateDemandControlRequestResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/update-demand-control-request-response-dto";
import UpdateDeviceInDemandControlActionResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/update-device-in-demand-control-action-response-dto";
import UpdateSpaceClusterInDemandControlActionResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/update-space-cluster-in-demand-control-action-response-dto";
import ViewAllDemandControlResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-all-demand-control-response-dto";
import ViewDemandControlActionByResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-demand-control-action-by-response-dto";
import ViewDemandControlActionResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-demand-control-action-response-dto";
import ViewDemandControlResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-demand-control-response-dto";
import { IViewDeviceTypeAvailabilityResponseDTO } from "../../../shared/oversight-core/dtos/response-dtos/view-device-type-availability-response-dto";
import { IViewUsageGuideResponseDTO } from "../../../shared/oversight-core/dtos/response-dtos/view-usage-guide-response-dto";
import ViewWeeklyDemandControlRequestsResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-weekly-demand-control-requests-response-dto";
import ViewWeeklyUsageGuideResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-weekly-usage-guide-response-dto";
import { transformErrorResponse } from "../../../shared/oversight-core/utils/transformErrorResponse";
import { rootApi } from "../apiManager";

export const usageGuideAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    addUsageGuide: builder.mutation<
      IAddUsageGuideResponseDTO,
      IAddUsageGuideRequestDTO
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `admin/power-usage-guide/add`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["UsageGuide"],
    }),
    updateUsageGuide: builder.mutation<
      IAddUsageGuideResponseDTO,
      IAddUsageGuideRequestDTO
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `admin/power-usage-guide/${data.powerUsageGuideId}/update`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: ["UsageGuide"],
    }),
    removeUsageGuide: builder.mutation<
      void,
      {
        usageGuideId: string;
      }
    >({
      transformErrorResponse,
      query({ usageGuideId }) {
        return {
          url: `admin/power-usage-guide/${usageGuideId}/delete`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["UsageGuide"],
    }),
    viewUsageGuide: builder.query<
      IViewUsageGuideResponseDTO,
      { usageGuideId: string }
    >({
      transformErrorResponse,
      query({ usageGuideId }) {
        return {
          url: `admin/power-usage-guide/${usageGuideId}/view`,
          method: "GET",
        };
      },
    }),
    viewWeeklyUsageGuide: builder.query<
      ViewWeeklyUsageGuideResponseDTO,
      {
        startDate: string;
      }
    >({
      transformErrorResponse,
      query({ startDate }) {
        return {
          url: `admin/power-usage-guide/weekly/view`,
          method: "GET",
          params: {
            startDate,
          },
        };
      },
      providesTags: () => {
        return ["UsageGuide"];
      },
    }),
    viewDeviceTypeAvailability: builder.query<
      IViewDeviceTypeAvailabilityResponseDTO,
      IViewDeviceTypeAvailabilityRequestDTO
    >({
      transformErrorResponse,
      query({
        startDate,
        endDate,
        repeatMode,
        expireDate,
        excludePowerUsageGuideIdentity,
      }) {
        return {
          url: `admin/power-usage-guide/power-consumer-type-availability/view`,
          method: "GET",
          params: {
            startDate,
            endDate,
            repeatMode,
            expireDate,
            excludePowerUsageGuideIdentity,
          },
        };
      },
    }),
    demandControlRequest: builder.mutation<
      AddDemandControlRequestResponseDTO,
      AddDemandResponseProgramRequestDTO
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `admin/demand-response-program/demand-control-request/add`,
          method: "POST",
          body: data,
        };
      },
    }),
    updateDemandControlRequest: builder.mutation<
      UpdateDemandControlRequestResponseDTO,
      UpdateDemandResponseProgramRequestDTO
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `admin/demand-response-program/demand-control-request/${data.id}/update`,
          method: "PUT",
          body: data,
        };
      },
    }),
    viewDemandControlResponse: builder.query<
      ViewDemandControlResponseDTO,
      {
        demandControlRequestId: string;
      }
    >({
      transformErrorResponse,
      query({ demandControlRequestId }) {
        return {
          url: `admin/demand-response-program/demand-control-response/${demandControlRequestId}/view`,
          method: "GET",
        };
      },
    }),
    viewAllDemandControlResponses: builder.query<
      ViewAllDemandControlResponseDTO,
      {
        pageNumber?: number;
        pageSize?: number;
        sortBy?: string;
        ascending?: boolean;
      }
    >({
      transformErrorResponse,
      query({ pageNumber, pageSize, sortBy, ascending }) {
        return {
          url: `admin/demand-response-program/demand-control-response/view`,
          method: "GET",
          params: {
            pageNumber,
            pageSize,
            sortBy,
            ascending,
          },
        };
      },
    }),
    viewDemandControlActionByResponse: builder.query<
      ViewDemandControlActionByResponseDTO,
      {
        demandControlRequestId: string;
        demandControlMethod: string;
        pageNumber?: number;
        pageSize?: number;
        sortBy?: string;
        ascending?: boolean;
      }
    >({
      transformErrorResponse,
      query({
        demandControlRequestId,
        demandControlMethod,
        pageNumber,
        pageSize,
        sortBy,
        ascending,
      }) {
        return {
          url: `admin/demand-response-program/demand-control-action/${demandControlRequestId}/view-by-response`,
          method: "GET",
          params: {
            demandControlMethod,
            pageNumber,
            pageSize,
            sortBy,
            ascending,
          },
        };
      },
    }),
    viewDemandControlAction: builder.query<
      ViewDemandControlActionResponseDTO,
      {
        demandControlActionId: string;
        pageNumber?: number;
        pageSize?: number;
        sortBy?: string;
        ascending?: boolean;
      }
    >({
      transformErrorResponse,
      query({
        demandControlActionId,
        pageNumber,
        pageSize,
        sortBy,
        ascending,
      }) {
        return {
          url: `admin/demand-response-program/demand-control-action/${demandControlActionId}/view`,
          method: "GET",
          params: {
            pageNumber,
            pageSize,
            sortBy,
            ascending,
          },
        };
      },
    }),
    publishDemandControlAction: builder.mutation<
      PublishDemandControlActionResponseDTO,
      { demandControlActionId: string }
    >({
      transformErrorResponse,
      query({ demandControlActionId }) {
        return {
          url: `admin/demand-response-program/demand-control-action/${demandControlActionId}/publish`,
          method: "PUT",
        };
      },
      invalidatesTags: ["UsageGuide"],
    }),
    updateSpaceClusterInDemandControlAction: builder.mutation<
      UpdateSpaceClusterInDemandControlActionResponseDTO,
      { demandControlActionId: string; spaceClusterId: string }
    >({
      transformErrorResponse,
      query({ demandControlActionId, spaceClusterId }) {
        return {
          url: `admin/demand-response-program/demand-control-action/${demandControlActionId}/space-cluster/update`,
          method: "PUT",
          body: { spaceClusterId },
        };
      },
      invalidatesTags: ["UsageGuide"],
    }),
    updatePowerConsumerInDemandControlAction: builder.mutation<
      UpdateDeviceInDemandControlActionResponseDTO,
      {
        demandControlActionId: string;
        spaceClusterId: string;
        powerConsumerId: string;
      }
    >({
      transformErrorResponse,
      query({ demandControlActionId, spaceClusterId, powerConsumerId }) {
        return {
          url: `admin/demand-response-program/demand-control-action/${demandControlActionId}/power-consumer/update`,
          method: "PUT",
          body: { spaceClusterId, powerConsumerId },
        };
      },
    }),
    viewWeeklyDemandControlRequests: builder.query<
      ViewWeeklyDemandControlRequestsResponseDTO,
      {
        date: string;
      }
    >({
      transformErrorResponse,
      query({ date }) {
        return {
          url: `admin/demand-response-program/demand-control-request/weekly/view`,
          method: "GET",
          params: { date },
        };
      },
      providesTags: () => {
        return ["UsageGuide"];
      },
    }),
    checkPendingDemandControlResponses: builder.query<
      CheckPendingDemandControlResponseDTO,
      void
    >({
      transformErrorResponse,
      query() {
        return {
          url: `admin/demand-response-program/demand-control-response/check-pending`,
          method: "GET",
        };
      },
    }),
    deleteDemandControlRequest: builder.mutation<void, { id: string }>({
      transformErrorResponse,
      query({ id }) {
        return {
          url: `admin/demand-response-program/demand-control-request/${id}/delete`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["UsageGuide"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useAddUsageGuideMutation,
  useUpdateUsageGuideMutation,
  useRemoveUsageGuideMutation,
  useViewWeeklyUsageGuideQuery,
  useLazyViewDeviceTypeAvailabilityQuery,
  useLazyViewUsageGuideQuery,
  useDemandControlRequestMutation,
  useUpdateDemandControlRequestMutation,
  useLazyViewDemandControlResponseQuery,
  useLazyViewAllDemandControlResponsesQuery,
  useLazyViewDemandControlActionByResponseQuery,
  useLazyViewDemandControlActionQuery,
  usePublishDemandControlActionMutation,
  useUpdateSpaceClusterInDemandControlActionMutation,
  useUpdatePowerConsumerInDemandControlActionMutation,
  useViewWeeklyDemandControlRequestsQuery,
  useLazyCheckPendingDemandControlResponsesQuery,
  useDeleteDemandControlRequestMutation,
} = usageGuideAPI;
