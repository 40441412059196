import LoginLayout from "../../shared/oversight-core/shared-components/login-layout/login-layout";
import AdminLoginForm from "./login-form/login-form";

const AdminLogin = () => {
  return (
    <LoginLayout
      title="Login to Your Account"
      subTitle="Welcome to oversight, Please enter your details"
    >
      <AdminLoginForm />
    </LoginLayout>
  );
};

export default AdminLogin;
