import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import AddTimeRange, {
  ITimeRangeProps,
  initialTime,
} from "../../../../shared/components/add-time-range/add-time-range";
import { EDemandControlMethod } from "../../../../shared/oversight-core/enums/demand-control-method";
import { ERepetitionMode } from "../../../../shared/oversight-core/enums/repetition-mode";
import { AppRoute } from "../../../../shared/oversight-core/interfaces/app-routes";
import AppDatePicker from "../../../../shared/oversight-core/ui-elements/app-date-picker/app-date-picker";
import AppSelect, {
  Option,
} from "../../../../shared/oversight-core/ui-elements/app-select/app-select";
import AppButton from "../../../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import AppInput from "../../../../shared/oversight-core/ui-elements/input/app-input";
import MaterialIcon from "../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import SpinnerModal from "../../../../shared/oversight-core/ui-elements/spinner/spinner";
import { convertIsoStringToTimeList } from "../../../../shared/oversight-core/utils/convert-ISO-string-to-time-list";
import { deviceTypes } from "../../../../shared/oversight-core/utils/device-types";
import { generateRepetitionOptions } from "../../../../shared/oversight-core/utils/generate-repetition-options";
import { limitationOptions } from "../../../../shared/oversight-core/utils/limitation-options";
import { alphanumericAndSomeSpecialCharactersAndCantStartWithSpecialCharacters } from "../../../../shared/oversight-core/utils/regex";
import { generateTimeRangeList } from "../../../../shared/oversight-core/utils/time-utils";
import DemandManagementCard from "../demand-management-card/demand-management-card";
import styles from "./add-update-manual-demand-control-request.module.scss";

export interface IAddDemandControlProps {
  navigateLocation?: Parameters<NavigateFunction>;
  initialIsoTimeList?: Array<{ fromDate: string; toDate: string }>;
  demandControlId?: string; // undefined if add, otherwise edit
}

interface IFormInput {
  title: string;
  date: Date;
  repetition: Option | Option[];
  deviceCategory: Option | Option[];
  limitation: Option | Option[];
}

const defaultFormValues: IFormInput = {
  title: "",
  date: new Date(),
  repetition: {
    value: ERepetitionMode.DO_NOT_REPEAT,
    label: "Doesn't Repeat",
  },
  deviceCategory: [],
  limitation: [],
};

const AddUpdateManualDemandControlRequest = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const locationState: IAddDemandControlProps =
    location.state as IAddDemandControlProps;
  const initialDate = locationState?.initialIsoTimeList?.[0].fromDate
    ? new Date(locationState.initialIsoTimeList[0].fromDate)
    : new Date();
  const initialTimeList = locationState?.initialIsoTimeList
    ? convertIsoStringToTimeList(locationState.initialIsoTimeList)
    : [{ ...initialTime }];

  const [selectedDate, setSelectedDate] = useState(initialDate);
  const [timeList, setTimeList] = useState<ITimeRangeProps[]>(initialTimeList);
  const [dateError, setDateError] = useState("");
  const [isErrors, setIsErrors] = useState(false);
  const [repetitionOptions, setRepetitionOptions] = useState<Option[]>(
    generateRepetitionOptions(selectedDate)
  );

  const startTime = new Date("2000-01-01T00:00:00");
  const endTime = new Date("2000-01-02T00:00:00");
  const intervalMinutes = 15;

  useEffect(() => {
    setRepetitionOptions(generateRepetitionOptions(selectedDate));
  }, [selectedDate]);

  const {
    register,
    formState: { errors },
    control,
  } = useForm<IFormInput>({
    defaultValues: { ...defaultFormValues },
  });

  return (
    <div className={`container-white position-relative ${styles.overflowY}`}>
      <Row className="justify-content-between mt-2">
        <Col className="col-12 col-xxl-5">
          <Row>
            <Col>
              <Row>
                <Col className="font-size-16 font-weight-500">Request</Col>
              </Row>
              <Row className="mt-3">
                <AppInput
                  label="Title"
                  placeholder="Title"
                  name="title"
                  errors={errors}
                  register={register("title", {
                    required: "Please enter a title",
                    pattern: {
                      value:
                        alphanumericAndSomeSpecialCharactersAndCantStartWithSpecialCharacters,
                      message:
                        "Entered value must start with a alphanumeric character and can contain |, -, _, ' as special characters",
                    },
                  })}
                />
                <div className="mt-4">
                  <label>Date</label>
                  <AppDatePicker
                    dateFormat="dd MMMM yyyy"
                    selectedDate={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    isInput={true}
                    error={dateError}
                  />
                </div>
                <div className="mt-4">
                  <AddTimeRange
                    times={timeList}
                    setTimes={setTimeList}
                    timeOptions={generateTimeRangeList(
                      startTime,
                      endTime,
                      intervalMinutes
                    )}
                    isErrors={isErrors}
                    setIsErrors={setIsErrors}
                  />
                </div>
                <AppSelect
                  className="mt-4"
                  label="Repetition"
                  placeholder="Select Repetition"
                  defaultValue={""}
                  name="repetition"
                  options={repetitionOptions}
                  register={register("repetition", {
                    required: "Please select a repetition",
                  })}
                  errors={errors}
                  control={control}
                  isRequired
                />
                <AppSelect
                  label="Device Category"
                  options={[...deviceTypes]}
                  className="mt-4"
                  placeholder="Select Device Category"
                  defaultValue={""}
                  name="deviceCategory"
                  register={register("deviceCategory", {
                    required: "Please select device categories",
                  })}
                  control={control}
                  errors={errors}
                  isMultiSelect
                  closeMenuOnSelect={false}
                  isRequired
                  menuHeight="200px"
                />
                <AppSelect
                  className="mt-4"
                  label="Effect Level of Limitation"
                  placeholder="Select Limitation"
                  defaultValue={""}
                  name="limitation"
                  control={control}
                  errors={errors}
                  register={register("limitation", {
                    required: "Please select effect level of limitation",
                  })}
                  options={limitationOptions}
                  isRequired
                />
              </Row>
            </Col>
            <Col xs={1} className="d-none d-xxl-block ps-4">
              <div className={styles["vertical-line"]}></div>
            </Col>
          </Row>
        </Col>
        <Col className="d-block d-xxl-none mt-5 mb-3">
          <div className={styles["horizontal-line"]}></div>
        </Col>
        <Col className="col-12 col-xxl-7 mt-4 mt-xxl-0">
          <Row>
            <Col className="font-size-16 font-weight-500">Response</Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <DemandManagementCard
                demandControllerMethod={{
                  demandControlMethod:
                    EDemandControlMethod.PROPORTIONATELY_FROM_EVERY_USER,
                  clients: 231,
                  devices: 637,
                  maximumPowerSaving: 1.1,
                }}
                isRadioButton={false}
              />
            </Col>
          </Row>
          <Row className={`mt-4 mx-0 py-3 ${styles.limitationInfo}`}>
            <Col className="col-auto">
              <MaterialIcon icon="live_help" color=" #A7AA18" />
            </Col>
            <Col className="ps-0">
              <Row>
                <Col className="font-size-14">
                  <div className="font-weight-500">Usage Limitations:</div>
                  <div className="font-weight-400 text-light mt-1">
                    Usage limitation is a method used to control consumer usage
                    by issuing warnings or imposing restrictions on specific
                    device types based on the time of day.
                  </div>
                  <div className="font-weight-400 text-light mt-3">
                    *It can be especially useful for managing demand during
                    high-usage times.
                  </div>
                  <div className="font-weight-400 text-light mt-3">
                    If the limitations of overlapping devices are the same, then
                    they will be merged; otherwise, they will be overridden or
                    skipped
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="justify-content-center justify-content-sm-between mt-4 px-2`">
        <Col className="col-auto">
          <AppButton
            onClick={() => navigate(AppRoute.ADMIN_USAGE_GUIDE)}
            text="Cancel"
            variant="transparentWithBorder"
            size="medium"
          />
        </Col>
        <Col className="col-auto">
          <AppButton
            onClick={() => {}}
            isLoading={false}
            text="Generate"
            variant="blue"
            size="medium"
            id="demand-request"
          />
        </Col>
      </Row>
      <SpinnerModal show={false} />
    </div>
  );
};

export default AddUpdateManualDemandControlRequest;
