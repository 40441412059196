import moment from "moment";
import { Col, Row } from "react-bootstrap";
import ViewDemandControlActionResponseDTO from "../../../../shared/oversight-core/dtos/response-dtos/view-demand-control-action-response-dto";
import { EDemandControlActionStatus } from "../../../../shared/oversight-core/enums/demand-control-action-status";
import { EDemandControlMethod } from "../../../../shared/oversight-core/enums/demand-control-method";
import { EPowerMeasureType } from "../../../../shared/oversight-core/enums/power-measure-type";
import DataTable from "../../../../shared/oversight-core/ui-elements/data-table/data-table";
import MaterialIcon from "../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import { findDemandControllerMethod } from "../../../../shared/oversight-core/utils/find-demand-controller-method";
import { powerConvertor } from "../../../../shared/oversight-core/utils/power-convertor";
import { formatTime } from "../../../../shared/oversight-core/utils/time-utils";
import Pagination from "../../../users/pagination/pagination";
import styles from "./reduction-request-view.module.scss";

interface IProps {
  onBack: () => void;
  headers: { id: number; columnName: string }[];
  tableData: { data: JSX.Element[]; disable: boolean }[];
  totalElements: number;
  filters: { pageNumber: number; pageSize: number };
  setFilters: React.Dispatch<
    React.SetStateAction<{
      pageNumber: number;
      pageSize: number;
    }>
  >;
  demandControlResponseView?: ViewDemandControlActionResponseDTO;
  demandControlActionStatus?: EDemandControlActionStatus;
}

const ReductionRequestView = (props: IProps) => {
  const {
    onBack,
    headers,
    tableData,
    totalElements,
    filters,
    setFilters,
    demandControlResponseView,
    demandControlActionStatus,
  } = props;

  return (
    <div className="container-white">
      <Row className="align-items-center">
        <Col>
          <Row className="align-items-center">
            <Col className="col-auto pe-0">
              <div className={`${styles.arrowBtn} bg-primary p-1`}>
                <MaterialIcon
                  icon="arrow_left"
                  className="text-white"
                  style={{ cursor: "pointer" }}
                  onClick={() => onBack()}
                />
              </div>
            </Col>
            <Col className="font-size-16 font-weight-500 text-dark">
              {
                demandControlResponseView?.demandControlResponseView
                  .demandControlRequestView.title
              }
            </Col>
          </Row>
        </Col>
        {/* <Col className="col-auto">
          <Row>
            <Col>
              <MaterialIcon
                icon={"delete"}
                color="#CB343D"
                size={22}
                className="cursor-pointer"
              />
            </Col>
          </Row>
        </Col> */}
      </Row>
      <Row className={`${styles.reductionCard} mt-3 mx-0 py-3 px-4`}>
        <Col className="col-auto">
          <Row>
            <Col className="col-12 col-sm-auto me-4">
              <Row>
                <Col className="font-size-12 font-weight-400 text-dark">
                  {moment(
                    demandControlResponseView?.demandControlResponseView
                      .demandControlRequestView.demandControlDate
                  ).format("DD/MM/YYYY")}
                </Col>
              </Row>
              <Row className="mt-2">
                <Col className="font-size-12 font-weight-400 text-light">
                  {`${formatTime(
                    new Date(
                      demandControlResponseView?.demandControlResponseView
                        .demandControlRequestView.demandControlPeriod
                        .fromDate || 0
                    )
                  )} - ${formatTime(
                    new Date(
                      demandControlResponseView?.demandControlResponseView
                        .demandControlRequestView.demandControlPeriod.toDate ||
                        0
                    )
                  )}`}
                </Col>
              </Row>
            </Col>
            <Col className="ms-0 ms-sm-0 mt-2 mt-sm-0">
              <Row>
                <Col>
                  <Row>
                    <Col className="col-auto font-size-12 font-weight-400 text-light pe-0">
                      Max Usage Prediction :
                    </Col>
                    <Col className="font-size-12 font-weight-500 text-dark ps-1">
                      {powerConvertor(
                        demandControlResponseView?.demandControlResponseView
                          .predictedMaximumPowerUsage || 0,
                        EPowerMeasureType.MEGA_WATT
                      ).toFixed(2)}{" "}
                      MW
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col>
                  <Row>
                    <Col className="col-auto font-size-12 font-weight-400 text-light pe-0">
                      Power Available :
                    </Col>
                    <Col className="font-size-12 font-weight-500 text-dark ps-1">
                      {powerConvertor(
                        demandControlResponseView?.demandControlResponseView
                          .availablePower || 0,
                        EPowerMeasureType.MEGA_WATT
                      ).toFixed(2)}{" "}
                      MW
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col>
                  <Row>
                    <Col className="col-auto font-size-12 font-weight-400 text-light pe-0">
                      Reduction Needed :
                    </Col>
                    <Col className="font-size-12 font-weight-500 text-dark ps-1">
                      {powerConvertor(
                        demandControlResponseView?.demandControlResponseView
                          .reductionNeededPowerUsage || 0,
                        EPowerMeasureType.MEGA_WATT
                      ).toFixed(2)}{" "}
                      MW
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col
          className={`${styles.ventricleLine} d-none d-xxl-block col-auto mx-0 mx-xxl-4`}
        ></Col>
        <Col
          className={`${styles.horizontalLine} d-block d-xxl-none col-12 mt-3`}
        ></Col>
        <Col className="col-12 col-xxl-auto text-center mt-3 mt-xxl-0">
          <Row>
            <Col className="font-size-16 font-weight-500 text-dark">
              {findDemandControllerMethod(
                demandControlResponseView?.demandControlResponseView
                  .demandControlMethods[0]
                  .demandControlMethod as EDemandControlMethod
              )}
            </Col>
          </Row>
          <Row className="mt-2 row-cols-auto justify-content-center">
            <Col>
              <Row>
                <Col className="font-size-20 font-weight-500 text-light">
                  {
                    demandControlResponseView?.demandControlResponseView
                      .demandControlMethods[0].clients
                  }
                </Col>
              </Row>
              <Row>
                <Col className="font-size-14 font-weight-400 text-light">
                  Clients
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col className="font-size-20 font-weight-500 text-light">
                  {
                    demandControlResponseView?.demandControlResponseView
                      .demandControlMethods[0].devices
                  }
                </Col>
              </Row>
              <Row>
                <Col className="font-size-14 font-weight-400 text-light">
                  Devices
                </Col>
              </Row>
            </Col>
            <Col>
              <Row className={`${styles.txt} font-size-20 font-weight-500`}>
                <Col>
                  {powerConvertor(
                    demandControlResponseView?.demandControlResponseView
                      .demandControlMethods[0].maximumPowerSaving || 0,
                    EPowerMeasureType.MEGA_WATT
                  ).toFixed(2)}{" "}
                  MW
                </Col>
              </Row>
              <Row>
                <Col className="font-size-14 font-weight-400 text-light">
                  Max Saving
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col className="font-size-20 font-weight-500 text-light">
                  {powerConvertor(
                    demandControlResponseView?.demandControlResponseView
                      .demandControlMethods[0].buffer || 0,
                    EPowerMeasureType.MEGA_WATT
                  ).toFixed(2)}{" "}
                  kW
                </Col>
              </Row>
              <Row>
                <Col className="font-size-14 font-weight-400 text-light">
                  Buffer
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        {demandControlActionStatus &&
          demandControlActionStatus !==
            EDemandControlActionStatus.NOT_STARTED && (
            <>
              <Col
                className={`${styles.ventricleLine} d-none d-xxl-block col-auto mx-0 mx-xxl-4`}
              ></Col>
              <Col
                className={`${styles.horizontalLine} d-block d-xxl-none col-12 mt-3`}
              ></Col>
              <Col className="col-12 col-xxl-auto text-center mt-3 mt-xxl-0">
                <Row>
                  <Col className="font-size-16 font-weight-500 text-dark">
                    {demandControlActionStatus ===
                    EDemandControlActionStatus.IN_PROGRESS
                      ? `Current Status`
                      : `Final State`}
                  </Col>
                </Row>
                <Row className="mt-2 row-cols-auto justify-content-center">
                  <Col>
                    <Row>
                      <Col className="font-size-20 font-weight-500 text-light">
                        {powerConvertor(
                          demandControlResponseView?.totalUsage || 0,
                          EPowerMeasureType.MEGA_WATT
                        ).toFixed(2)}{" "}
                        MW
                      </Col>
                    </Row>
                    <Row>
                      <Col className="font-size-14 font-weight-400 text-light">
                        Usage
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row>
                      <Col
                        className={`${styles.txt} font-size-20 font-weight-500`}
                      >
                        {powerConvertor(
                          demandControlResponseView?.totalSaving || 0,
                          EPowerMeasureType.MEGA_WATT
                        ).toFixed(2)}{" "}
                        MW
                      </Col>
                    </Row>
                    <Row>
                      <Col className="font-size-14 font-weight-400 text-light">
                        Saving
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </>
          )}
      </Row>
      <Row className="mt-3">
        <DataTable headers={headers} data={tableData} />
        <Pagination
          itemsPerPage={filters.pageSize}
          length={totalElements}
          currentPage={filters.pageNumber + 1}
          updateCurrentPage={(pn) => {
            setFilters((ps) => ({ ...ps, pageNumber: pn - 1 }));
          }}
        />
      </Row>
    </div>
  );
};

export default ReductionRequestView;
