import { useNavigate } from "react-router-dom";
import { useDeleteDemandControlRequestMutation } from "../../../../redux/api/usage-guide/usageGuideAPI";
import { AppRoute } from "../../../../shared/oversight-core/interfaces/app-routes";
import ModalContainer, {
  ModelContainerProps,
} from "../../../../shared/oversight-core/ui-elements/modal-container/modal-container";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../shared/oversight-core/utils/toast";

interface IProps extends ModelContainerProps {
  show: boolean;
  demandControlRequestId: string;
  onSuccess: () => void;
}

const DemandControlRequestDeleteModal = (props: IProps) => {
  const { demandControlRequestId, show, ...rest } = props;
  const navigate = useNavigate();

  const [
    deleteDemandControlRequest,
    { isLoading: isLoadingDeleteDemandControlRequest },
  ] = useDeleteDemandControlRequestMutation();

  const handleOnConfirm = () => {
    deleteDemandControlRequest({
      id: demandControlRequestId,
    })
      .unwrap()
      .then(() => {
        showSuccessMessage("Demand Control Request Deleted Successfully");
        props.onClose && props.onClose();
        props.onSuccess && props.onSuccess();
        navigate(AppRoute.ADMIN_USAGE_GUIDE);
      })
      .catch(() => {
        showErrorMessage("Demand Control Request Deleted Unsuccessful");
        props.onClose && props.onClose();
      });
  };

  return (
    <ModalContainer
      {...rest}
      title={"Delete Demand Control Request"}
      show={show}
      size="modal-md"
      confirmButtonText="Delete"
      confirmButtonVariant="red"
      cancelButtonText="Cancel"
      onConfirm={handleOnConfirm}
      isLoading={isLoadingDeleteDemandControlRequest}
    >
      <>
        <p className="text-light font-weight-400 font-size-14">
          Are you sure you want to delete demand control request?
        </p>
      </>
    </ModalContainer>
  );
};

export default DemandControlRequestDeleteModal;
