export const deviceTypeColor = [
  { value: "TV", color: "#f4eef8" },
  { value: "Refrigerator", color: "#eef8f6" },
  { value: "Microwave Oven", color: "#eef6f8" },
  { value: "Bulb", color: "#eef8ee" },
  { value: "Computer", color: "#f7f8ee" },
  { value: "Iron", color: "#eef1f8" },
  { value: "Washing Machine", color: "#f7f8ee" },
  { value: "Air Conditioner", color: "#f8f4ee" },
  { value: "Fan", color: "#eef8f8" },
];
