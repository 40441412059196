import styles from "./radio-button.module.scss";

interface IProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
}

const RadioButton = (props: IProps) => {
  const { checked, onChange } = props;

  return (
    <label className={styles[`radio-button`]}>
      <input
        type="radio"
        name="checked"
        checked={checked}
        defaultChecked={checked}
        onChange={(e) => onChange(e.target.checked)}
      />
      <div className={styles[`radio-circle`]}></div>
    </label>
  );
};

export default RadioButton;
