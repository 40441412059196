import { Col, Row } from "react-bootstrap";
import AppButton from "../../ui-elements/buttons/app-button/app-button";
import MaterialIcon from "../../ui-elements/material-icon/material-icon";
import styles from "./filtering-side-panel.module.scss";

interface IProps {
  isOpen: boolean;
  children?: JSX.Element;
  onClearAll?: () => void;
  onClose?: () => void;
  onFilter?: () => void;
  isLoading?: boolean;
}

interface IBackdropProps {
  isOpen: boolean;
  onClick: () => void;
}

const Backdrop = (props: IBackdropProps) => {
  const { isOpen, onClick } = props;
  return isOpen ? <div className={styles.backdrop} onClick={onClick} /> : null;
};

const FilteringSidePanel = (props: IProps) => {
  const {
    isOpen = false,
    isLoading = false,
    children,
    onClearAll,
    onClose,
    onFilter,
  } = props;

  return (
    <>
      <div className={`${styles["side-drawer"]} ${isOpen && styles.open}`}>
        <Row className="mt-2">
          <Col className="col-1">
            <MaterialIcon
              icon="filter_alt"
              className="cursor-pointer"
              onClick={onClose}
              color="#383941"
            />
          </Col>
          <Col className={`${styles.filterSidePanelTitle}`}>Filters</Col>
          <Col className="col-auto align-items-center fw-bold">
            <MaterialIcon
              icon="close"
              className="cursor-pointer"
              onClick={onClose}
              color="#505662"
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>{children}</Col>
        </Row>
        <Row className="align-items-center justify-content-between mt-5">
          <Col className="col-auto">
            <AppButton
              text="Filter"
              variant="blue"
              size="medium"
              className="px-5"
              onClick={() => {
                if (onFilter && !isLoading) {
                  onFilter();
                }
              }}
              isLoading={isLoading}
            />
          </Col>
          <Col className="col-auto pe-0">
            <AppButton
              text="Clear All"
              variant="transparent"
              onClick={() => {
                if (onClearAll) {
                  onClearAll();
                }
              }}
              size="medium"
              className="px-3"
            />
          </Col>
        </Row>
      </div>
      <Backdrop
        isOpen={isOpen}
        onClick={() => {
          if (onClose) {
            onClose();
          }
        }}
      />
    </>
  );
};

export default FilteringSidePanel;
