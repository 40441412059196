import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setToken } from "../../../../redux/features/auth/auth-slice";
import { resetBillingSpaceFilter } from "../../../../redux/features/billing-space-filter/billing-space-filter-slice";
import { selectProfile } from "../../../../redux/features/user/user-slice";
import FeedbackButton from "../../../components/feedback-button/feedback-button";
import oversightLogo from "../../assets/images/oversight_logo.svg";
import oversightLogoC from "../../assets/images/oversight_logo_c.svg";
import { UserRole } from "../../enums/user-roles";
import { AppRoute } from "../../interfaces/app-routes";
import IPageInfo from "../../interfaces/page-info";
import FeedbackModal from "../../shared-components/feedback-modal/feedback-modal";
import LogoutModal from "../../shared-components/logout-modal/logout-modal";
import MenuItem from "../../shared-components/sidebar/components/menu-item/menu-item";

interface IProps {
  collapsed?: boolean;
  closeMobileNav?: () => void;
  routes: IPageInfo[];
}

const AppMenuContent = (props: IProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { collapsed = false, closeMobileNav, routes } = props;
  const [showLogout, setShowLogout] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const profile = useSelector(selectProfile);

  const logout = () => {
    localStorage.clear();
    dispatch(setToken(""));
    dispatch(resetBillingSpaceFilter());

    if (routes) {
      if (profile?.role === UserRole.ROLE_ADMIN) {
        navigate(AppRoute.ADMIN_LOGIN);
      } else {
        navigate(AppRoute.LOGIN);
      }
    }
  };

  return (
    <Container className="h-100">
      <div className="h-100 d-flex flex-column justify-content-between py-4">
        <Row>
          <Col>
            <Row>
              <Col xs={12}>
                {collapsed ? (
                  <img
                    src={oversightLogoC}
                    alt="Oversight Logo"
                    className="d-block mx-auto mb-4"
                    style={{ marginTop: "28px" }}
                  />
                ) : (
                  <img
                    src={oversightLogo}
                    alt="Oversight Logo"
                    className="d-block mx-auto mb-4"
                    style={{ marginTop: "28px" }}
                  />
                )}
              </Col>
            </Row>
            <Row>
              {routes.map((sc) => {
                return sc?.subRoutes
                  ?.filter((item) => item.showOnDrawer)
                  .map((subRoute) => (
                    <Col
                      xs={12}
                      key={subRoute.path}
                      className="py-3 text-center d-flex justify-content-center"
                    >
                      <MenuItem
                        active={pathname.includes(subRoute.path)}
                        collapsed={props.collapsed}
                        icon={subRoute.icon}
                        to={subRoute.path}
                        text={subRoute.name}
                        onClick={closeMobileNav && closeMobileNav}
                        enableRoute={subRoute.enableRoute}
                        bgColor={subRoute.bgColor}
                        bgColorIcon={subRoute.bgColorIcon}
                      />
                    </Col>
                  ));
              })}
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-center py-3">
          <Col xs="auto">
            {profile?.role === UserRole.ROLE_USER && (
              <FeedbackButton
                collapsed={props.collapsed}
                setShowFeedback={() => setShowFeedback(true)}
              />
            )}
            <Row className="mt-1">
              <Col>
                <MenuItem
                  active={false}
                  collapsed={props.collapsed}
                  icon="logout"
                  onClick={() => setShowLogout(true)}
                  text="Logout"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <LogoutModal
        show={showLogout}
        onCancel={() => setShowLogout(false)}
        onClose={() => setShowLogout(false)}
        onConfirm={() => {
          setShowLogout(false);
          logout();
        }}
      />
      <FeedbackModal
        show={showFeedback}
        onCancel={() => setShowFeedback(false)}
        onClose={() => setShowFeedback(false)}
        onConfirm={() => {
          setShowFeedback(false);
        }}
      />
    </Container>
  );
};

export default AppMenuContent;
