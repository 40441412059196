import { Col, Row } from "react-bootstrap";
import ModalContainer, {
  ModelContainerProps,
} from "../../../../shared/oversight-core/ui-elements/modal-container/modal-container";

interface IProps extends ModelContainerProps {
  show: boolean;
  spaceStatus: boolean;
}

const AccountActivateDeactivateConfirmModal = (props: IProps) => {
  const { show, spaceStatus, ...rest } = props;

  return (
    <ModalContainer
      {...rest}
      title={`${spaceStatus ? `Enable` : `Disable`} Billing Space`}
      show={show}
      size="modal-md"
      confirmButtonText="Confirm"
      cancelButtonText="Cancel"
    >
      <Row>
        <Col className="text-light font-size-14 font-weight-400">
          Are you sure you want to {spaceStatus ? `enable` : `disable`} the
          billing space
        </Col>
      </Row>
    </ModalContainer>
  );
};

export default AccountActivateDeactivateConfirmModal;
