import { Col, Row } from "react-bootstrap";
import styles from "./app-switch.module.scss";

interface IProps {
  isOn: boolean;
  onSwitch?: (value: boolean) => void;
  size: "sm" | "md" | "lg";
  disabled?: boolean;
}

const AppSwitch = (props: IProps) => {
  const { onSwitch, isOn, size = "md", disabled } = props;

  return (
    <Row>
      <Col>
        <div
          className={`${styles.switch} ${styles[size]} ${
            disabled ? "cursor-disabled" : "cursor-pointer"
          }  ${isOn ? styles.onBackground : styles.offBackground}`}
          onClick={() => {
            onSwitch?.(!isOn);
          }}
        >
          <div
            className={`${isOn ? styles.on : styles.off} ${styles.button}`}
          />
        </div>
      </Col>
    </Row>
  );
};

export default AppSwitch;
