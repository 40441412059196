import { Col, Row } from "react-bootstrap";
import PuffLoader from "react-spinners/PuffLoader";
import MaterialIcon, { MtIcon } from "../../material-icon/material-icon";
import styles from "./app-button.module.scss";

export type ButtonVariant =
  | "red"
  | "blue"
  | "transparent"
  | "gray"
  | "light"
  | "transparentWithBorder";
export type ButtonSize = "small" | "large" | "medium" | "extra-small";
interface IProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  text: string;
  variant?: ButtonVariant;
  size?: ButtonSize;
  isLoading?: boolean;
  loaderColor?: string;
  iconName?: MtIcon;
  iconOnly?: boolean;
  iconColor?: string;
  isCursorDefault?: boolean;
  disabled?: boolean;
  isShowIndicator?: boolean;
}

const AppButton = (props: IProps) => {
  const {
    text,
    variant,
    isLoading = false,
    iconName,
    iconColor,
    loaderColor,
    iconOnly = false,
    id,
    isShowIndicator = false,
    ...rest
  } = props;

  const getVariantClass = () => {
    switch (variant) {
      case "red":
        return styles["app-button-red"];
      case "transparent":
        return styles["app-button-transparent"];
      case "transparentWithBorder":
        return styles["app-button-transparent-with-border"];
      case "gray":
        return styles["app-button-gray"];
      case "light":
        return styles["app-button-light"];
      default:
        return styles["app-button-blue"];
    }
  };
  const getSizeClass = () => {
    switch (props.size) {
      case "small":
        return styles["app-button-small"];
      case "medium":
        return styles["app-button-medium"];
      case "extra-small":
        return styles["app-button-extra-small"];
      default:
        return styles["app-button-large"];
    }
  };

  const getButtonContent = () => {
    if (isLoading) {
      return <PuffLoader color={loaderColor || "white"} size={18} />;
    } else if (iconOnly) {
      return (
        <MaterialIcon
          size={22}
          icon={iconName}
          className="justify-content-center"
        />
      );
    } else {
      return (
        <>
          <Row className="justify-content-center align-items-center g-2">
            {iconName && (
              <Col className="col-auto ">
                <MaterialIcon
                  size={17}
                  icon={iconName}
                  color={iconColor}
                  className="justify-content-center"
                />
              </Col>
            )}
            <Col className="col-auto">
              {isShowIndicator && (
                <Row className="justify-content-end">
                  <Col className="col-auto position-relative">
                    <div
                      className={`${styles.indicator} position-absolute`}
                    ></div>
                  </Col>
                </Row>
              )}
              {text}
            </Col>
          </Row>
        </>
      );
    }
  };

  return (
    <button
      {...{
        ...rest,
        className: `${rest.className} ${
          rest.disabled && styles["app-button-disabled"]
        } ${styles["app-button"]} ${getVariantClass()} ${getSizeClass()} ${
          iconOnly && styles["appIconButton"]
        }`,
      }}
      disabled={isLoading || props.disabled}
      id={id}
    >
      {getButtonContent()}
    </button>
  );
};

export default AppButton;
