import { Col, Row } from "react-bootstrap";
import AppButton from "../../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import styles from "./search.module.scss";
interface IProps {
  onChange: (searchText: string) => void;
  onSearch: () => void;
  placeholder: string;
  value: string;
}

const Search = (props: IProps) => {
  const { onChange, onSearch, placeholder, value } = props;

  return (
    <div className={`${styles[`search-wrapper`]}`}>
      <Row className={`align-items-center`}>
        <Col>
          <input
            type="text"
            placeholder={placeholder}
            value={value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onChange(e.target.value);
            }}
            className={`${styles[`search-input`]} ps-2 w-100 `}
          />
        </Col>
        <Col className="col-auto">
          <AppButton
            className="py-2"
            text="Search"
            iconName="search"
            iconOnly
            size="medium"
            onClick={() => {
              onSearch();
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Search;
