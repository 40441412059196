import { configureStore } from "@reduxjs/toolkit";
import { rootApi } from "./api/apiManager";
import { rtkQueryErrorLogger } from "./api/rtkQueryErrorLogger";
import listenerMiddleware from "./api/websocket-middleware";
import authReducer from "./features/auth/auth-slice";
import notificationReducer from "./features/notification/notification-slice";
import billingSpaceFilterReducer from "./features/billing-space-filter/billing-space-filter-slice";
import usageGuideFilterReducer from "./features/usage-guide-filters/usage-guide-filters-slice";
import userReducer from "./features/user/user-slice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    billingSpaceFilter: billingSpaceFilterReducer,
    usageGuideFilter: usageGuideFilterReducer,
    notifications: notificationReducer,
    [rootApi.reducerPath]: rootApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    // getDefaultMiddleware().concat([rootApi.middleware, rtkQueryErrorLogger]),
    getDefaultMiddleware()
      .prepend(listenerMiddleware.middleware)
      .concat([rootApi.middleware, rtkQueryErrorLogger]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
