import { skipToken } from "@reduxjs/toolkit/query";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useLazyViewConsumerSpaceClusterByIdentityQuery } from "../../../redux/api/consumer/consumerAPI";
import {
  useLazyViewManualScheduleMonthlyEnergyForSubSpaceClusterQuery,
  useLazyViewSemiAutomationScheduleMonthlyEnergyForSubSpaceClusterQuery,
  useViewWeeklyManualSchedulesQuery,
  useViewWeeklySemiAutomationSchedulesQuery,
} from "../../../redux/api/schedule/scheduleAPI";
import SpacePathViewBreadcrumb from "../../../shared/components/space-path-view-breadcrumb/space-path-view-breadcrumb";
import AdminViewWeeklySemiAutomationScheduleResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/admin-view-weekly-semi-automation-schedule-response-dto";
import ViewConsumerSubSpaceClusterByIdentityResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-consumer-space-cluster-by-identity";
import {
  default as IViewSemiAutomationScheduleMonthlyEnergyForSubSpaceClusterResponseDTO,
  default as ViewSemiAutomationScheduleMonthlyEnergyForSubSpaceClusterResponseDTO,
} from "../../../shared/oversight-core/dtos/response-dtos/view-semi-automation-schedule-monthly-energy-for-sub-space-cluster-response-dto";
import ViewWeeklyManualSchedulesResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-weekly-manual-schedules-response-dto";
import { EManagementMode } from "../../../shared/oversight-core/enums/management-mode";
import { ETimeGridYAxisType } from "../../../shared/oversight-core/enums/time-grid-y-axis-type";
import { AppRoute } from "../../../shared/oversight-core/interfaces/app-routes";
import { ISpaceView } from "../../../shared/oversight-core/interfaces/entities/space";
import { IScheduledShallowView } from "../../../shared/oversight-core/interfaces/scheduled-shallow-view";
import {
  DataColumn,
  Header,
  TimeGridEvent,
} from "../../../shared/oversight-core/shared-components/calendar/models";
import useTimeGrid, {
  sundayStartToMondayStart,
} from "../../../shared/oversight-core/shared-components/calendar/useTimeGrid";
import AppDatePicker from "../../../shared/oversight-core/ui-elements/app-date-picker/app-date-picker";
import { Option } from "../../../shared/oversight-core/ui-elements/app-select/app-select";
import AppButton from "../../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import MaterialIcon from "../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import NavigateWeeks from "../../../shared/oversight-core/ui-elements/navigate-weeks/navigate-weeks";
import SpinnerModal from "../../../shared/oversight-core/ui-elements/spinner/spinner";
import StatsViewTypeTwo from "../../../shared/oversight-core/ui-elements/stats-view/stats-view-type-two";
import { getMonthRangeToDisplay } from "../../../shared/oversight-core/utils/date-utils";
import Helper from "../../../shared/oversight-core/utils/helpers";
import { offsetToString } from "../../../shared/oversight-core/utils/offsetToString";
import { scrollToView } from "../../../shared/oversight-core/utils/scroll-to-view";
import CreateModal from "./components/create-modal/create-modal";
import MiniCalendar from "./components/mini-calendar/mini-calendar";
import ScheduleTabView from "./components/schedule-tab-view/schedule-tab-view";
import styles from "./schedule.module.scss";

interface IProps {
  selectedSpace: { clusterId: string; spaceId: string };
  sendSelectedSpace: (space: ISpaceView) => void;
}

type ColumnDataType = Date | string; // x-axis can be Date or Device ID

const defaultScheduleMonthlyEnergyForSubSpaceCluster: IViewSemiAutomationScheduleMonthlyEnergyForSubSpaceClusterResponseDTO =
  {
    spaceClusterEnergy: {
      energyBill: 0,
      energyInUnits: 0,
    },
    spaceClusterAverageWeeklyEnergy: {
      weekdayDailyEnergy: {
        energyBill: 0,
        energyInUnits: 0,
      },
      weekendDailyEnergy: {
        energyBill: 0,
        energyInUnits: 0,
      },
      weeklyEnergy: {
        energyBill: 0,
        energyInUnits: 0,
      },
    },
  };

const getWeekRange = (date: Date, selectedDate: Date): Date[] => {
  const dayOfWeek = date.getDay();

  let difference = dayOfWeek - 1;

  if (difference < 0) {
    difference = 6;
  }

  const dates: Date[] = [];
  const startOfWeek = new Date(selectedDate);
  startOfWeek.setDate(date.getDate() - difference);

  for (let i = 0; i < 7; i++) {
    const newDate = new Date(startOfWeek);
    dates.push(new Date(newDate.setDate(newDate.getDate() + i)));
  }

  return dates;
};

const Schedule = (props: IProps) => {
  const [noDevices, setNoDevices] = useState(false);
  const [noSmartDevices, setNoSmartDevices] = useState(false);
  const [deviceHeaders, setDeviceHeaders] = useState<
    { deviceName: string; deviceId: string }[]
  >([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedManagementMode, setSelectedManagementMode] = useState<Option>({
    value: EManagementMode.SEMI_AUTO,
    label: "Semi-Auto",
  });
  const [events, setEvents] = useState<
    DataColumn<ColumnDataType, IScheduledShallowView>[]
  >([]);
  const [weekRange, setWeekRange] = useState<Date[]>(
    getWeekRange(new Date(), selectedDate)
  );
  const [isGoToCurrentTime, setIsGoToCurrentTime] = useState(false);
  const [
    scheduleMonthlyEnergyForSubSpaceCluster,
    setScheduleMonthlyEnergyForSubSpaceCluster,
  ] =
    useState<ViewSemiAutomationScheduleMonthlyEnergyForSubSpaceClusterResponseDTO>(
      { ...defaultScheduleMonthlyEnergyForSubSpaceCluster }
    );
  const [loading, setIsLoading] = useState(true);
  const [weeklyManualScheduleEstimation, setWeeklyManualScheduleEstimation] =
    useState<ViewWeeklyManualSchedulesResponseDTO>();
  const [
    weeklySemiAutoScheduleEstimation,
    setWeeklySemiAutoScheduleEstimation,
  ] = useState<AdminViewWeeklySemiAutomationScheduleResponseDTO>();
  const [space, setSpace] =
    useState<ViewConsumerSubSpaceClusterByIdentityResponseDTO>();

  const [triggerGetSpace, { isFetching: isFetchingSpace }] =
    useLazyViewConsumerSpaceClusterByIdentityQuery();
  const [
    triggerViewSemiAutomationScheduleMonthlyEnergyForSubSpaceCluster,
    { isFetching: isFetchingViewSemiAutomationScheduleMonthlyEnergy },
  ] = useLazyViewSemiAutomationScheduleMonthlyEnergyForSubSpaceClusterQuery();
  const [
    triggerViewManualScheduleMonthlyEnergyForSubSpaceCluster,
    { isFetching: isFetchingViewManualScheduleMonthlyEnergy },
  ] = useLazyViewManualScheduleMonthlyEnergyForSubSpaceClusterQuery();
  const [selectedGridType, setSelectedGridType] = useState<ETimeGridYAxisType>(
    ETimeGridYAxisType.DATE
  );
  const [selectedSpace, setSelectedSpace] = useState<{
    clusterId: string;
    spaceId: string;
  }>({ clusterId: "", spaceId: "" });
  const [selectedBillingSpace, setSelectedBillingSpace] = useState<{
    clusterId: string;
    spaceId: string;
  }>({ clusterId: "", spaceId: "" });

  useEffect(() => {
    setSelectedSpace(props.selectedSpace);
    setSelectedBillingSpace(props.selectedSpace);
  }, [props.selectedSpace]);

  // Build grid headers. This can be Dates of week or Devices
  const headers: Header<ColumnDataType>[] = [];

  switch (selectedGridType) {
    case ETimeGridYAxisType.DATE:
      for (let cIndex = 0; cIndex < 7; cIndex++) {
        headers.push({
          columnId: moment(weekRange[cIndex]).toDate(),
          title: moment(weekRange[cIndex]).format("DD"),
          value: (
            <div className="d-flex flex-column">
              <div>{moment(weekRange[cIndex]).format("ddd")}</div>
              <div
                className={`${
                  moment(weekRange[cIndex]).format("YYYY-MM-DD") ===
                  moment().format("YYYY-MM-DD")
                    ? `today-grid-style`
                    : ``
                } px-1`}
              >
                {moment(weekRange[cIndex]).format("DD")}
              </div>
            </div>
          ),
          column: cIndex + 1, // +1 for row header
          header: true,
          row: 0,
        });
      }
      break;
    case ETimeGridYAxisType.DEVICE:
      for (let cIndex = 0; cIndex < deviceHeaders.length; cIndex++) {
        headers.push({
          columnId: deviceHeaders[cIndex].deviceId,
          title: deviceHeaders[cIndex].deviceName,
          value: <div>{deviceHeaders[cIndex].deviceName}</div>,
          column: cIndex + 1, // +1 for row header
          header: true,
          row: 0,
        });
      }
      break;
  }

  useEffect(() => {
    if (selectedSpace) {
      triggerGetSpace({
        spaceClusterId: selectedSpace.clusterId,
        subRootSpaceId: selectedSpace.spaceId,
      })
        .unwrap()
        .then((response: ViewConsumerSubSpaceClusterByIdentityResponseDTO) => {
          setSpace(response);
          setNoDevices(
            response.subSpaceCluster.rootSpace.powerConsumers.length < 1
          );
          setNoSmartDevices(
            response.subSpaceCluster.rootSpace.smartDevices.filter(
              (smartDevice) => smartDevice.linkedPowerConsumer
            ).length === 0
          );
          const specificSmartDevices =
            response.subSpaceCluster.rootSpace.smartDevices.filter(
              (smartDevice) => smartDevice.linkedPowerConsumer
            );
          const devices = response.subSpaceCluster.rootSpace.powerConsumers;

          const deviceHeaders: { deviceName: string; deviceId: string }[] = [];

          if (selectedManagementMode.value === EManagementMode.SEMI_AUTO) {
            specificSmartDevices.forEach((smartDevice) => {
              const matchingPowerConsumer =
                response.subSpaceCluster.rootSpace.powerConsumers.find(
                  (powerConsumer) =>
                    powerConsumer.id === smartDevice.linkedPowerConsumer?.id
                );

              if (matchingPowerConsumer) {
                const isDeviceAlreadyExist = deviceHeaders.find(
                  (device) => device.deviceId === matchingPowerConsumer.id
                );
                if (!isDeviceAlreadyExist) {
                  deviceHeaders.push({
                    deviceName: matchingPowerConsumer.name,
                    deviceId: matchingPowerConsumer.id,
                  });
                }
              }
            });
          } else {
            devices.forEach((device) => {
              deviceHeaders.push({
                deviceName: device.name,
                deviceId: device.id,
              });
            });
          }

          setDeviceHeaders(deviceHeaders);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [
    selectedGridType,
    selectedSpace,
    selectedManagementMode.value,
    triggerGetSpace,
  ]);

  let isCurrentWeek = false;
  for (const day of weekRange) {
    if (moment(day).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {
      isCurrentWeek = true;
      break;
    }
  }

  const onCurrentTimeIndicatorRender = useCallback(
    (elemId: string) => {
      if (isGoToCurrentTime) {
        scrollToView(elemId);
        setIsGoToCurrentTime(false);
      }
    },
    [isGoToCurrentTime]
  );
  const isShowTimeBar = isCurrentWeek;

  const { calendarContent } = useTimeGrid<
    ColumnDataType,
    IScheduledShallowView
  >(
    useMemo(() => {
      return {
        events,
        headers,
        isShowTimeBar,
        isDisableCreatingOverlay: true,
        onCurrentTimeIndicatorRender,
        defaultTitle: "New Schedule",
        isDeviceView: selectedGridType === ETimeGridYAxisType.DEVICE,
        viewEventModalTemplate: (events, onClose) => {
          return (
            <CreateModal<ColumnDataType>
              id=""
              events={events}
              onEditClick={() => {}}
              onDelete={() => {}}
              onClose={onClose}
            />
          );
        },
        eventContentTemplate: (events) => {
          const renderOneEvent = (
            event: TimeGridEvent<ColumnDataType, IScheduledShallowView>,
            isGrouped: boolean
          ) => (
            <>
              <Row>
                <Col
                  className={`${
                    isGrouped ? `font-size-9` : `font-size-10`
                  } font-weight-500 text-white`}
                >
                  <div className={isGrouped ? `${styles.title} bg-gray-4` : ""}>
                    {event.title}
                  </div>
                </Col>
              </Row>
              {event.data?.isDraft && (
                <Row>
                  <Col
                    className={`${
                      isGrouped ? `font-size-9` : `font-size-10`
                    } font-weight-400 text-white`}
                  >
                    (Draft)
                  </Col>
                </Row>
              )}
              {!isGrouped && (
                <Row>
                  <Col className="font-size-9 font-weight-500">
                    {moment()
                      .startOf("day")
                      .add(event.startTime, "minutes")
                      .format("HH:mm")}
                    -
                    {moment()
                      .startOf("day")
                      .add(event.endTime, "minutes")
                      .format("HH:mm")}
                  </Col>
                </Row>
              )}
            </>
          );
          return (
            <>
              {events?.length > 1 && (
                <Row>
                  <Col className="font-size-10 font-weight-500">
                    {events.length} Collapsed Events
                  </Col>
                </Row>
              )}
              {events?.map((event, eventIndex) => {
                return (
                  <Row key={eventIndex}>
                    <Col>{renderOneEvent(event, events?.length > 1)}</Col>
                  </Row>
                );
              })}
            </>
          );
        },
      };
    }, [
      events,
      headers,
      isShowTimeBar,
      onCurrentTimeIndicatorRender,
      selectedGridType,
    ])
  );

  const startDate = weekRange[0];
  const validForRequest =
    selectedManagementMode.value &&
    selectedSpace &&
    ((deviceHeaders.length > 0 &&
      selectedGridType === ETimeGridYAxisType.DEVICE) ||
      selectedGridType === ETimeGridYAxisType.DATE);
  const {
    data: semiAutomationScheduleResponse,
    isFetching: isFetchingViewWeeklySemiAutomationSchedules,
  } = useViewWeeklySemiAutomationSchedulesQuery(
    validForRequest
      ? {
          spaceClusterId: selectedSpace.clusterId,
          subRootSpaceId: selectedSpace.spaceId,
          startDate: moment(startDate).startOf("day").toISOString(true),
        }
      : skipToken
  );
  const {
    data: manualScheduleResponse,
    isFetching: isFetchingViewWeeklyManualSchedules,
  } = useViewWeeklyManualSchedulesQuery(
    validForRequest
      ? {
          spaceClusterId: selectedSpace.clusterId,
          subRootSpaceId: selectedSpace.spaceId,
          startDate: moment(startDate).startOf("day").toISOString(true),
        }
      : skipToken
  );

  useEffect(() => {
    if (manualScheduleResponse || semiAutomationScheduleResponse) {
      setWeeklyManualScheduleEstimation(manualScheduleResponse);
      setWeeklySemiAutoScheduleEstimation(semiAutomationScheduleResponse);
    }
  }, [manualScheduleResponse, semiAutomationScheduleResponse]);

  useEffect(() => {
    if (
      validForRequest &&
      (selectedManagementMode.value === EManagementMode.SEMI_AUTO
        ? semiAutomationScheduleResponse
        : manualScheduleResponse)
    ) {
      setEvents([]);
      const groupByDateOrDevId: Record<
        number | string,
        DataColumn<ColumnDataType, IScheduledShallowView>
      > = {};
      (selectedManagementMode.value === EManagementMode.SEMI_AUTO
        ? semiAutomationScheduleResponse?.shallowSemiAutomatedScheduleAdminViews
        : manualScheduleResponse?.shallowScheduleViews
      )?.forEach((sp: IScheduledShallowView) => {
        switch (selectedGridType) {
          case ETimeGridYAxisType.DATE:
            sp.scheduledPeriods.forEach((s) => {
              const fromDate = moment(new Date(s.fromDate));
              const toDate = moment(new Date(s.toDate));

              const day = fromDate.day();
              const columnId = fromDate.toDate().toISOString();
              const columnData = fromDate.toDate();
              const generated = {
                title: sp.title,
                columnData,
                startTime: fromDate
                  .clone()
                  .diff(fromDate.clone().startOf("day"), "minutes"),
                endTime: toDate
                  .clone()
                  .diff(toDate.clone().startOf("day"), "minutes"),
                date: fromDate.toDate(),
                data: sp,
              };

              if (generated) {
                // need to collect the event since event is generated
                if (!groupByDateOrDevId[day]) {
                  // create a column if not exist
                  groupByDateOrDevId[day] = {
                    columnId,
                    columnData,
                    columnIndex: sundayStartToMondayStart(day),
                    events: [],
                  };
                }
                // then collect the event
                groupByDateOrDevId[day].events.push(generated);
              }
            });
            break;
          case ETimeGridYAxisType.DEVICE:
            sp.scheduledPeriods.forEach((s) => {
              sp.powerConsumers.forEach((device) => {
                const fromDate = moment(new Date(s.fromDate));
                const toDate = moment(new Date(s.toDate));
                const columnIndex = deviceHeaders.findIndex(
                  (deviceHeader) => device.id === deviceHeader.deviceId
                );
                if (columnIndex > -1) {
                  const matchedDeviceHeader = deviceHeaders[columnIndex];
                  const columnId = matchedDeviceHeader.deviceId;
                  const columnData = matchedDeviceHeader.deviceId;
                  const generated = {
                    title: sp.title,
                    columnData,
                    startTime: fromDate
                      .clone()
                      .diff(fromDate.clone().startOf("day"), "minutes"),
                    endTime: toDate
                      .clone()
                      .diff(toDate.clone().startOf("day"), "minutes"),
                    date: fromDate.toDate(),
                    data: sp,
                  };

                  if (
                    generated &&
                    moment(s.fromDate)
                      .startOf("day")
                      .isSame(moment(selectedDate).startOf("day"))
                  ) {
                    // need to collect the event since event is generated
                    if (!groupByDateOrDevId[matchedDeviceHeader.deviceId]) {
                      // create a column if not exist
                      groupByDateOrDevId[matchedDeviceHeader.deviceId] = {
                        columnId,
                        columnData,
                        columnIndex,
                        events: [],
                      };
                    }
                    // then collect the event
                    groupByDateOrDevId[
                      matchedDeviceHeader.deviceId
                    ].events.push(generated);
                  }
                }
              });
            });
            break;
        }
      });
      setEvents(Object.values(groupByDateOrDevId));
    } else {
      setEvents([]);
    }
  }, [
    semiAutomationScheduleResponse,
    manualScheduleResponse,
    validForRequest,
    selectedDate,
    startDate,
    selectedGridType,
    selectedManagementMode.value,
    deviceHeaders,
  ]);

  useEffect(() => {
    if (selectedBillingSpace) {
      (selectedManagementMode.value === EManagementMode.SEMI_AUTO
        ? triggerViewSemiAutomationScheduleMonthlyEnergyForSubSpaceCluster
        : triggerViewManualScheduleMonthlyEnergyForSubSpaceCluster)({
        spaceClusterId: selectedBillingSpace.clusterId,
        subRootSpaceId: selectedBillingSpace.spaceId,
        year: +moment(selectedDate).format("yy"),
        month: +moment(selectedDate).format("MM"),
        zoneOffset: offsetToString(moment().utcOffset()),
      })
        .unwrap()
        .then((res) => {
          setScheduleMonthlyEnergyForSubSpaceCluster(res);
          setIsLoading(true);
        })
        .catch(() => {
          setScheduleMonthlyEnergyForSubSpaceCluster({
            ...defaultScheduleMonthlyEnergyForSubSpaceCluster,
          });
        });
    }
  }, [
    selectedDate,
    selectedManagementMode.value,
    triggerViewSemiAutomationScheduleMonthlyEnergyForSubSpaceCluster,
    triggerViewManualScheduleMonthlyEnergyForSubSpaceCluster,
    selectedBillingSpace,
  ]);

  useEffect(() => {
    setWeekRange(getWeekRange(new Date(selectedDate), selectedDate));
  }, [selectedDate]);

  const onClickThisWeekButton = () => {
    const currentDate = new Date();
    const firstDayOfWeek = getWeekRange(currentDate, currentDate)[0];
    setSelectedDate(firstDayOfWeek);
  };

  const weeklyScheduleEstimation = (
    <StatsViewTypeTwo
      variant="bg-icon-2"
      labelValue={
        selectedManagementMode.value === EManagementMode.MANUAL
          ? Helper.roundTo2(
              weeklyManualScheduleEstimation?.manualScheduleEnergy?.unit || 0
            ) + ""
          : Helper.roundTo2(
              weeklySemiAutoScheduleEstimation?.semiAutomatedScheduleEnergy
                ?.unit || 0
            ) + ""
      }
      title="ESTIMATED UNITS"
      isFetching={
        isFetchingViewWeeklyManualSchedules ||
        isFetchingViewWeeklySemiAutomationSchedules
      }
    />
  );

  const weeklySchedulePercentage = (
    <StatsViewTypeTwo
      variant="bg-icon-2"
      labelValue={
        selectedManagementMode.value === EManagementMode.MANUAL
          ? Helper.roundTo2(
              weeklyManualScheduleEstimation?.manualSchedulePercentage || 0
            ) + "%"
          : Helper.roundTo2(
              weeklySemiAutoScheduleEstimation?.semiAutomatedSchedulePercentage ||
                0
            ) + "%"
      }
      title="OF TOTAL UNITS"
      isFetching={
        isFetchingViewWeeklyManualSchedules ||
        isFetchingViewWeeklySemiAutomationSchedules
      }
      infoText="Percentage compared to the Space Cluster"
    />
  );

  const sendSelectedSpace = (space: ISpaceView) => {
    setSelectedSpace({
      clusterId: space.clusterId,
      spaceId: space.id,
    });
  };

  return (
    <div className="position-relative">
      {space ? (
        <>
          <div className="mt-3">
            <ScheduleTabView
              selectedDate={new Date(selectedDate)}
              isFetching={
                (isFetchingViewSemiAutomationScheduleMonthlyEnergy &&
                  loading) ||
                (isFetchingViewManualScheduleMonthlyEnergy && loading)
              }
              scheduledSpaceClusterAverageWeeklyEnergy={
                scheduleMonthlyEnergyForSubSpaceCluster.spaceClusterAverageWeeklyEnergy
              }
              spaceClusterEstimatedConsumption={
                scheduleMonthlyEnergyForSubSpaceCluster.spaceClusterEnergy
              }
              selectedManagementMode={selectedManagementMode}
              setSelectedDate={setSelectedDate}
              setSelectedManagementMode={setSelectedManagementMode}
            />
          </div>
          <div className="position-relative mt-4">
            <Row>
              <Col>
                <SpacePathViewBreadcrumb
                  selectedSpace={selectedSpace}
                  sendSelectedSpace={sendSelectedSpace}
                />
              </Col>
            </Row>
            <Row className="align-items-start mt-4 mb-1">
              <Col className="col-12 col-xxl-8">
                <Row className="align-items-center">
                  <Col className="col-auto">
                    <AppDatePicker
                      selectedDate={new Date(selectedDate)}
                      onChange={(date: Date) => {
                        setSelectedDate(date);
                      }}
                      isInput={true}
                      showDate={false}
                    />
                  </Col>
                  <Col className="col-auto">
                    <NavigateWeeks
                      selectedDate={new Date(selectedDate)}
                      handleDateChange={(date: Date) => {
                        setSelectedDate(date);
                        setIsLoading(false);
                      }}
                    />
                  </Col>
                  <Col className="text-light font-weight-500 font-size-12 col-auto">
                    {getMonthRangeToDisplay(weekRange)}
                  </Col>
                  <Col className="col-6 col-sm-auto mt-3 mt-sm-0 ps-sm-0">
                    <AppButton
                      text="This Week"
                      size="medium"
                      onClick={onClickThisWeekButton}
                      className="font-size-12 font-weight-500"
                      variant="transparentWithBorder"
                    />
                  </Col>
                  <Col className={`${styles.nowBtn} col-6 col-sm-auto`}>
                    <AppButton
                      text="Now"
                      size="medium"
                      onClick={() => {
                        setIsGoToCurrentTime(true);
                        onClickThisWeekButton();
                      }}
                      className="font-size-12 font-weight-500"
                      variant="transparentWithBorder"
                    />
                  </Col>
                  <Col
                    className="col-6 col-sm-auto"
                    onClick={() => {
                      setSelectedGridType((ps) =>
                        ps === ETimeGridYAxisType.DEVICE
                          ? ETimeGridYAxisType.DATE
                          : ETimeGridYAxisType.DEVICE
                      );
                    }}
                  >
                    <Row
                      className={`${styles.miniCalendarToggler} cursor-pointer align-items-center justify-content-center py-2 mx-0`}
                    >
                      <Col className="col-auto">
                        <MaterialIcon icon="swap_horiz" />
                      </Col>
                      <Col className="col-auto text-light font-size-12 font-weight-500">
                        {selectedGridType === ETimeGridYAxisType.DEVICE
                          ? ETimeGridYAxisType.DATE
                          : ETimeGridYAxisType.DEVICE}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col
                className={`${
                  selectedGridType === ETimeGridYAxisType.DEVICE &&
                  `d-none d-xxl-block`
                } col-12 col-xxl-4 mt-3 mt-xxl-0`}
              >
                <Row className="align-items-center justify-content-start justify-content-xxl-end">
                  <Col className="col-12 col-sm col-xxl-6 mt-2 mt-sm-0">
                    {weeklyScheduleEstimation}
                  </Col>
                  <Col className="col-12 col-sm col-xxl-6 mt-2 mt-sm-0">
                    {weeklySchedulePercentage}
                  </Col>
                </Row>
              </Col>
            </Row>
            {selectedGridType === ETimeGridYAxisType.DEVICE && (
              <>
                <Row className="ps-2 mt-2 mt-xxl-0">
                  <Col>
                    <MiniCalendar
                      startDate={weekRange[0]}
                      selectedDate={new Date(selectedDate)}
                      handleDateChange={(date: Date) => {
                        setSelectedDate(date);
                      }}
                    />
                  </Col>
                </Row>
                <Row className="align-items-center mt-2">
                  <Col className="col-6 d-block d-xxl-none">
                    {weeklyScheduleEstimation}
                  </Col>
                  <Col className="col-6 d-block d-xxl-none">
                    {weeklySchedulePercentage}
                  </Col>
                </Row>
              </>
            )}
            <Row className="mt-4">
              <Col>
                {selectedManagementMode.value === EManagementMode.SEMI_AUTO ? (
                  <>
                    {!noSmartDevices || events.length > 0 ? (
                      <div>{calendarContent}</div>
                    ) : (
                      <div className="container-dash mt-4">
                        <Row>
                          <Col className="text-center text-light font-size-12">
                            This space does not contain any smart devices.
                          </Col>
                        </Row>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {!noDevices ? (
                      <div>{calendarContent}</div>
                    ) : (
                      <div className="container-dash mt-4">
                        <Row>
                          <Col className="text-center text-light font-size-12">
                            This space does not contain any devices.
                          </Col>
                        </Row>
                      </div>
                    )}
                  </>
                )}
              </Col>
            </Row>
            <SpinnerModal
              show={
                isFetchingViewSemiAutomationScheduleMonthlyEnergy ||
                isFetchingViewManualScheduleMonthlyEnergy ||
                isFetchingViewWeeklySemiAutomationSchedules ||
                isFetchingViewWeeklyManualSchedules ||
                isFetchingSpace
              }
            />
          </div>
        </>
      ) : (
        <>
          {!isFetchingSpace && (
            <div className="container-dash mt-4">
              <Row>
                <Col className="text-center text-light font-size-12">
                  You have not created any billing spaces.{" "}
                  <Link to={AppRoute.SPACE_CLUSTERS}>Create Billing Space</Link>
                </Col>
              </Row>
            </div>
          )}
        </>
      )}
      <SpinnerModal show={isFetchingSpace} />
    </div>
  );
};

export default Schedule;
