import { OvstErrorCode } from "../../oversight-core/enums/ovst-error-codes";
import { showErrorMessage } from "../../oversight-core/utils/toast";

export const showSwitchErrorMessage = (errorCode: OvstErrorCode) => {
  switch (errorCode) {
    case OvstErrorCode.OVST_CONN_0001:
      showErrorMessage("Device is not online.");
      break;
    case OvstErrorCode.OVST_CONN_0002:
      showErrorMessage("Invalid identity.");
      break;
    case OvstErrorCode.OVST_CONN_0003:
      showErrorMessage(
        "Failure in one of the services. Please try again later."
      );
  }
};
