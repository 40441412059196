import { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  useLazyGetNotificationsQuery,
  useLazyGetUnreadNotificationsCountQuery,
} from "../../../redux/api/notification/notificationAPI";
import { countUnreadNotifications } from "../../../redux/features/notification/notification-slice";
import { selectProfile } from "../../../redux/features/user/user-slice";
import { adminPrivateRoutes } from "../../../screens/routes";
import { UserRole } from "../../oversight-core/enums/user-roles";
import { AppRoute } from "../../oversight-core/interfaces/app-routes";
import MobileNavBar from "../../oversight-core/shared-components/mobile-nav-bar/mobile-nav-bar";
import NotificationsPanel from "../../oversight-core/shared-components/notifications-panel/notifications-panel";
import SideBar from "../../oversight-core/shared-components/sidebar/side-bar";
import MaterialIcon from "../../oversight-core/ui-elements/material-icon/material-icon";
import ScrollToTop from "../../oversight-core/ui-elements/scroll-to-top/ScrollToTop";
import ErrorBoundary from "../../oversight-core/utils/ErrorBoundary";
import styles from "./dashboard-layout.module.scss";

enum ScrollingExcludedPages {
  USER = "USERS",
}

const DashboardLayout = () => {
  const profile = useSelector(selectProfile);
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showNotifications, setShowNotifications] = useState(false);

  const [triggerGetNotifications] = useLazyGetNotificationsQuery();
  const [triggerGetUnreadNotificationsCount] =
    useLazyGetUnreadNotificationsCountQuery();
  const unreadNotificationCount = useSelector(countUnreadNotifications);

  const getCurrentPageInfo = (path: string) => {
    const page = adminPrivateRoutes
      .filter((route) => route.root)[0]
      ?.subRoutes?.find((pi) => pi.path === path);

    return page;
  };

  const getCurrentPageTitle = () => {
    const lastSlashIndex = pathname.lastIndexOf("/");

    if (getCurrentPageInfo(pathname)?.name === undefined) {
      return getCurrentPageInfo(pathname.substring(0, lastSlashIndex) + "/:id")
        ?.name;
    } else {
      return getCurrentPageInfo(pathname)?.name;
    }
  };

  const closeOpen = () => {
    setOpen((ps) => !ps);
  };

  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    const animateTitleLength = 2;
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        const nextIndex = prevIndex + 1;
        if (nextIndex === animateTitleLength - 1) clearInterval(interval);
        return nextIndex % animateTitleLength;
      });
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    triggerGetNotifications({
      pageSize: 10,
      pageNumber: 0,
      isFirstTime: true,
      ascending: true,
    });
  }, [triggerGetNotifications]);

  useEffect(() => {
    triggerGetUnreadNotificationsCount();
  }, [triggerGetUnreadNotificationsCount]);

  const scrollingExcluded = () => {
    return !Object.values(ScrollingExcludedPages).includes(
      pathname.split("/")[1].toUpperCase() as ScrollingExcludedPages
    )
      ? "content-container"
      : "";
  };

  return (
    <div className={`${styles["main-container"]} vh-100`}>
      <div
        className={`${styles["navbar-container"]} vh-100 d-none d-md-block ${
          collapsed
            ? styles["navbar-container-collapsed"]
            : styles["navbar-container-notCollapsed"]
        }`}
      >
        <SideBar collapsed={collapsed} routes={adminPrivateRoutes} />
      </div>
      <div
        className={`${styles["main-content"]} ${
          collapsed
            ? styles["main-content-collapsed"]
            : styles["main-content-notCollapsed"]
        }`}
      >
        <div
          className={`ps-lg-2 py-3 ${styles["header-container"]} ${
            collapsed
              ? styles["main-content-collapsed"]
              : styles["main-content-notCollapsed"]
          }`}
        >
          <Container fluid className={styles["header"]}>
            <Row className="justify-content-between align-items-center">
              <Col xs="auto">
                <Row className="align-items-center">
                  <Col
                    xs="auto"
                    className="d-flex align-item-center pe-0 d-none d-lg-block"
                  >
                    <MaterialIcon
                      icon="menu"
                      onClick={handleCollapse}
                      className={`me-2 ${styles["collapse-icon"]}`}
                      style={{ cursor: "pointer" }}
                    />
                  </Col>
                  <Col xs="auto" className="pe-4 pe-lg-0">
                    <Row className="align-item-center gap-2">
                      {getCurrentPageInfo(pathname)?.name === "Dashboard" ? (
                        <Col
                          xs="auto"
                          className={`${styles["title-long"]} p-0`}
                        >
                          <div className={styles[`heading-text-container`]}>
                            <ul
                              className={`${
                                styles[`heading-text-list`]
                              } m-0 p-0`}
                              style={{
                                top: `-${currentIndex * 50}px`,
                              }}
                            >
                              <li className={styles[`heading-text`]}>
                                <span className={styles[`heading-text-color`]}>
                                  Hello {profile?.firstName},
                                </span>{" "}
                                Welcome to Oversight
                              </li>
                              <li className={styles[`heading-text`]}>
                                Dashboard
                              </li>
                            </ul>
                          </div>
                        </Col>
                      ) : (
                        <Col xs="auto" className={`${styles["title"]} p-0`}>
                          <Col>{getCurrentPageTitle()}</Col>
                        </Col>
                      )}
                      <Col
                        xs="auto"
                        className="p-0 d-flex d-lg-none align-item-center"
                      >
                        <MaterialIcon
                          icon="menu"
                          onClick={() => {
                            setOpen((ps) => !ps);
                          }}
                          className={`me-2 d-md-none ${styles["open-icon"]}`}
                          style={{ cursor: "pointer" }}
                        />
                        <MaterialIcon
                          icon="menu"
                          onClick={handleCollapse}
                          className={`me-2 ${styles["collapse-icon"]} d-none d-md-block`}
                          style={{ cursor: "pointer" }}
                        />
                        <MobileNavBar
                          isOpen={open}
                          closeOpen={closeOpen}
                          routes={adminPrivateRoutes}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col xs="auto" className="order-first order-lg-last">
                <Container>
                  <Row className="align-items-center justify-content-center justify-content-sm-end pe-4">
                    <Col xs="auto" className="order-2 order-md-1 me-1">
                      <div className="position-relative">
                        <MaterialIcon
                          size={30}
                          icon="notifications"
                          onClick={() =>
                            setShowNotifications(!showNotifications)
                          }
                          className="cursor-pointer"
                        />
                        {unreadNotificationCount > 0 ? (
                          <div className={styles.notificationBadge}>
                            {unreadNotificationCount > 9
                              ? "9+"
                              : unreadNotificationCount}
                          </div>
                        ) : null}
                        <div
                          className={`${styles.triangle} ${
                            showNotifications && styles.open
                          }`}
                        ></div>
                      </div>
                    </Col>
                    <Col xs="auto" className="px-2 order-1 order-lg-2">
                      <Avatar
                        name={`${profile?.firstName} ${profile?.lastName}`}
                        size="40"
                        round={true}
                        onClick={() => {
                          navigate(
                            profile && profile.role === UserRole.ROLE_USER
                              ? AppRoute.USER_PROFILE
                              : AppRoute.ADMIN_PROFILE
                          );
                        }}
                        className="cursor-pointer"
                      />
                    </Col>
                    <Col xs="auto" className="p-0 d-none d-lg-block order-lg-3">
                      <div
                        className="text-dark-2 font-weight-600 cursor-pointer"
                        onClick={() => {
                          navigate(
                            profile && profile.role === UserRole.ROLE_USER
                              ? AppRoute.USER_PROFILE
                              : AppRoute.ADMIN_PROFILE
                          );
                        }}
                      >
                        {profile?.firstName} {profile?.lastName}
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </div>
        <div className={`ps-lg-2 pb-4 ${styles[`${scrollingExcluded()}`]}`}>
          <Container fluid>
            <ScrollToTop />
            <Row>
              <Col xs={12} className={styles["content"]}>
                <ErrorBoundary key={pathname}>
                  <Outlet />
                </ErrorBoundary>
              </Col>
            </Row>
          </Container>
        </div>
        <NotificationsPanel showNotifications={showNotifications} />
      </div>
    </div>
  );
};

export default DashboardLayout;
