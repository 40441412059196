import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";
import NoChartDataView from "../no-chart-data-view/no-chart-data-view";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

interface IProps {
  borderColor1: string;
  borderColor2?: string;
  borderColor3?: string;
  borderColor4?: string;
  backgroundColor1: string;
  backgroundColor2?: string;
  backgroundColor3?: string;
  backgroundColor4?: string;
  label1: string;
  label2?: string;
  label3?: string;
  label4?: string;
  data1: Array<number>;
  data2?: Array<number>;
  data3?: Array<number>;
  data4?: Array<number>;
  yAxesUnit: string;
  labels: Array<string>;
  hasSecondDataSet?: boolean;
  hasThirdDataSet?: boolean;
  hasForthDataSet?: boolean;
  isChartDataAvailable?: boolean;
}

const AreaChart = (props: IProps) => {
  const labels = props.labels;
  const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        position: "top" as const,
        align: "start" as const,
        labels: {
          usePointStyle: true,
          pointStyleWidth: 10,
          boxHeight: 7,
          font: {
            size: 14,
            family: "Poppins",
            weight: "500",
          },
          color: "#383941",
        },
      },
      tooltip: {
        yAlign: "bottom" as const,
        titleAlign: "center" as const,
        backgroundColor: "#ffffff",
        titleColor: "#383941",
        bodyColor: "#69768B",
        bodyFont: {
          size: 12,
          family: "Poppins",
          weight: "500",
        },
        titleFont: { size: 10, family: "Poppins" },
        displayColors: false,
        callbacks: {
          label: (context: any) => {
            let label = "";
            if (context.parsed.y) {
              if (props.yAxesUnit === "Rs.") {
                label = props.yAxesUnit + " " + context.parsed.y;
              } else {
                label = context.parsed.y + " " + props.yAxesUnit;
              }
            }
            return label;
          },
        },
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          callback: (value: any) => {
            return value + " " + props.yAxesUnit;
          },
        },
        beginAtZero: true,
      },
    },
  };

  const legendMargin = {
    id: "legendMargin",
    beforeInit: (chart: any) => {
      const fitValue = chart.legend.fit;
      chart.legend.fit = () => {
        fitValue.bind(chart.legend)();
        return (chart.legend.height += 30);
      };
    },
  };

  const data1 = {
    labels,
    datasets: [
      {
        fill: true,
        label: props.label1,
        data: props.data1,
        borderColor: props.borderColor1,
        backgroundColor: props.backgroundColor1,
      },
    ],
  };

  const data2 = {
    labels,
    datasets: [
      {
        fill: true,
        label: props.label1,
        data: props.data1,
        borderColor: props.borderColor1,
        backgroundColor: props.backgroundColor1,
      },
      {
        fill: true,
        label: props.label2,
        data: props.data2,
        borderColor: props.borderColor2,
        backgroundColor: props.backgroundColor2,
      },
    ],
  };

  const data3 = {
    labels,
    datasets: [
      {
        fill: true,
        label: props.label1,
        data: props.data1,
        borderColor: props.borderColor1,
        backgroundColor: props.backgroundColor1,
      },
      {
        fill: true,
        label: props.label2,
        data: props.data2,
        borderColor: props.borderColor2,
        backgroundColor: props.backgroundColor2,
      },
      {
        fill: true,
        label: props.label3,
        data: props.data3,
        borderColor: props.borderColor3,
        backgroundColor: props.backgroundColor3,
      },
    ],
  };

  const data4 = {
    labels,
    datasets: [
      {
        fill: true,
        label: props.label1,
        data: props.data1,
        borderColor: props.borderColor1,
        backgroundColor: props.backgroundColor1,
      },
      {
        fill: true,
        label: props.label2,
        data: props.data2,
        borderColor: props.borderColor2,
        backgroundColor: props.backgroundColor2,
      },
      {
        fill: true,
        label: props.label3,
        data: props.data3,
        borderColor: props.borderColor3,
        backgroundColor: props.backgroundColor3,
      },
      {
        fill: true,
        label: props.label4,
        data: props.data4,
        borderColor: props.borderColor4,
        backgroundColor: props.backgroundColor4,
      },
    ],
  };

  return (
    <div
      style={{
        width: "100%",
        overflowX: "auto",
        overflowY: "hidden",
        height: "100%",
      }}
    >
      <div
        style={{
          minWidth: props.isChartDataAvailable ? "calc(100vw - 350px)" : "100%",
          width: "1000px",
          minHeight: props.isChartDataAvailable
            ? "calc(100vh - 300px)"
            : "calc(100vh - 190px)",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        {props.isChartDataAvailable ? (
          <Line
            options={options}
            data={
              props.hasForthDataSet
                ? data4
                : props.hasThirdDataSet
                ? data3
                : props.hasSecondDataSet
                ? data2
                : data1
            }
            plugins={[legendMargin]}
          />
        ) : (
          <NoChartDataView />
        )}
      </div>
    </div>
  );
};

export default AreaChart;
