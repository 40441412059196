import { Col, Row } from "react-bootstrap";
import ModalContainer, {
  ModelContainerProps,
} from "../../../../shared/oversight-core/ui-elements/modal-container/modal-container";

interface IProps extends ModelContainerProps {
  show: boolean;
}

const PowerConsumerDeactivateConfirmModal = (props: IProps) => {
  const { show, ...rest } = props;

  return (
    <ModalContainer
      {...rest}
      title=""
      show={show}
      size="modal-md"
      confirmButtonText="Confirm"
      cancelButtonText="Cancel"
    >
      <Row>
        <Col className="text-light font-size-14 font-weight-400">
          You are trying to deactivate the last active device. This will
          deactivate the account as there are no other active devices
        </Col>
      </Row>
    </ModalContainer>
  );
};

export default PowerConsumerDeactivateConfirmModal;
