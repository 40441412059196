import Tooltip from "rc-tooltip";
import { Col, Row } from "react-bootstrap";
import MaterialIcon from "../material-icon/material-icon";

interface IProps {
  onlyIcon?: boolean;
}

const LastKnownStatus = (props: IProps) => {
  const { onlyIcon } = props;
  return (
    <Tooltip
      placement="top"
      trigger={["hover"]}
      overlayInnerStyle={{
        borderColor: "#F8FA9C",
        backgroundColor: "#F8F9EB",
      }}
      overlay={
        <div className="text-light font-size-12 font-weight-400">
          Last known status before went offline
        </div>
      }
    >
      <Row className="align-items-center user-select-none">
        {!onlyIcon && (
          <Col className="col-auto font-size-8 text-light font-weight-400 ps-1 pe-0">
            Last Known
          </Col>
        )}

        <Col className={!onlyIcon ? "ps-1" : "ps-3"}>
          <MaterialIcon icon="info" color="#69768B" size={10} />
        </Col>
      </Row>
    </Tooltip>
  );
};

export default LastKnownStatus;
