import Avatar from "react-avatar";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import MaterialIcon from "../material-icon/material-icon";
import styles from "./user-details.module.scss";

interface IUserDetailsProps {
  name: string;
  username: string;
  email: string;
  contact: string;
  avatar: string;
}

const UserDetails = (props: IUserDetailsProps) => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Row className="w-100 justify-content-between align-items-center">
      <Col md={12} lg="auto" className="">
        <Row className="align-items-center">
          <Col lg="auto" className="col-2">
            <div
              className={`${styles.backButton} d-flex align-items-center`}
              onClick={goBack}
            >
              <MaterialIcon icon="arrow_left" />
            </div>
          </Col>
          <Col lg="auto" className="col-3 px-1">
            <Avatar
              className={`${styles.Avatar}`}
              name={props.name}
              size="50"
              round={true}
            />
          </Col>
          <Col lg="auto" className="col-7">
            <div className={`${styles.displayName}`}>{props.name}</div>
            <div className={`${styles.userName}`}>{props.username}</div>
          </Col>
        </Row>
      </Col>
      <Col
        lg="auto"
        className="d-flex flex-column d-lg-block align-items-center mt-3 mt-md-0"
      >
        <div className={`${styles.contacts} text-center text-lg-end`}>
          {props.email}
        </div>
        <div className={`${styles.contacts} text-center text-lg-end`}>
          {props.contact}
        </div>
      </Col>
    </Row>
  );
};

export default UserDetails;
