import { EPowerMeasureType } from "../enums/power-measure-type";

export const powerConvertor = (
  powerValue: number,
  convertPowerMeasureType: EPowerMeasureType
) => {
  switch (convertPowerMeasureType) {
    case EPowerMeasureType.KILO_WATT:
      return powerValue / 1000;
    case EPowerMeasureType.MEGA_WATT:
      return powerValue / 1000000;
    case EPowerMeasureType.GIGA_WATT:
      return powerValue / 1000000000;
    default:
      return powerValue;
  }
};
