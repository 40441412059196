import Tooltip from "rc-tooltip";
import { useCallback } from "react";
import { Col, Row } from "react-bootstrap";
import { EConsumptionValueComparison } from "../../enums/consumption-comparison";
import { EUsageDataFrom } from "../../enums/usage-data-from";
import Helper from "../../utils/helpers";
import MaterialIcon from "../material-icon/material-icon";
import SpinnerModal from "../spinner/spinner";
import styles from "./usage-value.module.scss";

interface IProps {
  cost: number;
  units: number;
  subTitle: string;
  comparisonWithLastMonth?: EConsumptionValueComparison;
  isFetching: boolean;
  isNoValues?: boolean;
  usageDataFrom?: EUsageDataFrom;
  predictedUsageText?: string;
}

const UsageValue = (props: IProps) => {
  const switchIcon = useCallback(() => {
    switch (props.comparisonWithLastMonth) {
      case EConsumptionValueComparison.HIGH:
        return <MaterialIcon icon="trending_up" color="#E75B74" />;
      case EConsumptionValueComparison.LOW:
        return <MaterialIcon icon="trending_down" color="#59C270" />;
      case EConsumptionValueComparison.EQUAL:
        return <MaterialIcon icon="arrow_right_alt" color="#819EB9" />;
      default:
        return <div className="py-1 my-2"></div>;
    }
  }, [props.comparisonWithLastMonth]);

  const switchBackgroundColor = useCallback(() => {
    switch (props.comparisonWithLastMonth) {
      case EConsumptionValueComparison.HIGH:
        return "#FFFBFB";
      case EConsumptionValueComparison.LOW:
        return "#F7FFF6";
      case EConsumptionValueComparison.EQUAL:
        return "#F7F9FB";
      default:
        return "";
    }
  }, [props.comparisonWithLastMonth]);

  return (
    <div
      className={`${styles.container} py-2 px-2`}
      style={{
        backgroundColor: `${switchBackgroundColor()}`,
      }}
    >
      <Row>
        <Col className={styles.title}>{props.subTitle}</Col>
      </Row>
      {props.isFetching ? (
        <div className="d-flex">
          <SpinnerModal withOverlay={false} show={props.isFetching} size="md" />
        </div>
      ) : (
        <>
          {props.isNoValues && !props.isFetching ? (
            <div className="text-light font-size-10 font-weight-600 mt-2">
              {props.predictedUsageText}
            </div>
          ) : (
            <Row className="align-items-center">
              <Col className="col-auto pe-0">
                {props.cost !== null && (
                  <Row className={`${styles.value} mt-1`}>
                    <Col>Rs {Helper.roundTo2(props.cost)}</Col>
                  </Row>
                )}
                <div
                  className={`${
                    props.cost === null ? `${styles.value} mt-1` : styles.units
                  } d-flex align-items-center`}
                >
                  <label>{Helper.roundTo2(props.units)} Units</label>
                  {switchIcon()}
                </div>
              </Col>
              {props.usageDataFrom && (
                <Col className="col-auto ps-2">
                  <Tooltip
                    placement="bottom"
                    trigger={["hover"]}
                    overlay={
                      <div className="text-light font-size-12 font-weight-500">
                        {props.usageDataFrom === EUsageDataFrom.SERVICE_PROVIDER
                          ? `from service provider`
                          : `from smart controller`}
                      </div>
                    }
                  >
                    <div className={` user-select-none`}>
                      <MaterialIcon
                        className="cursor-mouse"
                        icon="info"
                        color="#69768B"
                        size={13}
                      />
                    </div>
                  </Tooltip>
                </Col>
              )}
            </Row>
          )}
        </>
      )}
    </div>
  );
};

export default UsageValue;
