import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useLazyViewDemandControlActionQuery,
  useUpdatePowerConsumerInDemandControlActionMutation,
  useUpdateSpaceClusterInDemandControlActionMutation,
} from "../../../redux/api/usage-guide/usageGuideAPI";
import ViewDemandControlActionResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-demand-control-action-response-dto";
import { EDemandControlActionStatus } from "../../../shared/oversight-core/enums/demand-control-action-status";
import { EPowerMeasureType } from "../../../shared/oversight-core/enums/power-measure-type";
import { IDemandControlAction } from "../../../shared/oversight-core/interfaces/demand-control-action";
import MaterialIcon from "../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import SpinnerModal from "../../../shared/oversight-core/ui-elements/spinner/spinner";
import { formatDate } from "../../../shared/oversight-core/utils/date-utils";
import { powerConvertor } from "../../../shared/oversight-core/utils/power-convertor";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../shared/oversight-core/utils/toast";
import ReductionRequestView from "../components/reduction-request-view/reduction-request-view";
import ViewUserDevicesModal from "../modal/view-user-devices-modal/view-user-devices-modal";

const DemandResponseProgram = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const locationState = location.state;

  const [demandControlActions, setDemandControlActions] =
    useState<ViewDemandControlActionResponseDTO>();
  const [filters, setFilters] = useState({ pageNumber: 0, pageSize: 10 });
  const [totalElements, setTotalElements] = useState(0);
  const [showViewUserDeviceModal, setShowViewUserDeviceModal] = useState(false);
  const [
    viewSpaceClusterInDemandControlAction,
    setViewSpaceClusterInDemandControlAction,
  ] = useState<IDemandControlAction>();
  const [demandControlActionStatus, setDemandControlActionStatus] =
    useState<EDemandControlActionStatus>(
      EDemandControlActionStatus.NOT_STARTED
    );
  const [isUpdateDemandControlAction, setIsUpdateDemandControlAction] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [
    triggerDemandControlAction,
    { isFetching: isFetchingDemandControlAction },
  ] = useLazyViewDemandControlActionQuery();
  const [updatePowerConsumerInDemandControlAction] =
    useUpdatePowerConsumerInDemandControlActionMutation();
  const [updateSpaceClusterInDemandControlAction] =
    useUpdateSpaceClusterInDemandControlActionMutation();

  useEffect(() => {
    if (locationState?.demandControl.demandControlActionId) {
      triggerDemandControlAction({
        demandControlActionId:
          locationState?.demandControl.demandControlActionId,
        pageNumber: filters.pageNumber,
        pageSize: filters.pageSize,
      })
        .unwrap()
        .then((res: ViewDemandControlActionResponseDTO) => {
          setDemandControlActions(res);
          setTotalElements(res.affectedSpaceClusters.totalElements);
          setIsLoading(true);
        })
        .catch(() => {
          showErrorMessage("Sorry, An error occurred");
        });
    }
  }, [
    filters,
    locationState?.demandControl.demandControlActionId,
    triggerDemandControlAction,
    isUpdateDemandControlAction,
  ]);

  useEffect(() => {
    if (demandControlActions && viewSpaceClusterInDemandControlAction) {
      const found = demandControlActions.affectedSpaceClusters.elements.find(
        (dca) => dca.id === viewSpaceClusterInDemandControlAction.id
      );

      setViewSpaceClusterInDemandControlAction(found);
    }
  }, [demandControlActions, viewSpaceClusterInDemandControlAction]);

  useEffect(() => {
    if (
      locationState?.demandControl.demandControlPeriod &&
      locationState?.demandControl.demandControlDate
    ) {
      const fromTime = formatDate(
        new Date(locationState.demandControl.demandControlPeriod.fromDate)
      );

      const toTime = formatDate(
        new Date(locationState.demandControl.demandControlPeriod.toDate)
      );

      const nowTime = formatDate(new Date());

      if (toTime < nowTime) {
        setDemandControlActionStatus(EDemandControlActionStatus.FINAL_STATUS);
      } else if (nowTime > fromTime) {
        setDemandControlActionStatus(EDemandControlActionStatus.IN_PROGRESS);
      } else {
        setDemandControlActionStatus(EDemandControlActionStatus.NOT_STARTED);
      }
    }
  }, [
    locationState.demandControl.demandControlDate,
    locationState.demandControl.demandControlPeriod,
  ]);

  const updatePowerConsumer = async (
    demandControlActionId: string,
    spaceClusterId: string,
    powerConsumerId: string
  ) => {
    setIsLoading(false);
    await updatePowerConsumerInDemandControlAction({
      demandControlActionId,
      spaceClusterId,
      powerConsumerId,
    })
      .unwrap()
      .then(() => {
        setIsUpdateDemandControlAction((ps) => !ps);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateSpaceCluster = (
    demandControlActionId: string,
    spaceClusterId: string
  ) => {
    updateSpaceClusterInDemandControlAction({
      demandControlActionId,
      spaceClusterId,
    })
      .then(() => {
        showSuccessMessage(
          `Space Cluster ${
            viewSpaceClusterInDemandControlAction?.isRemoved
              ? `Enable`
              : `Disable`
          } Successfully`
        );
        setShowViewUserDeviceModal(false);
        setIsUpdateDemandControlAction((ps) => !ps);
        setIsLoading(true);
      })
      .catch(() => {
        showErrorMessage(
          `Space Cluster ${
            viewSpaceClusterInDemandControlAction?.isRemoved
              ? `Enable`
              : `Disable`
          } Unsuccessfully`
        );
      });
  };

  return (
    <div className="position-relative">
      <ReductionRequestView
        onBack={() => navigate(-1)}
        headers={[
          { id: 1, columnName: "Account No" },
          { id: 2, columnName: "Usual Usage" },
          { id: 3, columnName: "Reduced Usage" },
          { id: 4, columnName: "Saving" },
          { id: 5, columnName: "Devices" },
          { id: 6, columnName: "" },
        ]}
        tableData={
          demandControlActions
            ? demandControlActions.affectedSpaceClusters.elements.map((dca) => {
                return {
                  data: [
                    <>{dca.serviceProviderAccount.accountNumber}</>,
                    <>
                      {powerConvertor(
                        dca.usualUsage,
                        EPowerMeasureType.KILO_WATT
                      ).toFixed(2)}
                      kW
                    </>,
                    <>
                      {powerConvertor(
                        dca.reducedUsage,
                        EPowerMeasureType.KILO_WATT
                      ).toFixed(2)}
                      kW
                    </>,
                    <>
                      {powerConvertor(
                        dca.reduction,
                        EPowerMeasureType.KILO_WATT
                      ).toFixed(2)}
                      kW
                    </>,
                    <>{dca.devices}</>,
                    <>
                      <MaterialIcon
                        key={`${dca.id}`}
                        icon="visibility"
                        color="#2F2A89"
                        className={`cursor-pointer`}
                        onClick={() => {
                          setShowViewUserDeviceModal(true);
                          setViewSpaceClusterInDemandControlAction(dca);
                        }}
                        size={15}
                      />
                    </>,
                  ],
                  disable: dca.isRemoved,
                };
              })
            : []
        }
        totalElements={totalElements}
        filters={filters}
        setFilters={setFilters}
        demandControlResponseView={demandControlActions}
        demandControlActionStatus={demandControlActionStatus}
      />
      <ViewUserDevicesModal
        show={showViewUserDeviceModal}
        onClose={() => setShowViewUserDeviceModal(false)}
        viewSpaceClusterInDemandControlAction={
          viewSpaceClusterInDemandControlAction
        }
        demandControlActionId={
          demandControlActions?.demandControlActionId || ""
        }
        demandControlActionStatus={demandControlActionStatus}
        updatePowerConsumer={updatePowerConsumer}
        updateSpaceCluster={updateSpaceCluster}
      />
      <SpinnerModal show={isFetchingDemandControlAction && isLoading} />
    </div>
  );
};

export default DemandResponseProgram;
