import { Col, Row } from "react-bootstrap";
import noLiveData from "../../../oversight-core/assets/images/livenodata.svg";
import noData from "../../../oversight-core/assets/images/nodata.svg";

interface IProps {
  isLiveData?: boolean;
  from?: string;
}

const NoChartDataView = (props: IProps) => {
  const { isLiveData, from } = props;
  return (
    <Row className="content-justify-center">
      <Col className="text-center">
        <Row>
          <Col>
            <img src={isLiveData ? noLiveData : noData} alt="No Data" />
          </Col>
        </Row>
        <Row>
          <Col className="text-dark font-size-24 font-weight-600">
            No {isLiveData && "Live"} Data
          </Col>
        </Row>
        <Row>
          <Col className="text-light font-size-12 font-weight-500">
            {isLiveData
              ? `No power usage detected from ${from}`
              : "Currently, there are no data available for visualization"}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default NoChartDataView;
