import { Col, Container, Row } from "react-bootstrap";
import AppModal from "../app-modal/app-modal";
import AppButton, { ButtonVariant } from "../buttons/app-button/app-button";
import MaterialIcon from "../material-icon/material-icon";
import styles from "./modal-container.module.scss";

export interface ModelContainerProps {
  show: boolean;
  title?: string | JSX.Element;
  size?: "modal-sm" | "modal-lg" | "modal-md" | "modal-xl";
  onCancel?: () => void;
  onClose?: () => void;
  onConfirm?: () => void;
  onDelete?: () => void;
  onUpdate?: () => void;
  confirmButtonText?: string;
  cancelButtonText?: string;
  confirmButtonVariant?: ButtonVariant;
  cancelButtonVariant?: ButtonVariant;
  children?: JSX.Element;
  hideCancel?: boolean;
  hideConfirm?: boolean;
  isSecondButtonVisible?: boolean;
  isLoading?: boolean;
  className?: string;
  isButtonsVisible?: boolean;
  differentHeader?: boolean;
  confirmId?: string;
  disabled?: boolean;
}

const ModalContainer = (props: ModelContainerProps) => {
  const {
    isLoading = false,
    isButtonsVisible = true,
    differentHeader = false,
  } = props;
  return (
    <AppModal show={props.show} size={props.size}>
      <Container fluid className="p-0">
        {differentHeader ? (
          <Row className="justify-content-end">
            <Col className="col-auto">
              <MaterialIcon
                icon="edit"
                className="cursor-pointer"
                onClick={props.onUpdate}
              />
            </Col>
            <Col className="col-auto ps-0">
              <MaterialIcon
                icon="delete"
                className="cursor-pointer"
                color="#CB343D"
                onClick={props.onDelete}
              />
            </Col>
            <Col className="col-auto ps-0">
              <MaterialIcon
                icon="close"
                className="cursor-pointer"
                onClick={props.onClose}
              />
            </Col>
          </Row>
        ) : (
          <Row className="align-items-center justify-content-between">
            <Col xs="10">
              <div
                className={`${styles.title}`}
                style={{
                  fontWeight: 500,
                  color: "#505662",
                }}
              >
                {props.title}
              </div>
            </Col>
            <Col xs="2" sm="auto" className="fw-bold">
              <MaterialIcon
                icon="close"
                className="cursor-pointer"
                onClick={props.onClose}
              />
            </Col>
          </Row>
        )}
        <Row className={`${props.className ? props.className : `mt-4`}`}>
          <Col xs={12}>{props.children}</Col>
        </Row>
        {isButtonsVisible ? (
          <Row className="mt-5">
            <Col>
              <Row>
                {!props.hideConfirm && (
                  <Col xs="auto">
                    <AppButton
                      text={props.confirmButtonText || "Confirm"}
                      variant={props.confirmButtonVariant || "blue"}
                      size="medium"
                      onClick={props.onConfirm}
                      isLoading={isLoading}
                      id={props.confirmId}
                      disabled={props.disabled}
                    />
                  </Col>
                )}
                {!props.hideCancel && (
                  <Col xs="auto" className="p-0">
                    <AppButton
                      text={props.cancelButtonText || "Cancel"}
                      variant={props.cancelButtonVariant || "transparent"}
                      onClick={props.onCancel}
                      size="medium"
                      className={props.cancelButtonVariant ? "px-4" : "px-2"}
                    />
                  </Col>
                )}
              </Row>
            </Col>
            {props.isSecondButtonVisible && (
              <Col className="col-auto">
                <AppButton
                  text={props.confirmButtonText || "Next"}
                  variant={props.confirmButtonVariant || "blue"}
                  onClick={props.onConfirm}
                  size="medium"
                />
              </Col>
            )}
          </Row>
        ) : (
          <></>
        )}
      </Container>
    </AppModal>
  );
};

export default ModalContainer;
