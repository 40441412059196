import { Col, Row } from "react-bootstrap";
import styles from "./total-users.module.scss";
import SpinnerModal from "../../../shared/oversight-core/ui-elements/spinner/spinner";

interface IProps {
  title: string;
  counts: {
    totalUsers: number;
    lecoUsers?: number;
    other?: number;
  };
  isFetching:boolean;
}

const TotalUsers = (props: IProps) => {
  const { counts, isFetching } = props;
  return (
    <div className="container-white py-4 h-100">
      {isFetching ?(
        <SpinnerModal show ={isFetching} withOverlay={false} size="md" />
      ):(
        <>
          <Row>
            <Col className={styles.subTitle}>{props.title}</Col>
          </Row>
          <Row className="align-items-center">
            <Col>
              <div className={styles.title}>{counts.totalUsers}</div>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default TotalUsers;
