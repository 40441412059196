import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { EDeviceTypes } from "../../../shared/oversight-core/enums/device-types";
import { IDeviceStatisticsByTypeView } from "../../../shared/oversight-core/interfaces/entities/device-statistics-by-type-view";
import MaterialIcon from "../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import SpinnerModal from "../../../shared/oversight-core/ui-elements/spinner/spinner";
import findIcon from "../../../shared/oversight-core/utils/findIcon";
import styles from "./high-power-devices.module.scss";

interface IProps {
  consumers: IDeviceStatisticsByTypeView[];
  title: string;
  isFetching: boolean;
}

const HighPowerDevices = (props: IProps) => {
  const [data, setData] = useState<IDeviceProps[]>([]);

  useEffect(() => {
    if (props.consumers.length > 0) {
      const sorted = props.consumers
        .slice()
        .sort((a, b) => b.deviceCount - a.deviceCount);
      const _data = sorted.map((c) => {
        return {
          name: c.type,
          min: c.minAveragePowerUsage,
          max: c.maxAveragePowerUsage,
          deviceCount: c.deviceCount,
          type: c.type,
          percentage: c.countAsPercentage,
        };
      });

      setData(_data);
    } else {
      setData([]);
    }
  }, [props.consumers]);

  return (
    <div className={`${styles.container} container-white`}>
      {props.isFetching ? (
        <SpinnerModal show={props.isFetching} withOverlay={false} size="md" />
      ) : (
        <>
          {data.length > 0 ? (
            <>
              <Row className="align-items-center">
                <Col className="mb-md-3">
                  <label className={styles.title}>
                    {props.consumers.length === 0
                      ? "No Devices"
                      : `${props.title}`}
                  </label>
                </Col>
                <Col className="col-12 col-md-3 text-md-end ms-0 mt-md-1">
                  <label
                    className={styles.subTitle}
                  >{`Min - Max (Count)`}</label>
                </Col>
              </Row>
              <Row className={styles.content}>
                {data.map((c, index) => (
                  <Col key={index} xs={12} className={styles.contentRowLeft}>
                    <Device {...c} />
                  </Col>
                ))}
              </Row>
            </>
          ) : (
            <div className="container-dash">
              <div className="text-center text-light font-size-12">
                No Highest Energy Devices Available
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export interface IDeviceProps {
  name?: string;
  id?: string;
  min: number;
  max: number;
  deviceCount: number;
  type: EDeviceTypes;
  percentage: number;
}

const Device = (props: IDeviceProps) => {
  return (
    <Row className={`${styles.deviceContainer} align-items-center`}>
      <Col className={`${styles.left}`}>
        <Row className="align-items-center">
          <Col className="col-auto pe-0">
            <div className={`${props.type} ${styles.icon}`}>
              <MaterialIcon size={16} icon={findIcon(props.type)} />
            </div>
          </Col>
          <Col className="ps-2">
            <label>{props.type}</label>
          </Col>
        </Row>
      </Col>
      <Col className={`${styles.right} text-end`}>
        <Row className="align-items-center">
          <Col>
            <div className={styles.progress}>
              <div
                className={props.type}
                style={{ width: `${props.percentage}%` }}
              ></div>
            </div>
          </Col>
          <Col className="ps-0 col-6">
            <label>{`${props.min}W - ${props.max}W (${props.deviceCount})`}</label>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default HighPowerDevices;
