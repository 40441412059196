import Tooltip from "rc-tooltip";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  IConsumerListView,
  SearchFilters,
  useChangeConsumerImportanceMutation,
  useLazyGetAllTariffsQuery,
  useLazyGetPaginatedConsumersQuery,
} from "../../redux/api/consumer/consumerAPI";
import { EProviders } from "../../shared/oversight-core/enums/providers";
import { SearchField } from "../../shared/oversight-core/enums/search-field";
import { AppRoute } from "../../shared/oversight-core/interfaces/app-routes";
import AppSelect, {
  Option,
} from "../../shared/oversight-core/ui-elements/app-select/app-select";
import DataTable from "../../shared/oversight-core/ui-elements/data-table/data-table";
import MaterialIcon from "../../shared/oversight-core/ui-elements/material-icon/material-icon";
import SpinnerModal from "../../shared/oversight-core/ui-elements/spinner/spinner";
import { showErrorMessage } from "../../shared/oversight-core/utils/toast";
import UserImage from "./assets/Image.png";
import Pagination from "./pagination/pagination";
import Search from "./search/search";
import styles from "./users.module.scss";

const initialFilters: SearchFilters = {
  searchField: SearchField.NAME,
  searchValue: "",
  serviceProvider: EProviders.ALL,
  tariffCategory: "",
  important: false,
  pageNumber: 0,
  pageSize: 10,
  sortBy: "first_name",
  ascending: true,
};

const Users = () => {
  const [searchInput, setSearchInput] = useState("");
  const [filters, setFilters] = useState<SearchFilters>({ ...initialFilters });
  const [tariffs, setTariffs] = useState<Option[]>([]);
  const [consumers, setConsumers] = useState<IConsumerListView[]>([]);
  const [totalElements, setTotalElements] = useState(0);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const searchOptions = [
    { value: SearchField.ACCOUNT_NUMBER, label: "By Account Number" },
    { value: SearchField.EMAIL, label: "By Email" },
    { value: SearchField.MOBILE_NUMBER, label: "By Phone Number" },
    { value: SearchField.NAME, label: "By Name" },
  ];

  const [triggerGetTariffs] = useLazyGetAllTariffsQuery();
  const [triggerGetPaginatedConsumers] = useLazyGetPaginatedConsumersQuery();
  const [triggerChangeConsumerImportanceMutation] =
    useChangeConsumerImportanceMutation();

  useEffect(() => {
    setLoading(true);
    setTariffs([]);
    triggerGetTariffs()
      .then((response) => {
        if (response.data) {
          const tempTariffs: Option[] = response.data.tariffs.map((tariff) => {
            return {
              label: `${tariff.code} - ${tariff.description}`,
              value: tariff.code,
            };
          });
          setTariffs(tempTariffs);
        }
      })
      .catch(() => {
        showErrorMessage("Sorry, An error occurred");
      })
      .finally(() => setLoading(false));
  }, [triggerGetTariffs]);

  useEffect(() => {
    let _filter: SearchFilters;
    if (filters.searchValue.trim()) {
      _filter = filters;
    } else {
      _filter = { ...filters, searchField: SearchField.ALL };
    }
    setLoading(true);
    setConsumers([]);
    triggerGetPaginatedConsumers(_filter)
      .then((res) => {
        const c = res.data?.genericPage.elements;
        setTotalElements(res.data?.genericPage.totalElements || 0);
        if (c) setConsumers(c);
      })
      .catch(() => {
        showErrorMessage("Sorry, An error occurred");
      })
      .finally(() => setLoading(false));
  }, [filters, triggerGetPaginatedConsumers]);

  const changeImportant = (user: IConsumerListView) => {
    setLoading(true);
    triggerChangeConsumerImportanceMutation({
      customerId: user.identity,
      important: !user.important,
    })
      .then(() => {
        setConsumers((ps) => {
          const updated = ps.map((u) => {
            if (u.identity === user.identity) {
              return { ...u, important: !u.important };
            } else {
              return u;
            }
          });
          return [...updated];
        });
      })
      .catch(() => {
        showErrorMessage("Sorry, An error occurred");
      })
      .finally(() => setLoading(false));
  };

  const getPlaceholder = () => {
    switch (filters.searchField) {
      case SearchField.NAME:
        return "Search User Name";
      case SearchField.MOBILE_NUMBER:
        return "Search User Mobile Number";
      case SearchField.EMAIL:
        return "Search User Email";
      default:
        return "Search User Account Number";
    }
  };

  return (
    <div
      className={`${styles.usersListOverflow} container-white position-relative py-4`}
    >
      <Row className="align-items-center">
        <Col className="col-12 col-xl-6">
          <Row className="align-items-center">
            <Col className="col-4 col-lg-4 col-xl-5 pe-0">
              <AppSelect
                selectedValue={searchOptions.filter(
                  (a) => a.value === filters.searchField
                )}
                options={searchOptions}
                onChangeOption={(selectedOption) => {
                  setFilters((ps) => ({
                    ...ps,
                    searchField: selectedOption.value as SearchField,
                    pageNumber: 0,
                  }));
                }}
                borderRadiusStyle={"Right"}
                className="font-size-12"
              />
            </Col>
            <Col className="col-8 col-lg-6 col-xl-6 ps-0">
              <Search
                placeholder={getPlaceholder()}
                onSearch={() => {
                  setFilters((ps) => ({
                    ...ps,
                    searchValue: searchInput,
                    pageNumber: 0,
                  }));
                }}
                onChange={(input) => setSearchInput(input)}
                value={searchInput}
              />
            </Col>
            <Col className="col-1 d-none d-lg-block ps-4 ps-xl-2">
              <MaterialIcon
                icon="star"
                color="#2F2A89"
                fill={filters.important}
                className="cursor-pointer"
                onClick={() => {
                  setFilters((ps) => ({
                    ...ps,
                    important: !ps.important,
                    pageNumber: 0,
                  }));
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col className="col-12 col-lg-10 col-xl-4 mt-3 mt-xl-0">
          <Row className="align-items-center">
            <Col className="col-auto d-block d-lg-none">
              <MaterialIcon
                icon="star"
                color="#2F2A89"
                fill={filters.important}
                className="cursor-pointer"
                onClick={() => {
                  setFilters((ps) => ({
                    ...ps,
                    important: !ps.important,
                    pageNumber: 0,
                  }));
                }}
              />
            </Col>
            <Col>
              <AppSelect
                placeholder="Tariff Type"
                options={[{ label: "All", value: "" }, ...tariffs]}
                className="font-size-12"
                selectedValue={tariffs.filter(
                  (a) => a.value === filters.tariffCategory
                )}
                onChangeOption={(o) => {
                  setFilters((ps) => ({
                    ...ps,
                    tariffCategory: o.value,
                    pageNumber: 0,
                  }));
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col className="col-12 col-lg-2 col-xl-auto mt-3 mt-xl-0 text-center">
          <p
            className="mb-0 text-primary font-size-12 font-weight-500 cursor-pointer"
            onClick={() => {
              setFilters({ ...initialFilters });
              setSearchInput("");
            }}
          >
            Reset Filters
          </p>
        </Col>
      </Row>
      {consumers.length > 0 ? (
        <>
          <DataTable
            headers={[
              { id: 1, columnName: "" },
              { id: 2, columnName: "Name" },
              { id: 3, columnName: "Account\u00A0Numbers" },
              { id: 4, columnName: "Email" },
              { id: 5, columnName: "Phone Number" },
              { id: 6, columnName: "" },
            ]}
            data={consumers.map((user) => {
              return {
                data: [
                  <>
                    <div
                      onClick={() => {
                        if (user.important !== null) changeImportant(user);
                      }}
                    >
                      <MaterialIcon
                        icon="star"
                        color={user.important === null ? "#ccc" : `#2F2A89`}
                        fill={user.important}
                        className={
                          user.important !== null ? `cursor-pointer` : ""
                        }
                      />
                    </div>
                  </>,
                  <>
                    {user?.personName.firstName && user?.personName.lastName
                      ? `${user.personName.firstName} ${user.personName.lastName}`
                      : "-"}
                  </>,
                  <>
                    {user?.billingAccountNumbers &&
                    user.billingAccountNumbers.length > 0 ? (
                      <Tooltip
                        placement="left"
                        trigger={["hover"]}
                        overlay={
                          <div>
                            {user.billingAccountNumbers.map((c, index) => (
                              <div key={index}>{c}</div>
                            ))}
                          </div>
                        }
                      >
                        <div
                          className="text-truncate"
                          style={{ maxWidth: "150px" }}
                        >
                          {user.billingAccountNumbers.join("/")}
                        </div>
                      </Tooltip>
                    ) : (
                      "-"
                    )}
                  </>,
                  <>{user?.email || "-"}</>,
                  <>{user?.contactNumber || "-"}</>,
                  <>
                    <button
                      className={styles.button}
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(AppRoute.ADMIN_USERS + `/${user.identity}`, {
                          state: {
                            id: user.identity,
                            name:
                              user.personName.firstName +
                              " " +
                              user.personName.lastName,
                            username: user.username,
                            email: user.email,
                            contact: user.contactNumber,
                            avatar: UserImage,
                            serviceProvider: user.serviceProviderShortName,
                          },
                        });
                      }}
                    >
                      View
                    </button>
                  </>,
                ],
                serviceProvider: user.serviceProviderShortName,
              };
            })}
          />
          <Pagination
            itemsPerPage={filters.pageSize}
            length={totalElements}
            currentPage={filters.pageNumber + 1}
            updateCurrentPage={(pn) => {
              setFilters((ps) => ({ ...ps, pageNumber: pn - 1 }));
            }}
          />
        </>
      ) : (
        <>
          {!loading && (
            <div className="container-dash mt-4">
              <Col className="text-center text-light font-size-12">
                There are no users available for the selected filters.
              </Col>
            </div>
          )}
        </>
      )}
      <SpinnerModal show={loading} />
    </div>
  );
};

export default Users;
