import Tooltip from "rc-tooltip";
import { Col, Row } from "react-bootstrap";
import MaterialIcon from "../material-icon/material-icon";
import SpinnerModal from "../spinner/spinner";
import styles from "./stats-view.module.scss";

interface IProps {
  variant: "bg-icon-2" | "bg-icon-3" | "bg-icon-4" | "bg-icon-5" | "bg-icon-6";
  labelValue: string;
  title: string;
  fontStyle?: "normal" | "bold" | "small";
  fontSize?: number;
  isFetching?: boolean;
  infoText?: string;
}

const StatsViewTypeTwo = (props: IProps) => {
  const {
    variant,
    labelValue,
    title,
    fontStyle = "bold",
    isFetching,
    infoText,
  } = props;
  return (
    <div className="text-center">
      <Row>
        <Col>
          <div
            className={`${styles.label} ${variant} ${styles[fontStyle]} px-5 px-md-2`}
            style={{ fontSize: props.fontSize }}
          >
            {isFetching ? (
              <SpinnerModal show={isFetching} withOverlay={false} size="sm" />
            ) : (
              <>{labelValue}</>
            )}
          </div>
        </Col>
      </Row>
      <Row className="mt-1 justify-content-center">
        <Col className={`${styles.title} ${infoText && `pe-1`} col-auto`}>
          {title}
        </Col>
        {infoText && (
          <Col className="col-auto ps-0">
            <Tooltip
              placement="top"
              trigger={["hover"]}
              overlayInnerStyle={{
                borderColor: "#F8FA9C",
                backgroundColor: "#F8F9EB",
              }}
              overlay={
                <Row>
                  <Col className="text-light font-weight-400 font-size-10">
                    {infoText}
                  </Col>
                </Row>
              }
            >
              <MaterialIcon
                className="user-select-none"
                icon="info"
                color="#69768B"
                size={12}
              />
            </Tooltip>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default StatsViewTypeTwo;
