import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { IDeviceTypePowerConsumption } from "../../../shared/oversight-core/dtos/response-dtos/get-device-types-by-device-power-consumption-response-dto";
import { EDeviceTypes } from "../../../shared/oversight-core/enums/device-types";
import MaterialIcon from "../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import SpinnerModal from "../../../shared/oversight-core/ui-elements/spinner/spinner";
import findIcon from "../../../shared/oversight-core/utils/findIcon";
import styles from "./high-energy-consuming-devices.module.scss";

interface IProps {
  highestPowerConsumingDevices: IDeviceTypePowerConsumption[];
  title: string;
  isFetching: boolean;
}

const HighEnergyConsumingDevices = (props: IProps) => {
  const { highestPowerConsumingDevices, title, isFetching } = props;
  const [data, setData] = useState<IDeviceTypePowerConsumption[]>([]);

  useEffect(() => {
    if (highestPowerConsumingDevices.length > 0) {
      const _data = highestPowerConsumingDevices.map((c) => {
        return {
          type: c.type,
          energy: +c.energy.toFixed(2),
          percentage: c.percentage,
        };
      });

      setData(_data);
    } else {
      setData([]);
    }
  }, [highestPowerConsumingDevices]);

  return (
    <div className={`${styles.container} container-white`}>
      {isFetching ? (
        <SpinnerModal show={isFetching} withOverlay={false} size="md" />
      ) : (
        <>
          {data.length > 0 ? (
            <>
              <Row className="align-items-center">
                <Col className="font-size-12 font-weight-500 text-light">
                  {highestPowerConsumingDevices.length === 0
                    ? "No Devices"
                    : `${title}`}
                </Col>
              </Row>
              <Row>
                {data.map((c, index) => (
                  <Col key={index} lg={6} className={styles.contentRowLeft}>
                    <Device {...c} />
                  </Col>
                ))}
              </Row>
            </>
          ) : (
            <div className="container-dash">
              <div className="text-center text-light font-size-12">
                No Highest Energy Consuming Device Types Available
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

const Device = (props: IDeviceTypePowerConsumption) => {
  const { type, energy, percentage } = props;
  return (
    <Row className="align-items-center mt-4">
      <Col>
        <Row className="align-items-center">
          <Col className="col-auto pe-0">
            <div className={`${type} ${styles.icon}`}>
              <MaterialIcon size={16} icon={findIcon(type as EDeviceTypes)} />
            </div>
          </Col>
          <Col className="ps-2 font-size-12 font-weight-500 text-dark">
            <div>{type}</div>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row className="align-items-center">
          <Col>
            <div className={styles.progress}>
              <div className={type} style={{ width: `${percentage}%` }}></div>
            </div>
          </Col>
          <Col className="ps-0 col-auto font-size-12 font-weight-500 text-light">
            <label>{energy} Units</label>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default HighEnergyConsumingDevices;
