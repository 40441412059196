import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { EConnectionStatus } from "../../../shared/oversight-core/enums/connection-status";
import { EDeviceStatus } from "../../../shared/oversight-core/enums/device-status";
import { ESmartPlugType } from "../../../shared/oversight-core/enums/smart-plug-type";
import { ISpaceView } from "../../../shared/oversight-core/interfaces/entities/space";
import ActiveInactiveIndicator from "../../../shared/oversight-core/ui-elements/active-inactive-indicator/active-inactive-indicator";
import LastKnownStatus from "../../../shared/oversight-core/ui-elements/last-known-status/last-known-status";
import MaterialIcon from "../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import styles from "./space-view-container.module.scss";

interface IProps {
  mainSpace: ISpaceView;
  collapsed: boolean;
  onSpaceSelect: (space: ISpaceView) => void;
  level: number;
}

const SpaceViewContainer = (props: IProps) => {
  const [collapsed, setCollapsed] = useState(props.collapsed);

  useEffect(() => {
    setCollapsed(props.collapsed);
  }, [props.collapsed]);

  return (
    <Container fluid className="px-0">
      <div
        className={`d-flex justify-content-between align-items-center ${styles.space} w-100 py-2 cursor-pointer`}
        style={
          props.level === 1
            ? {
                borderTop: "solid 1px #eaeaea",
                paddingLeft: `${20 * props.level}px`,
              }
            : { paddingLeft: `${20 * props.level}px` }
        }
        onClick={() => props.onSpaceSelect(props.mainSpace)}
      >
        <div>{props.mainSpace.name}</div>
        <MaterialIcon
          icon="expand_more"
          className="cursor-pointer d-arrow"
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            e.stopPropagation();
            setCollapsed(!collapsed);
          }}
          style={
            collapsed
              ? { transform: "rotate(0deg)" }
              : { transform: "rotate(180deg)" }
          }
        />
      </div>
      <div className={`${collapsed ? "d-none" : ""}`}>
        <div style={{ paddingLeft: `${20 * props.level}px` }}>
          {props.mainSpace.powerConsumers.length > 0 ||
          props.mainSpace.smartDevices.length > 0 ? (
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Device Name</th>
                  <th>Type</th>
                  <th>Wattage</th>
                  <th>Controller Name</th>
                  <th>Controller Type</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {props.mainSpace.powerConsumers.map((powerConsumer) => {
                  const possibleSmartDevices =
                    props.mainSpace.smartDevices.filter(
                      (smartDevice) =>
                        smartDevice.linkedPowerConsumer?.id === powerConsumer.id
                    );

                  const possibleSmartDeviceConnectionState =
                    possibleSmartDevices.every(
                      (sd) =>
                        sd.deviceConnectionState ===
                        EConnectionStatus.DISCONNECTED
                    );

                  return (
                    <tr key={powerConsumer.id}>
                      <td>{powerConsumer.name}</td>
                      <td>{powerConsumer.deviceType}</td>
                      <td>{powerConsumer.powerUsageInWatt} W</td>
                      <td>
                        {possibleSmartDevices.length > 0
                          ? possibleSmartDevices.map((d, index) => (
                              <div
                                key={index}
                                className="d-flex align-items-center"
                              >
                                <div className="">{d.name}</div>
                                <div className="ms-1">
                                  <ActiveInactiveIndicator
                                    isActive={
                                      d.deviceConnectionState ===
                                      EConnectionStatus.CONNECTED
                                    }
                                  />
                                </div>
                              </div>
                            ))
                          : "-"}
                      </td>
                      <td>
                        {possibleSmartDevices.length > 0
                          ? possibleSmartDevices.map((d, index) => (
                              <div key={index}>{d.smartDeviceType}</div>
                            ))
                          : "-"}
                      </td>
                      <td>
                        <Row className="align-items-center">
                          <Col
                            xs={1}
                            className={
                              powerConsumer.devicePowerState ===
                              EDeviceStatus.ON
                                ? styles[`device-on`]
                                : ``
                            }
                          >
                            {powerConsumer.devicePowerState !== null
                              ? powerConsumer.devicePowerState ===
                                EDeviceStatus.ON
                                ? "On"
                                : "Off"
                              : "-"}
                          </Col>
                          {possibleSmartDeviceConnectionState &&
                            possibleSmartDevices.length > 0 && (
                              <Col className="col-auto ps-2">
                                <LastKnownStatus onlyIcon={true} />
                              </Col>
                            )}
                        </Row>
                      </td>
                    </tr>
                  );
                })}
                {props.mainSpace.smartDevices
                  .filter((smartDevice) => !smartDevice.linkedPowerConsumer)
                  .map((smartDevice) => {
                    return (
                      <tr key={smartDevice.id}>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="">{smartDevice.name}</div>
                            <div className="ms-1">
                              <ActiveInactiveIndicator
                                isActive={
                                  smartDevice.deviceConnectionState ===
                                  EConnectionStatus.CONNECTED
                                }
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          {smartDevice.smartPlugType === ESmartPlugType.GENERAL
                            ? `GENERAL`
                            : smartDevice.smartDeviceType}
                        </td>
                        <td>
                          <Row className="align-items-center">
                            <Col
                              xs={1}
                              className={
                                smartDevice.dynamicState.powerState ===
                                EDeviceStatus.ON
                                  ? styles[`device-on`]
                                  : ``
                              }
                            >
                              {smartDevice.dynamicState.powerState ===
                              EDeviceStatus.ON
                                ? "On"
                                : "Off"}
                            </Col>
                            {smartDevice.deviceConnectionState ===
                              EConnectionStatus.DISCONNECTED && (
                              <Col className="col-auto ps-2">
                                <LastKnownStatus onlyIcon={true} />
                              </Col>
                            )}
                          </Row>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          ) : (
            <div></div>
          )}
        </div>
        {props.mainSpace.childSpaces &&
          props.mainSpace.childSpaces.length > 0 && (
            <div>
              {props.mainSpace.childSpaces.map((ss, index) => (
                <SpaceViewContainer
                  key={index}
                  mainSpace={{
                    ...ss,
                  }}
                  level={props.level + 1}
                  collapsed={true}
                  onSpaceSelect={props.onSpaceSelect}
                />
              ))}
            </div>
          )}
      </div>
    </Container>
  );
};

export default SpaceViewContainer;
