import { toast } from "react-toastify";

export const showSuccessMessage = (message: string, id?: string) => {
  toast.success(message, {
    position: toast.POSITION.TOP_RIGHT,
    className: "toast-message",
    toastId: id,
  });
};

export const showErrorMessage = (errorMessage: string, id?: string) => {
  toast.error(errorMessage, {
    position: toast.POSITION.TOP_RIGHT,
    className: "toast-message",
    toastId: id,
  });
};

export const showWarningMessage = (message: string, id?: string) => {
  toast.warning(message, {
    position: toast.POSITION.TOP_RIGHT,
    className: "toast-message",
    toastId: id,
  });
};
