import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AppButton from "../../ui-elements/buttons/app-button/app-button";
import CodeTypingImg from "./assets/code-typing.png";
import styles from "./coming-soon.module.scss";

const ComingSoon = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <div className="d-flex align-items-center justify-content-center vh-90">
        <div className="text-center">
          <div>
            <img src={CodeTypingImg} className="img-fluid" alt="code typing" />
          </div>
          <div className={[styles.comingSoonHeading, "display-6"].join(" ")}>
            COMING SOON
          </div>
          <p className={[styles.comingSoonParagraph, " fs-6"].join(" ")}>
            This feature is currently under development. We can&apos;t wait to
            share it with you soon!
          </p>
          <div className="d-none justify-content-center gap-2">
            <div>
              <AppButton
                text="arrow"
                variant="blue"
                onClick={() => {
                  navigate(-1);
                }}
                iconOnly
                iconName="arrow_left"
              />
            </div>
            <div>
              <AppButton
                text="Bring Me To Home Page"
                variant="blue"
                onClick={() => {
                  navigate(-1);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ComingSoon;
