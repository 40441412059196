import { Col, Row } from "react-bootstrap";
import { EUsageDataFrom } from "../../../shared/oversight-core/enums/usage-data-from";
import IMonthlyEnergySummaryView from "../../../shared/oversight-core/interfaces/monthly-energy-summary-view";
import DataTable from "../../../shared/oversight-core/ui-elements/data-table/data-table";
import ModalContainer, {
  ModelContainerProps,
} from "../../../shared/oversight-core/ui-elements/modal-container/modal-container";
import { capitalizeFirstLetter } from "../../../shared/oversight-core/utils/capitalize-first-letter";

interface IProps extends ModelContainerProps {
  show: boolean;
  monthlyEnergySummeryView: IMonthlyEnergySummaryView[];
}

const UsageInfoModal = (props: IProps) => {
  const { show, ...rest } = props;

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  return (
    <ModalContainer
      title="Actual Usage"
      {...rest}
      show={show}
      size="modal-md"
      isButtonsVisible={false}
    >
      <>
        <Row>
          <Col className="text-light font-size-14 font-weight-400">
            We typically rely on meter readings for actual usage data. If meter
            readings aren&apos;t available, we&apos;ll use smart controller
            data, which may be less accurate, to estimate actual usage
          </Col>
        </Row>
        <Row>
          <Col>
            <DataTable
              headers={[
                { id: 2, columnName: "Month/Year" },
                { id: 3, columnName: "Actual Usage Based On" },
              ]}
              data={props.monthlyEnergySummeryView
                .slice(0, currentMonth)
                .map((item) => {
                  return {
                    data: [
                      <>
                        {capitalizeFirstLetter(item.monthName)}/ {currentYear}
                      </>,
                      <>
                        {item.energySummaryView.consumedUsageDataFrom ===
                        EUsageDataFrom.SERVICE_PROVIDER
                          ? `Meter Readings`
                          : `Smart Controller Data`}
                      </>,
                    ],
                  };
                })}
            ></DataTable>
          </Col>
        </Row>
      </>
    </ModalContainer>
  );
};

export default UsageInfoModal;
