import AdminListAllDevicesRequestDTO from "../../../shared/oversight-core/dtos/request-dtos/admin-list-all-devices-request-dto";
import AdminListAllSmartControllersRequestDTO from "../../../shared/oversight-core/dtos/request-dtos/admin-list-all-smart-controllers-request-dto";
import FeedbackRequestDTO from "../../../shared/oversight-core/dtos/request-dtos/feedback-request.-dto";
import AdminListAllDevicesResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/admin-list-all-devices-response-dto";
import AdminListAllSmartControllersResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/admin-list-all-smart-controllers-response-dto";
import AdminViewSpaceClusterConsumptionByYearAndMonthResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/admin-view-space-cluster-consumption-by-year-and-month-response-dto";
import AdminViewSpaceClusterEnergyByYearResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/admin-view-space-cluster-energy-by-year-response-dto";
import {
  SpaceClustersResponseDTO,
  ViewLastActualConsumptionByIdResponseDTO,
  ViewScheduledPowerConsumersSortedByEnergyResponseDTO,
  ViewSpaceClusterEnergyWithComparisonResponseDTO,
} from "../../../shared/oversight-core/dtos/response-dtos/dashboard-api-dto";
import ViewConsumedSpaceClusterByYearResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-consumed-space-cluster-by-year-response-dto";
import ViewConsumerSubSpaceClusterByIdentityResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-consumer-space-cluster-by-identity";
import ViewEnergyGenerationResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-energy-generation-response-dto";
import { EConsumptionViewTypes } from "../../../shared/oversight-core/enums/consumption-view-types";
import { EEnergyViewType } from "../../../shared/oversight-core/enums/energy-viewType";
import { EProviders } from "../../../shared/oversight-core/enums/providers";
import { EScheduleViewType } from "../../../shared/oversight-core/enums/schedule-view-type";
import { SearchField } from "../../../shared/oversight-core/enums/search-field";
import { ESortOrder } from "../../../shared/oversight-core/enums/sort-order";
import { RootSpace } from "../../../shared/oversight-core/interfaces/root-space";
import { IServiceProviderAccountView } from "../../../shared/oversight-core/interfaces/service-provider-account-view";
import { ISpaceClusterShallowHierarchyView } from "../../../shared/oversight-core/interfaces/space-hierarchy";
import { transformErrorResponse } from "../../../shared/oversight-core/utils/transformErrorResponse";
import { rootApi } from "../apiManager";

export interface SearchFilters {
  searchField: SearchField;
  searchValue: string;
  serviceProvider: EProviders;
  tariffCategory: string;
  important: boolean;
  pageNumber: number;
  pageSize: number;
  sortBy: string;
  ascending: boolean;
}

export interface TariffView {
  id: string;
  code: string;
  description: string;
}

export interface ListAllConsumersResponseDTO {
  genericPage: IGenericPageConsumerListView;
}

export interface IGenericPageConsumerListView {
  totalPages: number;
  currentPage: number;
  totalElements: number;
  elements: IConsumerListView[];
}

export interface IConsumerListView {
  identity: string;
  personName: IPersonName;
  billingAccountNumbers: string[];
  username: string;
  email: string;
  contactNumber: string;
  serviceProviderShortName: string;
  important: boolean;
  isActive: boolean;
  spaceClusters: ISpaceClusterView[];
}

export interface ISpaceClusterView {
  id: string;
  label: string;
  serviceProviderAccount: IServiceProviderAccountView;
  rootSpace: Space;
}

interface Space {
  id: string;
  name: string;
  parentSpaceId: string;
  powerConsumers: PowerConsumers;
  childSpaces: Space[];
}

interface PowerConsumers {
  id: string;
  name: string;
  deviceType: string;
  deviceCategory: string;
  averageEnergyConsumptionInWatt: number;
}

export interface AverageEnergyConsumption {
  averageEnergyConsumptionInWatt: number;
}

export interface Identity {
  id: string;
}

export interface ContactNumber {
  value: string;
}

export interface PowerGenerator {
  identity: Identity;
}

export interface Tariff {
  identity: Identity;
  code: string;
  description: string;
}

export interface IPersonName {
  firstName: string;
  middleName: string;
  lastName: string;
}

export interface getConsumerSpaceClusterViewResponseDTO {
  spaceCluster: SpaceCluster;
}

export interface SpaceCluster {
  id: string;
  label: string;
  tariffCode: string;
  accountNumber: string;
  rootSpace: RootSpace;
}

const removeNullUndefined = (obj: SearchFilters) =>
  Object.entries(obj)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .filter(([_, v]) => {
      return v || v === 0;
    })
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});

export const adminDashboardAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getPaginatedConsumers: builder.query<
      ListAllConsumersResponseDTO,
      SearchFilters
    >({
      transformErrorResponse,
      query(params) {
        const removed = removeNullUndefined(params);
        return {
          url: `admin/customer/list`,
          method: "GET",
          params: removed,
        };
      },
    }),
    getConsumerSpaceClusterView: builder.query<
      getConsumerSpaceClusterViewResponseDTO,
      string
    >({
      transformErrorResponse,
      query(clusterId) {
        return {
          url: `admin/consumer/space-cluster/${clusterId}/view`,
          method: "GET",
        };
      },
    }),
    getAllTariffs: builder.query<{ tariffs: TariffView[] }, void>({
      transformErrorResponse,
      query() {
        return {
          url: `admin/tariff/list`,
          method: "GET",
        };
      },
    }),
    changeConsumerImportance: builder.mutation<
      void,
      { customerId: string; important: boolean }
    >({
      transformErrorResponse,
      query({ customerId, important }) {
        return {
          url: `admin/customer/${customerId}/importance/update`,
          method: "PATCH",
          body: {
            important,
          },
        };
      },
    }),
    getAllSpaceClusterOfConsumer: builder.query<
      SpaceClustersResponseDTO,
      string
    >({
      transformErrorResponse,
      query(consumerId) {
        return {
          url: `admin/consumer/${consumerId}/space-cluster/with-generators/list`,
          method: "GET",
        };
      },
    }),
    getSpaceClusterEnergyWithComparison: builder.query<
      ViewSpaceClusterEnergyWithComparisonResponseDTO,
      { spaceClusterId: string; zoneOffset: string; consumerId: string }
    >({
      transformErrorResponse,
      query({ spaceClusterId, zoneOffset, consumerId }) {
        return {
          url: `admin/space-cluster/${spaceClusterId}/consumption/estimate/view`,
          method: "GET",
          params: { zoneOffset, consumerId },
        };
      },
    }),
    getActualUsageOfConsumer: builder.query<
      ViewLastActualConsumptionByIdResponseDTO,
      { spaceClusterId: string }
    >({
      transformErrorResponse,
      query({ spaceClusterId }) {
        return {
          url: `admin/space-cluster/${spaceClusterId}/consumption/actual/last/view`,
          method: "GET",
        };
      },
    }),
    viewPowerConsumersWithHighConsumptionOfConsumer: builder.query<
      ViewScheduledPowerConsumersSortedByEnergyResponseDTO,
      {
        clusterId: string;
        sortingOrder: ESortOrder;
        limit: number;
      }
    >({
      transformErrorResponse,
      query({ clusterId, sortingOrder, limit }) {
        return {
          url: `admin/space-cluster/${clusterId}/power-consumer-usage-schedule/sort-by-energy/view`,
          method: "GET",
          params: { sortingOrder, limit },
        };
      },
    }),
    viewPowerGenerationDataOfConsumer: builder.query<
      ViewEnergyGenerationResponseDTO,
      {
        consumerId: string;
        powerGeneratorId: string;
        year: number;
      }
    >({
      transformErrorResponse,
      query({ consumerId, powerGeneratorId, year }) {
        return {
          url: `admin/consumer/${consumerId}/power-generator/${powerGeneratorId}/energy-generation/view`,
          method: "GET",
          params: { year },
        };
      },
    }),
    ViewActualConsumptionByYearOfConsumer: builder.query<
      ViewConsumedSpaceClusterByYearResponseDTO,
      {
        spaceClusterId: string;
        viewEnergyAs: EEnergyViewType;

        year: number;
      }
    >({
      transformErrorResponse,
      query({ spaceClusterId, viewEnergyAs, year }) {
        return {
          url: `admin/space-cluster/${spaceClusterId}/consumed-energy/by-year/view`,
          method: "GET",
          params: { year, viewEnergyAs },
        };
      },
    }),
    ViewConsumerSpaceClusterByIdentity: builder.query<
      ViewConsumerSubSpaceClusterByIdentityResponseDTO,
      { spaceClusterId: string; subRootSpaceId: string }
    >({
      transformErrorResponse,
      query({ spaceClusterId, subRootSpaceId }) {
        return {
          url: `admin/consumer/space-cluster/${spaceClusterId}/sub-root/${subRootSpaceId}/view`,
          method: "GET",
        };
      },
    }),
    AdminListSpaceClusterHierarchyByIdentity: builder.query<
      ISpaceClusterShallowHierarchyView,
      { spaceClusterId: string; spaceId: string }
    >({
      transformErrorResponse,
      transformResponse({
        spaceCluster,
      }: {
        spaceCluster: ISpaceClusterShallowHierarchyView;
      }) {
        const hierarchy = [...spaceCluster.ancestorSpaces].sort((a, b) => {
          if (a.parentSpaceId === null) {
            return -1;
          } else if (b.parentSpaceId === null) {
            return 1;
          } else {
            return a.id === b.parentSpaceId ? -1 : 1;
          }
        });

        return { ...spaceCluster, ancestorSpaces: hierarchy };
      },
      query(data) {
        return {
          url: `admin/space-cluster/${data.spaceClusterId}/space/${data.spaceId}/hierarchy`,
          method: "GET",
        };
      },
    }),
    AdminListAllPowerConsumers: builder.query<
      AdminListAllDevicesResponseDTO,
      AdminListAllDevicesRequestDTO
    >({
      transformErrorResponse,
      query(data: AdminListAllDevicesRequestDTO) {
        return {
          url: `admin/power-consumer/list`,
          method: "GET",
          params: { ...data },
        };
      },
    }),
    AdminListAllSmartControllers: builder.query<
      AdminListAllSmartControllersResponseDTO,
      AdminListAllSmartControllersRequestDTO
    >({
      transformErrorResponse,
      query(data: AdminListAllSmartControllersRequestDTO) {
        return {
          url: `admin/smart-device/list`,
          method: "GET",
          params: { ...data },
        };
      },
    }),
    AdminViewSpaceClusterConsumptionByYearAndMonth: builder.query<
      AdminViewSpaceClusterConsumptionByYearAndMonthResponseDTO,
      {
        spaceClusterId: string;
        year: number;
        month: number;
        consumerId: string;
      }
    >({
      transformErrorResponse,
      query({ spaceClusterId, year, month, consumerId }) {
        return {
          url: `admin/space-cluster/${spaceClusterId}/consumption/by-year-month/view`,
          method: "GET",
          params: { year, month, consumerId },
        };
      },
    }),
    AdminViewSpaceClusterEnergyByYear: builder.query<
      AdminViewSpaceClusterEnergyByYearResponseDTO,
      {
        spaceClusterId: string;
        year: number;
        viewEnergyAs: EEnergyViewType;
        viewConsumptionAs: EConsumptionViewTypes;
        viewScheduleAs: EScheduleViewType;
        zoneOffset: string;
        consumerId: string;
      }
    >({
      transformErrorResponse,
      query({
        spaceClusterId,
        year,
        viewEnergyAs,
        consumerId,
        viewConsumptionAs,
        viewScheduleAs,
        zoneOffset,
      }) {
        return {
          url: `admin/space-cluster/${spaceClusterId}/energy/by-year/view`,
          method: "GET",
          params: {
            year,
            viewEnergyAs,
            consumerId,
            viewConsumptionAs,
            viewScheduleAs,
            zoneOffset,
          },
        };
      },
    }),
    feedback: builder.mutation<void, FeedbackRequestDTO>({
      query(data) {
        return {
          url: "consumer/feedback",
          method: "POST",
          body: data,
        };
      },
      transformErrorResponse,
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetPaginatedConsumersQuery,
  useLazyGetAllTariffsQuery,
  useLazyGetConsumerSpaceClusterViewQuery,
  useLazyGetAllSpaceClusterOfConsumerQuery,
  useLazyViewActualConsumptionByYearOfConsumerQuery,
  useLazyGetActualUsageOfConsumerQuery,
  useChangeConsumerImportanceMutation,
  useLazyGetSpaceClusterEnergyWithComparisonQuery,
  useLazyViewPowerConsumersWithHighConsumptionOfConsumerQuery,
  useLazyViewPowerGenerationDataOfConsumerQuery,
  useLazyViewConsumerSpaceClusterByIdentityQuery,
  useLazyAdminListSpaceClusterHierarchyByIdentityQuery,
  useLazyAdminListAllPowerConsumersQuery,
  useLazyAdminListAllSmartControllersQuery,
  useLazyAdminViewSpaceClusterConsumptionByYearAndMonthQuery,
  useLazyAdminViewSpaceClusterEnergyByYearQuery,
  useFeedbackMutation,
} = adminDashboardAPI;
