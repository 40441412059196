import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  useLazyCheckPendingDemandControlResponsesQuery,
  useViewWeeklyDemandControlRequestsQuery,
} from "../../redux/api/usage-guide/usageGuideAPI";
import CheckPendingDemandControlResponseDTO from "../../shared/oversight-core/dtos/response-dtos/check-pending-demand-control-response-dto";
import { EDemandControlRequestType } from "../../shared/oversight-core/enums/demand-control-request-type";
import { EPowerMeasureType } from "../../shared/oversight-core/enums/power-measure-type";
import { AppRoute } from "../../shared/oversight-core/interfaces/app-routes";
import { IDemandControlRequestView } from "../../shared/oversight-core/interfaces/demand-control-request-view";
import {
  DataColumn,
  Header,
  TimeGridEvent,
} from "../../shared/oversight-core/shared-components/calendar/models";
import useTimeGrid, {
  sundayStartToMondayStart,
} from "../../shared/oversight-core/shared-components/calendar/useTimeGrid";
import AppDatePicker from "../../shared/oversight-core/ui-elements/app-date-picker/app-date-picker";
import AppButton from "../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import ButtonWithDropdown from "../../shared/oversight-core/ui-elements/buttons/button-with-dropdown/button-with-dropdown";
import MaterialIcon from "../../shared/oversight-core/ui-elements/material-icon/material-icon";
import NavigateWeeks from "../../shared/oversight-core/ui-elements/navigate-weeks/navigate-weeks";
import SpinnerModal from "../../shared/oversight-core/ui-elements/spinner/spinner";
import {
  getMonthRangeToDisplay,
  getWeekRange,
} from "../../shared/oversight-core/utils/date-utils";
import { powerConvertor } from "../../shared/oversight-core/utils/power-convertor";
import { scrollToView } from "../../shared/oversight-core/utils/scroll-to-view";
import CreateModal from "./modal/create-modal/create-modal";
import DemandControlRequestDeleteModal from "./modal/demand-control-request-delete-modal/demand-control-request-delete-modal";
import styles from "./usage-guide.module.scss";

const UsageGuide = () => {
  const navigate = useNavigate();
  const [selectedCurrentDate, setSelectedCurrentDate] = useState(
    moment().valueOf()
  );
  const [events, setEvents] = useState<
    DataColumn<Date, IDemandControlRequestView>[]
  >([]);
  const [weekRange, setWeekRange] = useState<Date[]>(
    getWeekRange(new Date(), selectedCurrentDate)
  );
  const [isGoToCurrentTime, setIsGoToCurrentTime] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDemandControlResponsePending, setIsDemandControlResponsePending] =
    useState(false);
  const [isDemandControlRequestDeleted, setIsDemandControlRequestDeleted] =
    useState(false);
  const [deleteUsageGuideDataId, setDeleteUsageGuideDataId] = useState("");

  const [checkPendingDemandControlResponses] =
    useLazyCheckPendingDemandControlResponsesQuery();

  useEffect(() => {
    setWeekRange(
      getWeekRange(new Date(selectedCurrentDate), selectedCurrentDate)
    );
  }, [selectedCurrentDate]);

  const headers: Header<Date>[] = [];

  for (let cIndex = 0; cIndex < 7; cIndex++) {
    headers.push({
      columnId: moment(weekRange[cIndex]).toDate(),
      title: moment(weekRange[cIndex]).format("DD"),
      value: (
        <div className="d-flex flex-column">
          <div>{moment(weekRange[cIndex]).format("ddd")}</div>
          <div
            className={`${
              moment(weekRange[cIndex]).format("YYYY-MM-DD") ===
              moment().format("YYYY-MM-DD")
                ? `today-grid-style`
                : ``
            } px-1`}
          >
            {moment(weekRange[cIndex]).format("DD")}
          </div>
        </div>
      ),
      column: cIndex + 1, // +1 for row header
      header: true,
      row: 0,
    });
  }

  let isCurrentWeek = false;
  for (const day of weekRange) {
    if (moment(day).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {
      isCurrentWeek = true;
      break;
    }
  }

  const onCurrentTimeIndicatorRender = useCallback(
    (elemId: string) => {
      if (isGoToCurrentTime) {
        scrollToView(elemId);
        setIsGoToCurrentTime(false);
      }
    },
    [isGoToCurrentTime]
  );

  const isShowTimeBar = isCurrentWeek;
  const defaultTitle = "New Request";

  const { calendarContent } = useTimeGrid<Date, IDemandControlRequestView>(
    useMemo(() => {
      return {
        events,
        headers,
        isShowTimeBar,
        defaultTitle,
        isDisableCreatingOverlay: true,
        isDisableCreatingOverlayPastTime: true,
        onCurrentTimeIndicatorRender,
        isDemandControlRequestDeleted,
        viewEventModalTemplate: (events, onClose) => {
          return (
            <CreateModal<Date>
              id=""
              events={events}
              onDelete={(event) => {
                onClose();
                setDeleteUsageGuideDataId(event?.data?.id || "");
                setShowDeleteModal(true);
              }}
              onClose={onClose}
            />
          );
        },
        eventContentTemplate: (events) => {
          const renderOneEvent = (
            event: TimeGridEvent<Date, IDemandControlRequestView>,
            isGrouped: boolean
          ) => (
            <>
              <Row>
                {!isGrouped && event.data?.demandControlRequestType && (
                  <Col className="col-auto">
                    <MaterialIcon
                      icon={
                        event.data?.demandControlRequestType ===
                        EDemandControlRequestType.DEMAND_CONTROL_LIMIT
                          ? "error"
                          : "release_alert"
                      }
                      color={
                        event.data?.demandControlRequestType ===
                        EDemandControlRequestType.DEMAND_CONTROL_LIMIT
                          ? "#E8C304"
                          : "#EC7575"
                      }
                      size={16}
                    />
                  </Col>
                )}
                <Col
                  className={`${
                    !isGrouped && event.data?.demandControlRequestType
                      ? "ps-0"
                      : ""
                  } ${
                    isGrouped ? `font-size-9` : `font-size-10`
                  } font-weight-500 text-white`}
                >
                  <div className={isGrouped ? `${styles.title} bg-gray-4` : ""}>
                    {event.title}
                  </div>
                </Col>
              </Row>
              {!isGrouped && (
                <Row className="font-size-10 font-weight-500 text-white">
                  <Col className="col-auto pe-0">
                    {powerConvertor(
                      event.data?.power || 0,
                      EPowerMeasureType.MEGA_WATT
                    )}
                  </Col>
                  <Col className="ps-0">MW</Col>
                </Row>
              )}
              {!isGrouped && (
                <Row>
                  <Col>
                    {moment()
                      .startOf("day")
                      .add(event.startTime, "minutes")
                      .format("HH:mm")}
                    -
                    {moment()
                      .startOf("day")
                      .add(event.endTime, "minutes")
                      .format("HH:mm")}
                  </Col>
                </Row>
              )}
            </>
          );
          return (
            <>
              {events?.length > 1 && (
                <Row>
                  <Col className="font-size-10 font-weight-500">
                    {events.length} Collapsed Guides
                  </Col>
                </Row>
              )}
              {events?.map((event, eventIndex) => {
                return (
                  <Row key={eventIndex}>
                    <Col>{renderOneEvent(event, events?.length > 1)}</Col>
                  </Row>
                );
              })}
            </>
          );
        },
      };
    }, [
      events,
      headers,
      isShowTimeBar,
      onCurrentTimeIndicatorRender,
      isDemandControlRequestDeleted,
    ])
  );

  const startDate = weekRange[0];

  const {
    data: demandControlResponse,
    isFetching: isFetchingViewWeeklyDemandControlResponse,
  } = useViewWeeklyDemandControlRequestsQuery({
    date: moment(startDate).startOf("day").toISOString(true),
  });

  useEffect(() => {
    if (demandControlResponse) {
      const groupByDateOrDevId: Record<
        number | string,
        DataColumn<Date, IDemandControlRequestView>
      > = {};
      demandControlResponse.demandControlRequestViews.forEach((dc) => {
        const fromDate = moment(new Date(dc.demandControlPeriod.fromDate));
        const toDate = moment(new Date(dc.demandControlPeriod.toDate));

        const day = fromDate.day();
        const columnId = fromDate.toDate().toISOString();
        const columnData = fromDate.toDate();

        const generated = {
          title: dc.title,
          columnData,
          startTime: fromDate
            .clone()
            .diff(fromDate.clone().startOf("day"), "minutes"),
          endTime: toDate
            .clone()
            .diff(toDate.clone().startOf("day"), "minutes"),
          date: fromDate.toDate(),
          data: dc,
        };

        if (generated) {
          // need to collect the event since event is generated
          if (!groupByDateOrDevId[day]) {
            // create a column if not exist
            groupByDateOrDevId[day] = {
              columnId,
              columnData,
              columnIndex: sundayStartToMondayStart(day),
              events: [],
            };
          }
          // then collect the event
          groupByDateOrDevId[day].events.push(generated);
        }
      });
      setEvents(Object.values(groupByDateOrDevId));
    } else {
      setEvents([]);
    }
  }, [startDate, isDemandControlRequestDeleted, demandControlResponse]);

  useEffect(() => {
    checkPendingDemandControlResponses()
      .unwrap()
      .then((res: CheckPendingDemandControlResponseDTO) => {
        setIsDemandControlResponsePending(res.pending);
      })
      .catch(() => {
        setIsDemandControlResponsePending(false);
      });
  }, [checkPendingDemandControlResponses]);

  const onClickThisWeekButton = () => {
    const currentDate = new Date();
    const currentWeekStartDate = moment(currentDate)
      .startOf("isoWeek")
      .toDate();
    setSelectedCurrentDate(moment(currentWeekStartDate).valueOf());
  };

  return (
    <div className="container-white position-relative">
      <Row className="align-items-center">
        <Col>
          <Row className="align-items-center justify-content-center justify-content-xl-start">
            <Col className="col-auto">
              <AppDatePicker
                selectedDate={new Date(selectedCurrentDate)}
                onChange={(date: Date) => {
                  setSelectedCurrentDate(moment(date).valueOf());
                }}
                isInput={true}
                showDate={false}
              />
            </Col>
            <Col className="col-auto">
              <NavigateWeeks
                selectedDate={new Date(selectedCurrentDate)}
                handleDateChange={(date: Date) => {
                  setSelectedCurrentDate(moment(date).valueOf());
                }}
              />
            </Col>
            <Col className="col-auto pe-0 font-size-12 font-weight-500 text-light">
              {getMonthRangeToDisplay(weekRange)}
            </Col>
            <Col className="col-12 col-sm-3 col-xxl-3 mt-3 mt-sm-0">
              <AppButton
                text="This Week"
                size="medium"
                onClick={onClickThisWeekButton}
                className="font-size-12 font-weight-500 py-1"
                variant="transparentWithBorder"
              />
            </Col>
            <Col className="col-12 col-sm-1 col-xxl-3 mt-3 mt-sm-0">
              <AppButton
                text="Now"
                size="medium"
                onClick={() => {
                  setIsGoToCurrentTime(true);
                  onClickThisWeekButton();
                }}
                className="font-size-12 font-weight-500"
                variant="transparentWithBorder"
              />
            </Col>
          </Row>
        </Col>
        <Col className="col-12 col-xl-5 mt-3 mt-xl-0">
          <Row className="align-items-center justify-content-center justify-content-xl-end">
            <Col className="col-auto pe-0">
              <AppButton
                text="Requests"
                size="medium"
                className="font-size-12 font-weight-500"
                variant="transparentWithBorder"
                onClick={() => navigate(AppRoute.REQUESTS)}
                isShowIndicator={isDemandControlResponsePending}
              />
            </Col>
            <Col className="col-auto pe-0">
              <ButtonWithDropdown
                items={[
                  {
                    name: "Automated Demand Response",
                    onClick: () => {
                      navigate(AppRoute.AUTOMATED_DEMAND_CONTROL);
                    },
                  },
                ]}
                buttonName="New Response"
              />
            </Col>
            <Col className="col-auto">
              <MaterialIcon
                icon={"help"}
                color={"#69768B"}
                className="user-select-none"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={`${styles[`gride-overflow`]} mt-4`}>
        <Col className="px-0">{calendarContent}</Col>
      </Row>
      <DemandControlRequestDeleteModal
        show={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onClose={() => setShowDeleteModal(false)}
        onSuccess={() => {
          setIsDemandControlRequestDeleted((ps) => !ps);
        }}
        demandControlRequestId={deleteUsageGuideDataId}
      />
      <SpinnerModal show={isFetchingViewWeeklyDemandControlResponse} />
    </div>
  );
};

export default UsageGuide;
