import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useLazyViewAllDemandControlResponsesQuery } from "../../../../redux/api/usage-guide/usageGuideAPI";
import ViewAllDemandControlResponseDTO from "../../../../shared/oversight-core/dtos/response-dtos/view-all-demand-control-response-dto";
import { EDemandControlResponseStatus } from "../../../../shared/oversight-core/enums/deman-control-response-status";
import { AppRoute } from "../../../../shared/oversight-core/interfaces/app-routes";
import { IDemandControlResponseView } from "../../../../shared/oversight-core/interfaces/demand-control-response-view";
import DataTable from "../../../../shared/oversight-core/ui-elements/data-table/data-table";
import MaterialIcon from "../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import SpinnerModal from "../../../../shared/oversight-core/ui-elements/spinner/spinner";
import { formatTime } from "../../../../shared/oversight-core/utils/time-utils";
import Pagination from "../../../users/pagination/pagination";
import styles from "./requests.module.scss";

const Requests = () => {
  const navigate = useNavigate();

  const [filters, setFilters] = useState({ pageNumber: 0, pageSize: 12 });
  const [totalElements, setTotalElements] = useState(0);
  const [allDemandControlResponses, setAllDemandControlResponses] = useState<
    IDemandControlResponseView[]
  >([]);

  const [
    triggerAllDemandControlResponses,
    { isFetching: isFetchingAllDemandControlResponses },
  ] = useLazyViewAllDemandControlResponsesQuery();

  useEffect(() => {
    triggerAllDemandControlResponses({
      pageNumber: filters.pageNumber,
      pageSize: filters.pageSize,
    })
      .unwrap()
      .then((res: ViewAllDemandControlResponseDTO) => {
        setAllDemandControlResponses(res.genericPage.elements);
        setTotalElements(res.genericPage.totalElements);
      })
      .catch(() => setTotalElements(0));
  }, [filters, triggerAllDemandControlResponses]);

  const demandControlResponseStatus = (
    status: EDemandControlResponseStatus
  ) => {
    switch (status) {
      case EDemandControlResponseStatus.COMPLETED:
        return "Generated";
      case EDemandControlResponseStatus.FAILED:
        return "Failed";
      default:
        return "Pending";
    }
  };

  return (
    <div className="position-relative">
      <div className="container-white position-relative">
        <Row>
          <Col className="col-auto pe-0">
            <div className={`${styles.arrowBtn} bg-primary p-1`}>
              <MaterialIcon
                icon="arrow_left"
                className="text-white"
                style={{ cursor: "pointer" }}
                onClick={() => navigate(-1)}
              />
            </div>
          </Col>
        </Row>
        {allDemandControlResponses && allDemandControlResponses.length > 0 ? (
          <Row>
            <DataTable
              headers={[
                { id: 1, columnName: "Title" },
                { id: 2, columnName: "Date" },
                { id: 3, columnName: "Time" },
                { id: 4, columnName: "Reduction" },
                { id: 5, columnName: "Status" },
                { id: 6, columnName: "" },
              ]}
              data={allDemandControlResponses?.map((dcr) => {
                return {
                  data: [
                    <>{dcr.demandControlRequestView.title}</>,
                    <>
                      {moment(
                        dcr.demandControlRequestView.demandControlDate
                      ).format("DD/MM/YYYY")}
                    </>,
                    <>{`${formatTime(
                      new Date(
                        dcr.demandControlRequestView.demandControlPeriod.fromDate
                      )
                    )} - ${formatTime(
                      new Date(
                        dcr.demandControlRequestView.demandControlPeriod.toDate
                      )
                    )}`}</>,
                    <>{dcr.reductionNeededPowerUsage || "-"}</>,
                    <>
                      <div
                        className={
                          dcr.status !== EDemandControlResponseStatus.COMPLETED
                            ? styles.txt1
                            : ``
                        }
                      >
                        {demandControlResponseStatus(
                          dcr.status as EDemandControlResponseStatus
                        )}
                      </div>
                    </>,
                    <>
                      <MaterialIcon
                        key={`${dcr.id}`}
                        icon="visibility"
                        color="#2F2A89"
                        className="cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(
                            AppRoute.AUTOMATED_DEMAND_CONTROL + `/${dcr.id}`,
                            {
                              state: {
                                demandControl: dcr,
                              },
                            }
                          );
                        }}
                        size={15}
                      />
                    </>,
                  ],
                };
              })}
            />
            <Pagination
              itemsPerPage={filters.pageSize}
              length={totalElements}
              currentPage={filters.pageNumber + 1}
              updateCurrentPage={(pn) => {
                setFilters((ps) => ({ ...ps, pageNumber: pn - 1 }));
              }}
            />
          </Row>
        ) : (
          <>
            {!isFetchingAllDemandControlResponses && (
              <div className="container-dash mt-3">
                <Row>
                  <Col className="text-center font-size-14 text-light">
                    There are no requests.
                  </Col>
                </Row>
              </div>
            )}
          </>
        )}
      </div>

      <SpinnerModal show={isFetchingAllDemandControlResponses} />
    </div>
  );
};

export default Requests;
