import { useEffect, useRef, useState } from "react";
import AppButton from "../app-button/app-button";
import styles from "./button-with-dropdown.module.scss";

interface IProps {
  items: {
    name: string;
    onClick: () => void;
  }[];
  buttonName: string;
}

const ButtonWithDropdown = (props: IProps) => {
  const [showDropDown, setShowDropDown] = useState(false);

  const menuButtonRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: MouseEvent) => {
    if (
      menuButtonRef.current &&
      !menuButtonRef.current.contains(e.target as Node)
    ) {
      setShowDropDown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="d-flex justify-content-end" ref={menuButtonRef}>
      <AppButton
        text={props.buttonName}
        size="medium"
        className="font-size-12 font-weight-500"
        onClick={() => {
          setShowDropDown(!showDropDown);
        }}
      />
      {showDropDown && (
        <ul className={styles[`dropdown-menu`]}>
          {props.items?.map((item, index) => {
            return (
              <li key={index} onClick={() => item.onClick()}>
                {item.name}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default ButtonWithDropdown;
