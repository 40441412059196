import moment from "moment";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  useLazyGetUnreadNotificationsCountQuery,
  useMarkNotificationAsReadMutation,
} from "../../../../../redux/api/notification/notificationAPI";
import { markNotificationAsRead } from "../../../../../redux/features/notification/notification-slice";
import { AppDispatch } from "../../../../../redux/store";
import { EDemandControlRequestType } from "../../../enums/demand-control-request-type";
import { OvstErrorCode } from "../../../enums/ovst-error-codes";
import { NotificationView } from "../../../interfaces/notification-view";
import AppButton from "../../../ui-elements/buttons/app-button/app-button";
import MaterialIcon from "../../../ui-elements/material-icon/material-icon";
import { showSwitchErrorMessage } from "../../../utils/switch-error-message";
import NotificationContent from "../notification-content/notification-content";
import styles from "./notification-card.module.scss";

interface IProps {
  notifications: NotificationView[];
}

const NotificationsCard = (props: IProps) => {
  const [showNotificationsView, setShowNotificationsView] = useState(false);
  const [showNotifications, setShowNotifications] = useState(true);
  const [triggerGetUnreadNotificationsCount] =
    useLazyGetUnreadNotificationsCountQuery();
  const [readNotification] = useMarkNotificationAsReadMutation();

  const dispatch: AppDispatch = useDispatch();

  const handleViewClick = (notificationId: string) => {
    // setShowNotificationsView(true);
    // setShowNotifications(false);

    readNotification({ notificationId })
      .unwrap()
      .then(() => {
        dispatch(markNotificationAsRead(notificationId));
        triggerGetUnreadNotificationsCount();
      })
      .catch((error) => {
        if (error.status === 412) {
          showSwitchErrorMessage(error.ovstErrorCode as OvstErrorCode);
        }
      });
  };

  return (
    <div>
      {showNotifications &&
        (props.notifications.length > 0 ? (
          props.notifications
            .slice()
            .sort((a, b) => {
              return (
                new Date(b.enqueuedTime).getTime() -
                new Date(a.enqueuedTime).getTime()
              );
            })
            .map((notification) => (
              <div
                key={notification.id}
                className={`${
                  showNotificationsView || notification.hasUserOpened
                    ? ``
                    : styles[`notification-card`]
                }`}
              >
                <Row className="mx-4">
                  <Col className="col-12 col-sm-1 mt-4 pt-sm-3">
                    <MaterialIcon
                      icon="error"
                      color={
                        notification.payload.demandControlRequestType ===
                        EDemandControlRequestType.DEMAND_CONTROL_LIMIT
                          ? "#E8C304"
                          : "#ec7575"
                      }
                    />
                  </Col>
                  <Col className="col-12 col-sm-11 mt-2 mt-sm-4 mb-0 mb-sm-4">
                    <Row className="align-items-center">
                      <Col className="col-12 col-sm-10">
                        <div className="font-weight-300 text-light font-size-10 fst-italic">
                          {notification.sender}
                        </div>
                        <div className="font-weight-600 font-size-14">
                          {notification.title}
                        </div>
                        <div className="font-weight-300 text-light font-size-10 fst-italic">
                          {moment(notification.enqueuedTime).fromNow()}
                        </div>
                        <div className="font-weight-400 font-size-12 mt-1">
                          {notification.description}
                        </div>
                      </Col>
                      {!notification.hasUserOpened && (
                        <Col className="col-auto">
                          <AppButton
                            text="Mark as read"
                            variant="transparent"
                            size="medium"
                            className="font-weight-500 px-0"
                            onClick={() => handleViewClick(notification.id)}
                          />
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
                <hr className="my-0" />
              </div>
            ))
        ) : (
          <div
            className={`${styles.noNotifications} d-flex align-items-center px-4 text-light font-weight-500`}
          >
            No notifications available at the moment. Check again later.
          </div>
        ))}
      {showNotificationsView && (
        <NotificationContent
          onBack={() => {
            setShowNotifications(true);
            setShowNotificationsView(false);
          }}
        />
      )}
    </div>
  );
};

export default NotificationsCard;
