import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";

export interface Notification {
  id: string;
  title: string;
  description: string;
  sender: string;
  recipientUserId: string;
  recipientUsername: string;
  payload: any;
  enqueuedTime: Date;
  hasUserOpened: boolean;
}

interface NotificationState {
  data: Notification[];
  unreadNotificationCount: number;
  hasMore: boolean;
}

const initialState: NotificationState = {
  data: [],
  unreadNotificationCount: 0,
  hasMore: true,
};

export const notificationSlice = createSlice({
  name: "notifications",
  initialState: initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<Notification>) => {
      const isFound = state.data.find(
        (notification) => notification.id === action.payload.id
      );

      if (isFound) {
        return;
      }

      state.data = [action.payload, ...state.data];

      state.unreadNotificationCount = state.unreadNotificationCount + 1;
    },
    addNotifications: (
      state,
      action: PayloadAction<{
        isFirstTime: boolean;
        notifications: Notification[];
        hasMore: boolean;
      }>
    ) => {
      state.hasMore = action.payload.hasMore;
      if (action.payload.isFirstTime) {
        state.data = [...action.payload.notifications];
        return;
      }
      state.data = [...state.data, ...action.payload.notifications];
    },
    setUnreadNotificationCount: (state, action: PayloadAction<number>) => {
      state.unreadNotificationCount = action.payload;
    },
    markNotificationAsRead: (state, action: PayloadAction<string>) => {
      const notificationToBeRead = state.data.find(
        (n) => n.id === action.payload
      );
      if (notificationToBeRead) {
        notificationToBeRead.hasUserOpened = true;
      }
    },
  },
});

export const {
  addNotification,
  addNotifications,
  markNotificationAsRead,
  setUnreadNotificationCount,
} = notificationSlice.actions;

export const selectNotificationDetails = (state: RootState) =>
  state.notifications.data;

export const selectHasMoreNotifications = (state: RootState) =>
  state.notifications.hasMore;

export const countUnreadNotifications = (state: RootState) =>
  state.notifications.unreadNotificationCount;

export default notificationSlice.reducer;
