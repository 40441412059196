import { useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import { useLazyGetNotificationsQuery } from "../../../../redux/api/notification/notificationAPI";
import {
  selectHasMoreNotifications,
  selectNotificationDetails,
} from "../../../../redux/features/notification/notification-slice";
import SpinnerModal from "../../ui-elements/spinner/spinner";
import NotificationsCard from "./notification-card/notification-card";
import styles from "./notifications-panel.module.scss";

interface IProps {
  showNotifications: boolean;
}

const PAGE_SIZE = 10;

const NotificationsPanel = (props: IProps) => {
  const notifications = useSelector(selectNotificationDetails);
  const hasMoreNotifications = useSelector(selectHasMoreNotifications);
  const [currentPage, setCurrentPage] = useState(0);
  const [triggerGetNotifications] = useLazyGetNotificationsQuery();
  const ref = useRef<HTMLDivElement>(null);

  const fetchData = () => {
    triggerGetNotifications({
      pageNumber: currentPage + 1,
      pageSize: PAGE_SIZE,
      ascending: true,
    })
      .unwrap()
      .then(() => {
        setCurrentPage((ps) => ps + 1);
      });
  };

  return (
    <div
      className={`mx-2 ${styles["side-drawer"]} ${
        props.showNotifications ? styles.open : styles.close
      }`}
    >
      <InfiniteScroll
        dataLength={notifications.length}
        next={fetchData}
        hasMore={hasMoreNotifications}
        loader={<SpinnerModal show={true} withOverlay={false} />}
        height={notifications.length > 0 ? 500 : 200}
        style={{ overflowX: "hidden" }}
      >
        <div ref={ref}>
          <NotificationsCard notifications={notifications} />
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default NotificationsPanel;
