import { Option } from "../../oversight-core/ui-elements/app-select/app-select";
import { ELimitationType } from "../enums/limitation-type";

export const limitationOptions: Option[] = [
  { value: ELimitationType.DEFAULT, label: "Default" },
  { value: ELimitationType.ONLY_GIVE_WARNING, label: "Only Give Warning" },
  {
    value: ELimitationType.EXCLUDE_FROM_SCHEDULE,
    label: "Exclude From Schedule",
  },
];
