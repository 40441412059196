import { EDemandControlMethod } from "../enums/demand-control-method";

export const findDemandControllerMethod = (
  demandControllerMethod: EDemandControlMethod
) => {
  switch (demandControllerMethod) {
    case EDemandControlMethod.PROPORTIONATELY_FROM_EVERY_USER:
      return "Proportionately from Every User";
    case EDemandControlMethod.HIGHEST_POWER_USING_DEVICES:
      return "Highest Power Using Devices";
    case EDemandControlMethod.HIGHEST_POWER_SAVING_POSSIBLE:
      return "Highest Power Saving Possible";
  }
};
