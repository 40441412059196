import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useLazyViewConsumerSpaceClusterByIdentityQuery } from "../../../redux/api/consumer/consumerAPI";
import { ISpaceView } from "../../oversight-core/interfaces/entities/space";
import MaterialIcon from "../../oversight-core/ui-elements/material-icon/material-icon";
import styles from "./filter-search.module.scss";

interface IProps {
  selectedSpace: { clusterId: string; spaceId: string };
  sendSelectedSpace: (space: ISpaceView) => void;
}

const FilterSearch = (props: IProps) => {
  const [searchValue, setSearchValue] = useState("");
  const [filterSpaces, setFilterSpaces] = useState<ISpaceView[]>([]);

  const [triggerGetSpace, { data: spaceData }] =
    useLazyViewConsumerSpaceClusterByIdentityQuery();

  useEffect(() => {
    triggerGetSpace({
      spaceClusterId: props.selectedSpace.clusterId,
      subRootSpaceId: props.selectedSpace.spaceId,
    });
  }, [props.selectedSpace, triggerGetSpace]);

  const spaces = (singleSpace: ISpaceView) => {
    const spacesList: ISpaceView[] = [];
    const space = (item: ISpaceView) => {
      if (item === null) return;
      spacesList.push(item);

      for (const child of item.childSpaces) {
        space(child);
      }
    };

    space(singleSpace);
    return spacesList;
  };

  useEffect(() => {
    if (spaceData?.subSpaceCluster.rootSpace) {
      const filterSpace = spaces(spaceData?.subSpaceCluster.rootSpace).filter(
        (item) => item.name.toLowerCase().includes(searchValue.toLowerCase())
      );

      setFilterSpaces(filterSpace);
    }
  }, [spaceData?.subSpaceCluster.rootSpace, searchValue]);

  return (
    <div className={styles.searchWrapper}>
      <Row className="align-items-center px-2 py-1">
        <Col>
          <input
            type="text"
            placeholder="Search Space"
            value={searchValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchValue(e.target.value);
            }}
            className={`${styles[`search-input`]} w-100`}
          />
          {searchValue && (
            <ul className={`${styles[`dropdown-menu`]} ${styles.overflow}`}>
              {filterSpaces?.map((space, index) => {
                return (
                  <li
                    key={index}
                    onClick={() => {
                      props.sendSelectedSpace({
                        ...space,
                        clusterId: props.selectedSpace.clusterId,
                      });
                      setSearchValue("");
                    }}
                  >
                    {space.name}
                  </li>
                );
              })}
            </ul>
          )}
        </Col>
        <Col className="col-auto">
          <MaterialIcon icon="search" color="#69768B" size={20} />
        </Col>
      </Row>
    </div>
  );
};

export default FilterSearch;
