import { isRejectedWithValue, Middleware } from "@reduxjs/toolkit";
import { OvstErrorCode } from "../../shared/oversight-core/enums/ovst-error-codes";
import { IHttpError } from "../../shared/oversight-core/interfaces/http-errror";
import { setToken } from "../features/auth/auth-slice";

export const rtkQueryErrorLogger: Middleware = (api) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const error = action.payload as IHttpError;
    switch (error.status) {
      case 500:
        console.log(error);
        break;
      case 401:
        if (error.ovstErrorCode === OvstErrorCode.OVST_SEC_0001) {
          localStorage.clear();
          api.dispatch(setToken(""));
        }
        break;
      default:
        break;
    }
  }

  return next(action);
};
