import styles from "./table-row.module.scss";

export interface ITableRowProps {
  data: JSX.Element[];
  disable?: boolean;
}

const TableRow = (props: ITableRowProps) => {
  const { data, disable } = props;
  return (
    <tr
      style={{ verticalAlign: "middle" }}
      className={`${disable && styles.disable}`}
    >
      <>
        {data.map((item, index) => (
          <td key={index} className="text-light font-size-12 font-weight-400">
            {item}
          </td>
        ))}
      </>
    </tr>
  );
};

export default TableRow;
