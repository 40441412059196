import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useForm } from "react-hook-form";
import { useLazyListTariffsQuery } from "../../../../../../../redux/api/space/spaceAPI";
import {
  useAddAccountNumberMutation,
  useUpdateAccountNumberMutation,
} from "../../../../../../../redux/api/user/userAPI";
import { OvstErrorCode } from "../../../../../enums/ovst-error-codes";
import { IAccountNumbersResponse } from "../../../../../interfaces/extendedUserProfile";
import ConfirmationModal from "../../../../../shared-components/confirmation-modal/confirmation-modal";
import AppSelect, {
  Option,
} from "../../../../../ui-elements/app-select/app-select";
import AppInput from "../../../../../ui-elements/input/app-input";
import ModalContainer, {
  ModelContainerProps,
} from "../../../../../ui-elements/modal-container/modal-container";
import {
  noSpecialCharsNoWhitespaceStartEndNotAllowCommaAndSingleMarkRegex,
  numericsOnlyRegex,
} from "../../../../../utils/regex";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../../utils/toast";

interface IFormInput {
  accountNumber: string;
  label: string;
  tariffCode: Option | Option[] | undefined | [];
}

const defaultFormValues: IFormInput = {
  accountNumber: "",
  label: "",
  tariffCode: [],
};

interface IProps extends ModelContainerProps {
  closeAddUpdateAccountNumberModal: () => void;
  accountData?: IAccountNumbersResponse;
  getAccountNumbers: () => void;
}

const AddUpdateAccountNumberModal = (props: IProps) => {
  const { ...rest } = props;
  const [tariffs, setTariffs] = useState<Option[]>([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const [addAccountNumber, { isLoading: isLoadingAddAccountNumber }] =
    useAddAccountNumberMutation();
  const [updateAccountNumber, { isLoading: isLoadingUpdateAccountNumber }] =
    useUpdateAccountNumberMutation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
  } = useForm<IFormInput>({
    defaultValues: { ...defaultFormValues },
  });

  const [triggerListTariffs, { isLoading: isTariffLoading }] =
    useLazyListTariffsQuery();

  const accountData: IAccountNumbersResponse | undefined = props.accountData;

  useEffect(() => {
    setValue("accountNumber", accountData?.accountNumber || "");
    setValue(
      "tariffCode",
      tariffs.find((option) => option.value === accountData?.tariffCode) || []
    );
    setValue("label", accountData?.label || "");
  }, [accountData, setValue, tariffs]);

  useEffect(() => {
    triggerListTariffs().then((response) => {
      if (!response.data) {
        return;
      }

      const tempTariffs: Option[] = response.data.tariffs.map((tariff) => {
        return {
          label: `${tariff.code} - ${tariff.description}`,
          value: tariff.code,
        };
      });
      setTariffs(tempTariffs);
    });
  }, [triggerListTariffs]);

  const requestSuccessHandler = (message: string) => {
    showSuccessMessage(message);
    reset({ ...defaultFormValues });
    props.getAccountNumbers();
    props.closeAddUpdateAccountNumberModal();
  };

  const onSubmit = (data: IFormInput) => {
    if (!accountData?.accountNumber) {
      addAccountNumber({
        accountNumber: data.accountNumber,
        label: data.label,
        tariffCode: (data.tariffCode as Option).value,
      })
        .unwrap()
        .then(() => {
          ReactGA.event({
            category: "Successful",
            action: "Add Billing Account",
          });
          requestSuccessHandler("Account Number Added Successfully");
        })
        .catch((error) => {
          if (
            error.status === 412 &&
            error.ovstErrorCode === OvstErrorCode.OVST_0044
          ) {
            showErrorMessage(
              "Inserted billing label is already in use, please add a different one"
            );
            return;
          } else if (
            error.status === 412 &&
            error.ovstErrorCode === OvstErrorCode.OVST_0042
          ) {
            showErrorMessage(
              "Account number cannot be added or updated using an already added account number"
            );
            return;
          }
          showErrorMessage(error.message || "Creating account number failed");
        });
    } else {
      setShowConfirmationModal(true);
    }
  };

  const onConfirmUpdateBillingAccountNumber = (data: IFormInput) => {
    if (accountData) {
      updateAccountNumber({
        oldAccountNumber: accountData.accountNumber,
        label: data.label,
        tariffCode: (data.tariffCode as Option).value,
        updatedAccountNumber: data.accountNumber,
      })
        .unwrap()
        .then(() => {
          requestSuccessHandler("Account Number Updated Successfully");
          setShowConfirmationModal(false);
        })
        .catch((error) => {
          if (
            error.status === 412 &&
            error.ovstErrorCode === OvstErrorCode.OVST_0044
          ) {
            showErrorMessage(
              "Inserted billing label is already in use, please add a different one"
            );
            return;
          } else if (
            error.status === 412 &&
            error.ovstErrorCode === OvstErrorCode.OVST_0042
          ) {
            showErrorMessage(
              "Account number cannot be added or updated using an already added account number"
            );
            return;
          }
          showErrorMessage(error.message || "Updating account number failed");
        });
    }
  };

  return (
    <>
      <ModalContainer
        {...rest}
        title={
          accountData?.accountNumber
            ? "Update Billing Account Number"
            : "Add Billing Account Number"
        }
        size="modal-lg"
        onConfirm={handleSubmit(onSubmit)}
        isLoading={isLoadingAddAccountNumber || isTariffLoading}
        onCancel={() => {
          reset({ ...defaultFormValues });
          if (props.onCancel) {
            props.onCancel();
          }
        }}
        onClose={() => {
          reset({ ...defaultFormValues });
          if (props.onClose) {
            props.onClose();
          }
        }}
        confirmId="billing-account-confirm"
      >
        <>
          <AppInput
            label="Billing Label"
            placeholder="Billing Label"
            name="label"
            type="text"
            register={register("label", {
              required: "Label is required",

              pattern: {
                value:
                  noSpecialCharsNoWhitespaceStartEndNotAllowCommaAndSingleMarkRegex,
                message:
                  "Entered value can't start/end or contain only white spaces and can't contain special characters",
              },
            })}
            errors={errors}
          />
          <AppSelect
            className="mt-4"
            defaultValue={""}
            label="Tariff Type"
            options={tariffs}
            control={control}
            name="tariffCode"
            placeholder="Tariff Type"
            register={register("tariffCode", {
              required: "Please select a tariff code",
            })}
            errors={errors}
            menuHeight="200px"
            isRequired={true}
            id="tariff-type"
          />
          <AppInput
            className="mt-4"
            label="Billing Account number"
            placeholder="Billing Account number"
            name="accountNumber"
            type="text"
            register={register("accountNumber", {
              required: "Account number is required",

              pattern: {
                value: numericsOnlyRegex,
                message: "Only numerics are allowed",
              },
            })}
            errors={errors}
          />
        </>
      </ModalContainer>
      <ConfirmationModal
        show={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        onCancel={() => setShowConfirmationModal(false)}
        onConfirm={handleSubmit(onConfirmUpdateBillingAccountNumber)}
        title="Update Billing Account Number"
        isLoading={isLoadingUpdateAccountNumber}
      />
    </>
  );
};

export default AddUpdateAccountNumberModal;
