import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useLazyGetAllSpaceClusterOfConsumerQuery } from "../../../redux/api/consumer/consumerAPI";
import { ISpaceClusterWithPowerGeneratorIds } from "../../oversight-core/dtos/response-dtos/dashboard-api-dto";
import { EDeviceTypes } from "../../oversight-core/enums/device-types";
import { ESmartControllerType } from "../../oversight-core/enums/smart-controller-type";
import { ISelectedSpace } from "../../oversight-core/interfaces/selected-space";
import UserDashboardFilters from "../../oversight-core/interfaces/user-dashboard-filters";
import FilteringSidePanel from "../../oversight-core/shared-components/filtering-side-panel/filtering-side-panel";
import AppSelect, {
  Option,
} from "../../oversight-core/ui-elements/app-select/app-select";
import ButtonWithBadge from "../../oversight-core/ui-elements/buttons/button-with-badge/button-with-badge";
import SpaceSelectorDropdown from "../../oversight-core/ui-elements/space-selector-dropdown/space-selector-dropdown";
import getDeviceTypes, {
  getDeviceLabel,
} from "../../oversight-core/utils/getDeviceType";
import { showErrorMessage } from "../../oversight-core/utils/toast";
import SelectedFiltersView from "../selected-filters-view/selected-filters-view";

const deviceTypes: Option[] = [
  { value: "", label: getDeviceLabel(EDeviceTypes.ALL_DEVICES, true) },
  ...getDeviceTypes(true),
];

const smartDeviceTypeOptions = [
  { label: "All", value: "" },
  { label: "Smart Plugs", value: ESmartControllerType.SMART_PLUG },
  { label: "AC Controllers", value: ESmartControllerType.AC_CONTROLLER },
];

interface IProps {
  setFilters: React.Dispatch<React.SetStateAction<UserDashboardFilters>>;
  filters: UserDashboardFilters;
  selectedSpaceCluster: ISpaceClusterWithPowerGeneratorIds | undefined;
}

const SpaceDeviceFilters = (props: IProps) => {
  const { selectedSpaceCluster, setFilters, filters } = props;
  const { state: user } = useLocation();

  const [showFilters, setShowFilters] = useState(false);
  const [selectedDeviceType, setSelectedDeviceType] = useState<Option>({
    ...deviceTypes[0],
  });
  const [selectedSmartDeviceType, setSelectedSmartDeviceType] =
    useState<Option>({ ...smartDeviceTypeOptions[0] });
  const [badgeValue, setBadgeValue] = useState(0);
  const [spaceClusters, setSpaceClusters] = useState<
    ISpaceClusterWithPowerGeneratorIds[]
  >([]);
  const [selectedSpace, setSelectedSpace] = useState<ISelectedSpace>({
    id: "",
    name: "",
    clusterId: "",
  });

  const [triggerGetSpaceClusters] = useLazyGetAllSpaceClusterOfConsumerQuery();

  useEffect(() => {
    if (user?.id) {
      triggerGetSpaceClusters(user.id)
        .unwrap()
        .then((res) => {
          setSpaceClusters(res.spaceClusters);
        })
        .catch(() => {
          setSpaceClusters([]);
          showErrorMessage("Sorry, An error occurred");
        });
    } else {
      setSpaceClusters([]);
    }
  }, [user?.id, triggerGetSpaceClusters]);

  useEffect(() => {
    if (
      filters.selectedFilterSpace.id !== selectedSpaceCluster?.rootSpace.id &&
      filters.selectedDeviceType &&
      filters.selectedSmartControllerType
    ) {
      setBadgeValue(3);
    } else if (
      (filters.selectedDeviceType && filters.selectedSmartControllerType) ||
      (filters.selectedSmartControllerType &&
        filters.selectedFilterSpace.id !==
          selectedSpaceCluster?.rootSpace.id) ||
      (filters.selectedDeviceType &&
        filters.selectedFilterSpace.id !== selectedSpaceCluster?.rootSpace.id)
    ) {
      setBadgeValue(2);
    } else if (
      filters.selectedFilterSpace.id !== selectedSpaceCluster?.rootSpace.id ||
      filters.selectedDeviceType ||
      filters.selectedSmartControllerType
    ) {
      setBadgeValue(1);
    } else {
      setBadgeValue(0);
    }
  }, [
    selectedSpace.clusterId,
    selectedSpace.id,
    selectedDeviceType.value,
    selectedSmartDeviceType.value,
    selectedSpaceCluster?.rootSpace.id,
    filters.selectedFilterSpace.id,
    filters.selectedDeviceType,
    filters.selectedSmartControllerType,
  ]);

  useEffect(() => {
    setSelectedSpace({
      clusterId: selectedSpaceCluster?.id || "",
      id: selectedSpaceCluster?.rootSpace.id || "",
      name: selectedSpaceCluster?.rootSpace.name || "",
    });
  }, [selectedSpaceCluster]);

  return (
    <>
      <Row className="justify-content-center justify-content-sm-end align-items-center px-1">
        <Col className="col-auto">
          <SelectedFiltersView
            spaceName={filters.selectedFilterSpace.name}
            filters={[
              {
                icon: filters.selectedDeviceType
                  ? (filters.selectedDeviceType as EDeviceTypes)
                  : EDeviceTypes.ALL_DEVICES,
                filterName: filters.selectedDeviceType || "All devices",
                smartControllerTpe: filters.selectedSmartControllerType,
              },
            ]}
          />
        </Col>
        <Col className="col-auto">
          <ButtonWithBadge
            text="Filter"
            icon="filter_alt"
            onClick={() => {
              setShowFilters(true);
            }}
            badgeValue={badgeValue}
          />
        </Col>
      </Row>
      <FilteringSidePanel
        isOpen={showFilters}
        onClose={() => setShowFilters(false)}
        onFilter={() => {
          setShowFilters(false);
          setFilters({
            selectedFilterSpace: {
              clusterId: selectedSpace.clusterId,
              id: selectedSpace.id,
              name: selectedSpace.name,
            },
            selectedDeviceType: selectedDeviceType.value,
            selectedSmartControllerType:
              selectedSmartDeviceType.value as ESmartControllerType,
            badgeValue: badgeValue,
          });
        }}
        onClearAll={() => {
          setFilters({
            selectedFilterSpace: {
              clusterId: selectedSpaceCluster?.id || "",
              id: selectedSpaceCluster?.rootSpace.id || "",
              name: selectedSpaceCluster?.label || "",
            },
            selectedDeviceType: "",
            selectedSmartControllerType: undefined,
            badgeValue: 0,
          });
          setSelectedSpace({
            clusterId: selectedSpaceCluster?.id || "",
            id: selectedSpaceCluster?.rootSpace.id || "",
            name: selectedSpaceCluster?.rootSpace.name || "",
          });
          setSelectedDeviceType({ ...deviceTypes[0] });
          setSelectedSmartDeviceType({ ...smartDeviceTypeOptions[0] });
          setBadgeValue(0);
          setShowFilters(false);
        }}
      >
        <>
          <SpaceSelectorDropdown
            dropdownOptions={
              selectedSpaceCluster
                ? [
                    ...spaceClusters
                      .filter((space) => space.id === selectedSpaceCluster.id)
                      .map((space) => {
                        return {
                          clusterId: space.id,
                          id: space.rootSpace.id,
                          name: space.label,
                          childSpaces: space.rootSpace.childSpaces,
                        };
                      }),
                  ]
                : []
            }
            disabled={false}
            selectedSubSpace={selectedSpace.name}
            updateSelectedSubSpaceId={(
              selectedSubSpaceId: string,
              selectedSubSpaceName: string,
              clusterId?: string
            ) => {
              setSelectedSpace({
                id: selectedSubSpaceId,
                name: selectedSubSpaceName,
                clusterId: clusterId || "",
              });
            }}
            label="Space"
            className="font-weight-500 text-light"
          />
          <AppSelect
            label="Device Type"
            options={[...deviceTypes]}
            className="mt-4"
            selectedValue={selectedDeviceType}
            onChangeOption={(selectedDeviceTypeOption) =>
              setSelectedDeviceType(selectedDeviceTypeOption)
            }
            fontSize="12px"
            labelFontSize="font-size-14"
            fontWeight="500"
            fontColor="#69768b"
          />
          <AppSelect
            label="Smart Controller Type"
            options={smartDeviceTypeOptions}
            className="mt-4"
            selectedValue={selectedSmartDeviceType}
            onChangeOption={(selectedSmartDeviceOption) => {
              setSelectedSmartDeviceType(selectedSmartDeviceOption);
            }}
            fontSize="12px"
            labelFontSize="font-size-14"
            fontWeight="500"
            fontColor="#69768b"
          />
        </>
      </FilteringSidePanel>
    </>
  );
};

export default SpaceDeviceFilters;
