import {
  DeepMap,
  FieldError,
  FieldValues,
  UseFormRegisterReturn,
} from "react-hook-form";
import Helper from "../../utils/helpers";
import MaterialIcon, { MtIcon } from "../material-icon/material-icon";

export type FieldErrors<TFieldValues extends FieldValues = FieldValues> =
  DeepMap<TFieldValues, FieldError>;

interface IProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  errorMessage?: string;
  icon?: MtIcon;
  label?: string;
  onIconClick?: () => void;
  register?: UseFormRegisterReturn;
  placeholder: string;
  errors: FieldErrors;
  isError?: boolean;
  isSuccess?: boolean;
  newPassword?: boolean;
  isRequired?: boolean;
  borderRadiusStyle?: "Right" | "Left";
}

const AppInput = (props: IProps) => {
  const {
    className,
    icon,
    label,
    onIconClick,
    register,
    placeholder,
    isRequired = true,
    borderRadiusStyle,
    errors,
    isError,
    isSuccess,
    onKeyDown,
    ...rest
  } = props;

  const getBorderRadiusStyle = () => {
    switch (borderRadiusStyle) {
      case "Right":
        return "12px 0 0 12px";
      case "Left":
        return "0px 12px 12px 0px";
      default:
        return "12px";
    }
  };

  return (
    <div className={`app-input-wrapper ${className}`}>
      {label && (
        <>
          {label}
          <label className="asterisk">{isRequired && "*"}</label>
        </>
      )}
      <div
        className={
          (rest.name && errors[rest.name]?.message) || isError
            ? "app-input has-danger"
            : isSuccess
            ? "app-input has-success"
            : "app-input"
        }
        style={{ borderRadius: `${getBorderRadiusStyle()}` }}
      >
        {rest.type === "number" ? (
          <input
            {...register}
            placeholder={placeholder}
            type={rest.type}
            min="0"
            onKeyDown={onKeyDown ?? Helper.validateInputTypeNumber}
            disabled={rest.disabled}
            className={`${props.disabled ? "app-button-disabled" : ""}`}
          />
        ) : (
          <input
            {...register}
            placeholder={placeholder}
            type={rest.type}
            autoComplete={props.newPassword ? "new-password" : ""}
            disabled={rest.disabled}
            className={`${props.disabled ? "app-button-disabled" : ""}`}
            onKeyDown={onKeyDown}
          />
        )}

        {props.icon && (
          <div className="icon" onClick={onIconClick}>
            <MaterialIcon
              icon={icon}
              color={isSuccess ? "#4FC755" : "#69768B"}
            />
          </div>
        )}
      </div>
      {rest.name && <span className="error">{errors[rest.name]?.message}</span>}
    </div>
  );
};

export default AppInput;
