import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useLazyViewConsumerSpaceClusterByIdentityQuery } from "../../../redux/api/consumer/consumerAPI";
import SpacePathViewBreadcrumb from "../../../shared/components/space-path-view-breadcrumb/space-path-view-breadcrumb";
import { ISpaceView } from "../../../shared/oversight-core/interfaces/entities/space";
import SpinnerModal from "../../../shared/oversight-core/ui-elements/spinner/spinner";
import SpaceViewContainer from "../space-view-container/space-view-container";

interface IProps {
  selectedSpace: { clusterId: string; spaceId: string };
  onSpaceSelect: (space: ISpaceView) => void;
  sendSelectedSpace: (space: ISpaceView) => void;
}

const SpaceView = (props: IProps) => {
  const { selectedSpace, sendSelectedSpace, onSpaceSelect } = props;
  const [spaces, setSpaces] = useState<ISpaceView>();
  const [isFirstTime, setIsFirstTime] = useState(true);

  const [
    triggerGetConsumerSpaceClusterByIdentityQuery,
    {
      data: spaceData,
      isSuccess: getSpaceSuccess,
      isFetching: isFetchingConsumerSpaceClusterByIdentity,
    },
  ] = useLazyViewConsumerSpaceClusterByIdentityQuery();

  useEffect(() => {
    if (getSpaceSuccess && spaceData) {
      setSpaces(spaceData.subSpaceCluster.rootSpace);
    }
  }, [getSpaceSuccess, spaceData]);

  useEffect(() => {
    setIsFirstTime(true);
    if (selectedSpace.clusterId && selectedSpace.spaceId) {
      triggerGetConsumerSpaceClusterByIdentityQuery({
        spaceClusterId: selectedSpace.clusterId,
        subRootSpaceId: selectedSpace.spaceId,
      });
    }
  }, [triggerGetConsumerSpaceClusterByIdentityQuery, selectedSpace]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (selectedSpace.clusterId && selectedSpace.spaceId) {
        triggerGetConsumerSpaceClusterByIdentityQuery({
          spaceClusterId: selectedSpace.clusterId,
          subRootSpaceId: selectedSpace.spaceId,
        });
        setIsFirstTime(false);
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [triggerGetConsumerSpaceClusterByIdentityQuery, selectedSpace]);

  return (
    <div className="position-relative">
      <Row className="mt-3">
        <Col>
          <Row className="mb-3 mx-0">
            <SpacePathViewBreadcrumb
              selectedSpace={{
                clusterId: selectedSpace.clusterId,
                spaceId: selectedSpace.spaceId,
              }}
              sendSelectedSpace={sendSelectedSpace}
            />
          </Row>
          {spaces && (
            <SpaceViewContainer
              mainSpace={spaces}
              collapsed={false}
              onSpaceSelect={onSpaceSelect}
              level={1}
            />
          )}
        </Col>
      </Row>
      <SpinnerModal
        show={isFetchingConsumerSpaceClusterByIdentity && isFirstTime}
        size="lg"
      />
    </div>
  );
};

export default SpaceView;
