export enum OvstErrorCode {
  OVST_SEC_0001 = "OVST_SEC_0001",
  OVST_SEC_0002 = "OVST_SEC_0002",
  OVST_APP_SEC_0001 = "OVST_APP_SEC_0001",
  OVST_APP_SEC_0002 = "OVST_APP_SEC_0002",
  OVST_SEC_0004 = "OVST_SEC_0004",
  OVST_0001 = "OVST_0001",
  OVST_SEC_0005 = "OVST_SEC_0005",
  OVST_0036 = "OVST_0036",
  NO_INTERNET_CONNECTION = "NO_INTERNET_CONNECTION",
  OVST_CONS_0001 = "OVST_CONS_0001",
  OVST_SEC_0006 = "OVST_SEC_0006",
  OVST_APP_SEC_0004 = "OVST_APP_SEC_0004",
  OVST_0034 = "OVST_0034",
  OVST_0040 = "OVST_0040",
  OVST_CONS_0005 = "OVST_CONS_0005",
  OVST_CONS_0006 = "OVST_CONS_0006",
  OVST_CONS_0007 = "OVST_CONS_0007",
  OVST_CONS_0015 = "OVST_CONS_0015",
  OVST_CONS_0016 = "OVST_CONS_0016",
  OVST_0042 = "OVST_0042",
  OVST_0043 = "OVST_0043",
  OVST_0044 = "OVST_0044",
  OVST_0045 = "OVST_0045",
  OVST_0028 = "OVST_0028",
  OVST_0030 = "OVST_0030",
  OVST_CONS_0020 = "OVST_CONS_0020",
  OVST_CONS_0019 = "OVST_CONS_0019",
  OVST_CONS_0026 = "OVST_CONS_0026",
  OVST_CONS_0030 = "OVST_CONS_0030",
  OVST_CONS_0033 = "OVST_CONS_0033",
  OVST_0052 = "OVST_0052",
  OVST_0053 = "OVST_0053",
  OVST_0054 = "OVST_0054",
  OVST_0011 = "OVST_0011",
  OVST_0010 = "OVST_0010",
  OVST_CONS_0031 = "OVST_CONS_0031",
  OVST_CONN_0001 = "OVST_CONN_0001",
  OVST_CONN_0002 = "OVST_CONN_0002",
  OVST_CONN_0003 = "OVST_CONN_0003",
}
