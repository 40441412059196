import { Col, Row } from "react-bootstrap";
import { EPowerMeasureType } from "../../../../shared/oversight-core/enums/power-measure-type";
import { IShallowDemandControlMethodView } from "../../../../shared/oversight-core/interfaces/shallow-demand-control-method-view";
import RadioButton from "../../../../shared/oversight-core/ui-elements/radio-button/radio-button";
import { findDemandControllerMethod } from "../../../../shared/oversight-core/utils/find-demand-controller-method";
import { powerConvertor } from "../../../../shared/oversight-core/utils/power-convertor";
import styles from "./demand-management-card.module.scss";

interface IProps {
  demandControllerMethod: IShallowDemandControlMethodView;
  onChange?: (
    value: boolean,
    selectedDemandControllerMethod: IShallowDemandControlMethodView
  ) => void;
  checked?: boolean;
  isRadioButton?: boolean;
}

const DemandManagementCard = (props: IProps) => {
  const {
    demandControllerMethod,
    onChange,
    checked = false,
    isRadioButton = true,
  } = props;

  const handleSelected = () => {
    onChange && onChange(true, demandControllerMethod);
  };

  return (
    <div
      className={`${styles.cardContainer} p-4 cursor-pointer`}
      onClick={handleSelected}
    >
      <Row className="align-items-center justify-content-between">
        <Col className="font-weight-500">
          <Row>
            <Col>
              {findDemandControllerMethod(
                demandControllerMethod.demandControlMethod
              )}
            </Col>
          </Row>
          <Row className="gx-5 mt-3">
            <Col className="col-auto text-center">
              <Row>
                <Col className="text-light font-size-20 font-weight-500">
                  {demandControllerMethod.clients}
                </Col>
              </Row>
              <Row className="text-light font-size-14 font-weight-400">
                <Col>Clients</Col>
              </Row>
            </Col>
            <Col className="col-auto text-center">
              <Row>
                <Col className="text-light font-size-20 font-weight-500">
                  {demandControllerMethod.devices}
                </Col>
              </Row>
              <Row className="text-light font-size-14 font-weight-400">
                <Col>Devices</Col>
              </Row>
            </Col>
            <Col className="col-auto text-center">
              <Row>
                <Col
                  className={`${styles.powerValueGreenColor} font-size-20 font-weight-500`}
                >
                  {powerConvertor(
                    demandControllerMethod.maximumPowerSaving,
                    EPowerMeasureType.MEGA_WATT
                  ).toFixed(2)}{" "}
                  MW
                </Col>
              </Row>
              <Row className="text-light font-size-14 font-weight-400">
                <Col>Max Saving</Col>
              </Row>
            </Col>
            {demandControllerMethod.buffer && (
              <Col className="col-auto text-center">
                <Row>
                  <Col className="text-light font-size-20 font-weight-500">
                    {powerConvertor(
                      demandControllerMethod.buffer,
                      EPowerMeasureType.MEGA_WATT
                    ).toFixed(2)}{" "}
                    kW
                  </Col>
                </Row>
                <Row className="text-light font-size-14 font-weight-400">
                  <Col>buffer</Col>
                </Row>
              </Col>
            )}
          </Row>
        </Col>
        {isRadioButton && (
          <Col className="col-auto">
            <RadioButton
              checked={checked}
              onChange={(value) =>
                onChange && onChange(value, demandControllerMethod)
              }
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default DemandManagementCard;
