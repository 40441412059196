import moment from "moment";
import { v4 as uuid } from "uuid";
import { formatTime } from "./time-utils";

export const convertIsoStringToTimeList = (
  ISOTimeList: { fromDate: string; toDate: string }[]
) => {
  return ISOTimeList.map((timeItem) => {
    const fromDate = new Date(timeItem.fromDate);
    const toDate = new Date(timeItem.toDate);
    const fromString = formatTime(fromDate);
    const toString = formatTime(toDate);

    const isDifferent =
      moment(fromDate).format("DD") !== moment(toDate).format("DD");

    return {
      id: uuid(),
      startTime: { label: fromString, value: fromString },
      endTime: {
        label: isDifferent ? "12:00 AM" : toString,
        value: isDifferent ? "11:59 PM" : toString,
      },
      startTimeError: "",
      endTimeError: "",
    };
  });
};
