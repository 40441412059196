import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import {
  useLazyAdminListAllPowerConsumersQuery,
  useLazyAdminListAllSmartControllersQuery,
} from "../../../redux/api/consumer/consumerAPI";
import AdminListAllDevicesResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/admin-list-all-devices-response-dto";
import AdminListAllSmartControllersResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/admin-list-all-smart-controllers-response-dto";
import { EConnectionStatus } from "../../../shared/oversight-core/enums/connection-status";
import { EDeviceStatus } from "../../../shared/oversight-core/enums/device-status";
import { ESmartPlugType } from "../../../shared/oversight-core/enums/smart-plug-type";
import { ISpaceClusterPowerConsumerViews } from "../../../shared/oversight-core/interfaces/space-cluster-power-consumer-views";
import { ISpaceClusterSmartPlugViews } from "../../../shared/oversight-core/interfaces/space-cluster-smart-plug-views";
import UserDashboardFilters from "../../../shared/oversight-core/interfaces/user-dashboard-filters";
import ActiveInactiveIndicator from "../../../shared/oversight-core/ui-elements/active-inactive-indicator/active-inactive-indicator";
import DataTable from "../../../shared/oversight-core/ui-elements/data-table/data-table";
import LastKnownStatus from "../../../shared/oversight-core/ui-elements/last-known-status/last-known-status";
import SpinnerModal from "../../../shared/oversight-core/ui-elements/spinner/spinner";
import styles from "./device-list-view.module.scss";

interface IProps {
  filters: UserDashboardFilters;
}

const DeviceListView = (props: IProps) => {
  const { state: user } = useLocation();
  const { filters } = props;
  const [powerConsumerList, setPowerConsumerList] = useState<
    ISpaceClusterPowerConsumerViews[]
  >([]);
  const [smartDevicesList, setSmartDevicesList] = useState<
    ISpaceClusterSmartPlugViews[]
  >([]);

  const [
    triggerPowerConsumersList,
    { isFetching: isFetchingPowerConsumerList },
  ] = useLazyAdminListAllPowerConsumersQuery();

  const [triggerSmartDeviceList, { isFetching: isFetchingSmartDeviceList }] =
    useLazyAdminListAllSmartControllersQuery();

  useEffect(() => {
    if (
      filters.selectedFilterSpace.clusterId &&
      filters.selectedFilterSpace.id &&
      user?.id
    ) {
      triggerPowerConsumersList({
        consumerId: user?.id,
        type: filters.selectedDeviceType,
        smartDeviceType: filters.selectedSmartControllerType
          ? filters.selectedSmartControllerType
          : undefined,
        pageNumber: 0,
        pageSize: 1000,
        spaceClusterId: filters.selectedFilterSpace.clusterId,
        subRootSpaceId: filters.selectedFilterSpace.id,
        ascending: true,
      })
        .unwrap()
        .then((response: AdminListAllDevicesResponseDTO) => {
          setPowerConsumerList(response.deviceList.elements);
        })
        .catch(() => {
          setPowerConsumerList([]);
        });
    }
  }, [
    triggerPowerConsumersList,
    filters.selectedFilterSpace.clusterId,
    filters.selectedFilterSpace.id,
    filters.selectedDeviceType,
    user?.id,
    filters.selectedSmartControllerType,
  ]);

  useEffect(() => {
    if (
      filters.selectedFilterSpace.clusterId &&
      filters.selectedFilterSpace.id &&
      user?.id
    ) {
      triggerSmartDeviceList({
        consumerId: user?.id,
        type: filters.selectedDeviceType,
        smartDeviceType: filters.selectedSmartControllerType
          ? filters.selectedSmartControllerType
          : undefined,
        pageNumber: 0,
        pageSize: 1000,
        spaceClusterId: filters.selectedFilterSpace.clusterId,
        subRootSpaceId: filters.selectedFilterSpace.id,
        ascending: true,
      })
        .unwrap()
        .then((response: AdminListAllSmartControllersResponseDTO) => {
          setSmartDevicesList(response.smartDevices.elements);
        })
        .catch(() => {
          setSmartDevicesList([]);
        });
    }
  }, [
    filters.selectedFilterSpace.clusterId,
    filters.selectedFilterSpace.id,
    filters.selectedDeviceType,
    user?.id,
    filters.selectedSmartControllerType,
    triggerSmartDeviceList,
  ]);

  return (
    <div className="position-relative">
      {powerConsumerList.length > 0 || smartDevicesList.length > 0 ? (
        <Row className="mt-4">
          <DataTable
            headers={[
              { id: 1, columnName: "Device Name" },
              {
                id: 2,
                columnName: "Type",
              },
              {
                id: 3,
                columnName: "Wattage",
              },
              {
                id: 4,
                columnName: "Controller Name",
              },
              { id: 5, columnName: "Controller Type" },
              { id: 6, columnName: "Status" },
            ]}
            data={
              filters.selectedSmartControllerType
                ? smartDevicesList.map((sd) => {
                    return {
                      data: [
                        <>{sd.smartDevice.linkedPowerConsumer?.name || "-"}</>,
                        <>
                          {sd.smartDevice.linkedPowerConsumer?.deviceType ||
                            "-"}
                        </>,
                        <>
                          {sd.smartDevice.linkedPowerConsumer?.powerUsageInWatt
                            ? `${sd.smartDevice.linkedPowerConsumer?.powerUsageInWatt} W`
                            : `-`}
                        </>,
                        <>
                          <div className="d-flex align-items-center">
                            <div>{sd.smartDevice.name}</div>
                            <div className="ms-1">
                              <ActiveInactiveIndicator
                                isActive={
                                  sd.smartDevice.deviceConnectionState ===
                                  EConnectionStatus.CONNECTED
                                }
                              />
                            </div>
                          </div>
                        </>,
                        <>
                          {sd.smartDevice.smartPlugType ===
                          ESmartPlugType.GENERAL
                            ? `GENERAL`
                            : sd.smartDevice.smartDeviceType}
                        </>,
                        <>
                          <Row className="align-items-center">
                            <Col
                              xs={1}
                              className={
                                sd.smartDevice.dynamicState.powerState ===
                                EDeviceStatus.ON
                                  ? styles[`device-on`]
                                  : ``
                              }
                            >
                              {sd.smartDevice.dynamicState.powerState ===
                              EDeviceStatus.ON
                                ? "On"
                                : "Off"}
                            </Col>
                            {sd.smartDevice.deviceConnectionState ===
                              EConnectionStatus.DISCONNECTED && (
                              <Col className="col-auto ps-2">
                                <LastKnownStatus onlyIcon={true} />
                              </Col>
                            )}
                          </Row>
                        </>,
                      ],
                    };
                  })
                : powerConsumerList.map((powerConsumer) => {
                    return {
                      data: [
                        <>{powerConsumer.powerConsumer.name}</>,
                        <>{powerConsumer.powerConsumer.deviceType}</>,
                        <>{powerConsumer.powerConsumer.powerUsageInWatt} W</>,
                        <>
                          {powerConsumer.linkedSmartDevices.length > 0 ? (
                            <>
                              {powerConsumer.linkedSmartDevices
                                .filter((lsd) =>
                                  filters.selectedSmartControllerType
                                    ? lsd.smartDeviceType ===
                                      filters.selectedSmartControllerType
                                    : lsd.smartDeviceType
                                )
                                .map((d, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="d-flex align-items-center"
                                    >
                                      <div>{d.name}</div>
                                      <div className="ms-1">
                                        <ActiveInactiveIndicator
                                          isActive={
                                            d.deviceConnectionState ===
                                            EConnectionStatus.CONNECTED
                                          }
                                        />
                                      </div>
                                    </div>
                                  );
                                })}
                            </>
                          ) : (
                            "-"
                          )}
                        </>,
                        <>
                          {powerConsumer.linkedSmartDevices.length > 0 ? (
                            <>
                              {powerConsumer.linkedSmartDevices
                                .filter((lsd) =>
                                  filters.selectedSmartControllerType
                                    ? lsd.smartDeviceType ===
                                      filters.selectedSmartControllerType
                                    : lsd.smartDeviceType
                                )
                                .map((d, index) => (
                                  <div
                                    key={index}
                                    className="d-flex align-items-center"
                                  >
                                    {d.smartDeviceType}
                                  </div>
                                ))}
                            </>
                          ) : (
                            "-"
                          )}
                        </>,
                        <>
                          <Row className="align-items-center">
                            <Col
                              xs={1}
                              className={
                                powerConsumer.powerConsumer.devicePowerState ===
                                EDeviceStatus.ON
                                  ? styles[`device-on`]
                                  : ``
                              }
                            >
                              {powerConsumer.powerConsumer.devicePowerState !=
                              null
                                ? powerConsumer.powerConsumer
                                    .devicePowerState === EDeviceStatus.ON
                                  ? "On"
                                  : "Off"
                                : "-"}
                            </Col>
                            {powerConsumer.linkedSmartDevices.every(
                              (d) =>
                                d.deviceConnectionState ===
                                EConnectionStatus.DISCONNECTED
                            ) &&
                              powerConsumer.linkedSmartDevices.length > 0 && (
                                <Col className="col-auto ps-2">
                                  <LastKnownStatus />{" "}
                                </Col>
                              )}
                          </Row>
                        </>,
                      ],
                    };
                  })
            }
          />
        </Row>
      ) : (
        <>
          {(!isFetchingPowerConsumerList || !isFetchingSmartDeviceList) && (
            <div className="container-dash mt-4">
              <Col className="text-center text-light font-size-12">
                There are no devices available for the selected filters.
              </Col>
            </div>
          )}
        </>
      )}
      <SpinnerModal
        show={isFetchingPowerConsumerList || isFetchingSmartDeviceList}
      />
    </div>
  );
};

export default DeviceListView;
