import { EDeviceTypes } from "../../oversight-core/enums/device-types";
import { Option } from "../../oversight-core/ui-elements/app-select/app-select";
import getDeviceTypes, {
  getDeviceLabel,
} from "../../oversight-core/utils/getDeviceType";
import { deviceTypeColor } from "./device-type-color";

interface IOptionWithColor extends Option {
  color: string;
}

export const deviceTypes: IOptionWithColor[] = [
  {
    value: "",
    label: getDeviceLabel(EDeviceTypes.ALL_DEVICES, true),
    color: "",
  },
  ...getDeviceTypes(true).map((deviceType) => {
    const foundDevice = deviceTypeColor.find((item) => {
      return item.value === deviceType.value;
    });

    return {
      ...deviceType,
      color: foundDevice ? foundDevice.color : "#f4eef8",
    };
  }),
];
