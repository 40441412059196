import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useFeedbackMutation } from "../../../../redux/api/consumer/consumerAPI";
import { EFeedbackApplicationType } from "../../enums/feedback-application-type";
import { EFeedbackType } from "../../enums/feedback-type";
import AppSelect, { Option } from "../../ui-elements/app-select/app-select";
import MaterialIcon from "../../ui-elements/material-icon/material-icon";
import ModalContainer, {
  ModelContainerProps,
} from "../../ui-elements/modal-container/modal-container";
import { showErrorMessage, showSuccessMessage } from "../../utils/toast";
import styles from "./feedback-modal.module.scss";

type IProps = ModelContainerProps;

const featureOfTheOversightOptions: Option[] = [
  {
    value: EFeedbackType.GENERAL_APPLICATION,
    label: "General Application",
  },
  {
    value: EFeedbackType.USER_LOGIN,
    label: "User Login",
  },
  {
    value: EFeedbackType.USER_REGISTRATION,
    label: "User Registration",
  },
  {
    value: EFeedbackType.DEVICES,
    label: "Devices",
  },
  {
    value: EFeedbackType.SMART_CONTROLLER,
    label: "Smart Controller",
  },
  {
    value: EFeedbackType.SEMI_AUTOMATED_SCHEDULING,
    label: "Semi Automated Scheduling",
  },
  {
    value: EFeedbackType.DEMAND_RESPONSE_PROGRAM,
    label: "Demand Response Program",
  },
];

interface IFormInput {
  feedback: string;
  featureOfTheOversight: Option | [];
}

const defaultFormValues: IFormInput = {
  feedback: "",
  featureOfTheOversight: [],
};

const FeedbackModal = (props: IProps) => {
  const [selected, setSelected] = useState(1);

  const [sendFeedback, { isLoading: isLoadingSendFeedback }] =
    useFeedbackMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<IFormInput>({
    defaultValues: { ...defaultFormValues },
  });

  const onSubmit = (data: IFormInput) => {
    sendFeedback({
      feedbackApplicationType:
        selected === 1
          ? EFeedbackApplicationType.WEB_APPLICATION
          : EFeedbackApplicationType.MOBILE_APPLICATION,
      feedbackType: (data.featureOfTheOversight as Option)
        .value as EFeedbackType,
      feedback: data.feedback,
    })
      .unwrap()
      .then(() => {
        showSuccessMessage("Feedback sent successfully");
      })
      .catch(() => {
        showErrorMessage("Sending feedback failed. Please try again later");
      })
      .finally(() => {
        reset({ ...defaultFormValues });
        props.onClose && props.onClose();
      });
  };

  return (
    <ModalContainer
      {...props}
      title="Feedback"
      confirmButtonText="Send"
      size="modal-md"
      onConfirm={handleSubmit(onSubmit)}
      isLoading={isLoadingSendFeedback}
      onCancel={() => {
        reset({ ...defaultFormValues });
        props.onCancel && props.onCancel();
      }}
      onClose={() => {
        reset({ ...defaultFormValues });
        props.onClose && props.onClose();
      }}
    >
      <>
        <Row>
          <Col className="text-dark">What Application the Feedback About?</Col>
        </Row>
        <Row className="px-2 mt-2">
          <Col className="col-12 col-sm-6 px-3">
            <Row>
              <Col
                className={`${
                  selected === 1 ? "bg-primary" : ""
                } cursor-pointer px-4 py-2 ${styles.appType}`}
                onClick={() => setSelected(1)}
              >
                <Row className="align-items-center">
                  <Col className="col-auto px-0">
                    <MaterialIcon
                      icon="ad"
                      color={selected === 1 ? "#fff" : "#69768B"}
                    />
                  </Col>
                  <Col
                    className={`font-size-14 ${
                      selected === 1 ? "text-white" : "text-light"
                    }`}
                  >
                    Web Application
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col className="col-12 col-sm-6 px-3 mt-2 mt-sm-0">
            <Row>
              <Col
                className={`${
                  selected === 2 ? "bg-primary" : ""
                } cursor-pointer px-4 py-2 ${styles.appType}`}
                onClick={() => setSelected(2)}
              >
                <Row className="align-items-center">
                  <Col className="col-auto px-0">
                    <MaterialIcon
                      icon="smartphone"
                      color={selected === 2 ? "#fff" : "#69768B"}
                    />
                  </Col>
                  <Col
                    className={`font-size-14 ${
                      selected === 2 ? "text-white" : "text-light"
                    }`}
                  >
                    Mobile Application
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <AppSelect
              defaultValue={""}
              placeholder="Select feature of the Oversight"
              options={featureOfTheOversightOptions}
              label="Feature of the Oversight"
              control={control}
              name="featureOfTheOversight"
              errors={errors}
              register={register("featureOfTheOversight", {
                required: "Please select the feature of the Oversight",
              })}
              isRequired={true}
              menuHeight="250px"
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <Row>
              <Col>
                <label className="font-size-16">Your Feedback</label>
                <label className="asterisk">*</label>
              </Col>
            </Row>
            <Row>
              <Col>
                <textarea
                  {...register("feedback", {
                    required: "Feedback is required",
                    maxLength: {
                      value: 75,
                      message:
                        "You have exceeded the maximum number of 75 characters in this field",
                    },
                  })}
                  name="feedback"
                  placeholder="Feedback"
                  className={`${styles.textArea} w-100`}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <span className="error">
                  {errors && errors["feedback"]?.message}
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    </ModalContainer>
  );
};

export default FeedbackModal;
