import { Col, Row } from "react-bootstrap";
import { EDeviceTypes } from "../../oversight-core/enums/device-types";
import { ESmartControllerType } from "../../oversight-core/enums/smart-controller-type";
import MaterialIcon from "../../oversight-core/ui-elements/material-icon/material-icon";
import findIcon from "../../oversight-core/utils/findIcon";
import styles from "./selected-filters-view.module.scss";

interface IProps {
  spaceName: string;
  filters: {
    icon?: EDeviceTypes;
    filterName?: string;
    subText?: string;
    date?: string;
    smartControllerTpe?: string;
  }[];
}

const SelectedFiltersView = (props: IProps) => {
  const { spaceName, filters } = props;

  return (
    <>
      <Row>
        <Col className={styles.spaceName}>{spaceName}</Col>
      </Row>

      {filters.map((filter, index) => (
        <div key={index}>
          <Row className="align-items-center">
            {filter.icon && (
              <Col className={`col-auto pe-0`}>
                <div className={`p-1 ${filter.icon} rounded`}>
                  <MaterialIcon icon={findIcon(filter.icon)} size={16} />
                </div>
              </Col>
            )}
            {filter.filterName && (
              <Col className={`text-light font-size-12 font-weight-500 ps-2`}>
                {filter.filterName}
              </Col>
            )}
            {filter.subText && (
              <Col className={`text-primary font-size-12 font-weight-500 ps-0`}>
                {filter.subText}
              </Col>
            )}
            {filter.date && (
              <Col className={`text-primary font-size-12 font-weight-500 ps-0`}>
                {filter.date}
              </Col>
            )}
          </Row>
          {filter.smartControllerTpe && (
            <Row className="align-items-center ms-0 mt-1">
              <Col className={`${styles.smartControllerIcon} col-auto px-0`}>
                <MaterialIcon
                  icon={
                    filter.smartControllerTpe ===
                    ESmartControllerType.SMART_PLUG
                      ? `smart_outlet`
                      : `missing_controller`
                  }
                  color="#7E9ABB"
                  size={16}
                  className="p-1"
                />
              </Col>
              <Col className="ps-2 text-light font-size-12 font-weight-500">
                {filter.smartControllerTpe === ESmartControllerType.SMART_PLUG
                  ? `Smart Plugs`
                  : `AC Controllers`}
              </Col>
            </Row>
          )}
        </div>
      ))}
    </>
  );
};

export default SelectedFiltersView;
