import { format } from "date-fns";
import { ERepetitionMode } from "../enums/repetition-mode";

export const generateRepetitionOptions = (date: Date) => {
  return [
    {
      value: ERepetitionMode.DO_NOT_REPEAT,
      label: "Doesn't Repeat",
    },
    { value: ERepetitionMode.DAILY, label: "Daily" },
    {
      value: ERepetitionMode.WEEKLY,
      label: `Weekly on ${format(date, "EEEE")}`,
    },
    {
      value: ERepetitionMode.MONTHLY,
      label: `Monthly on ${format(date, "dd")}`,
    },
    // {
    //   value: RepetitionMode.YEARLY,
    //   label: `Annually on ${format(date, "MMMM dd")}`,
    // },
  ];
};
