import MaterialIcon, { MtIcon } from "../../material-icon/material-icon";
import styles from "./icon-button.module.scss";

interface IProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  text?: string;
  icon: MtIcon;
  variant?: string;
}

const IconButton = (props: IProps) => {
  const { text, ...rest } = props;

  return (
    <button
      {...{
        ...rest,
        className: `${rest.className ? rest.className : ""} ${
          styles["icon-button"]
        } ${styles[`${rest.variant}`]}`,
      }}
    >
      <MaterialIcon icon="add" size={20} className="me-2" />{" "}
      {props.text && <div className="font-size-14">{text}</div>}
    </button>
  );
};

export default IconButton;
