import TariffResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/tariff-response-dto";
import { transformErrorResponse } from "../../../shared/oversight-core/utils/transformErrorResponse";
import { rootApi } from "../apiManager";

export const spaceAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    listTariffs: builder.query<TariffResponseDTO, void>({
      transformErrorResponse,
      query() {
        return {
          url: `tariff/list`,
          method: "GET",
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const { useLazyListTariffsQuery } = spaceAPI;
